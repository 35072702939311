import IconButton from '@mui/material/IconButton';
import { GoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { motion } from "framer-motion";
import EyeOffOutline from 'mdi-material-ui/EyeOffOutline';
import EyeOutline from 'mdi-material-ui/EyeOutline';
import React, { useEffect, useState } from "react";
import { CgSpinner } from "react-icons/cg";
import { RxCross1 } from "react-icons/rx";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API } from "../../utils/constants";
import { useAuth } from "../../utils/context";

const SignupForm = () => {
  const [, setAuth] = useAuth();
  const navigate = useNavigate();
  const [showVerifyEmailPopup, setShowVerifyEmailPopup] = useState(false);
  const [otp, setOtp] = useState("");
  const [, setEmailVerified] = useState(false);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [institute, setInstitute] = useState("");
  const [password, setPassword] = useState("");
  const [contact, setContact] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [emailmessage, setEmailMessage] = useState("");
  const [passwordMessage, setPasswordMessage] = useState("");
  const [resendTimer, setResendTimer] = useState(30);
  const [startTimer, setStartTimer] = useState(false);

  useEffect(() => {
    let interval = null;
    if (startTimer && resendTimer > 0) {
      interval = setInterval(() => {
        setResendTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (resendTimer === 0) {
      setResendTimer(0);
      setStartTimer(false);
    }
    return () => clearInterval(interval);
  }, [startTimer, resendTimer]);
  const handleSendOTP = async () => {
    if (!name || !email || !password || !contact || !institute) {
      toast.error("Please fill in all fields");
      return;
    }

    if (!validateEmail(email)) {
      toast.error("Please enter a valid email address");
      return;
    }

    if (!validateContact(contact)) {
      toast.error("Please enter a valid contact number");
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(`${API}/auth/send-otp`, { email });
      setResendTimer(30);
      setStartTimer(true);
      setShowVerifyEmailPopup(true);
      setLoading(false);
      toast.success(response.data);
    } catch (error) {
      toast.error(error.response.data.message);
      setLoading(false);
    }
  };

  const handleResendOtp = async () => {
    setLoading(true);
    handleSendOTP()
    setOtp("")
    setResendTimer(30);
    setLoading(false);
    setStartTimer(true);
  };


  const handleVerifyOTP = async () => {
    setLoading(true);
    try {
      await axios.post(`${API}/auth/verify-otp`, { email, otp });
      setEmailVerified(true);
      setShowVerifyEmailPopup(false);
      handleSignUp();
      setLoading(false);
    } catch (error) {
      toast.error(error.response.data.message);
      setLoading(false);
    }
  };

  const handleSignUp = async () => {
    if (!name || !email || !password || !contact) {
      toast.error("Please fill in all fields");
      return;
    }
    if (!validateEmail(email)) {
      toast.error("Please enter a valid email address");
      return;
    }
    if (!validateContact(contact)) {
      toast.error("Please enter a valid contact number");
      return;
    }
    if (password.length < 8) {
      toast.error("Password must be at least 8 characters long");
      return;
    }

    const userData = {
      name: name,
      email: email,
      password: password,
      contact: contact,
      institute: institute,
    };

    try {
      const response = await axios.post(`${API}/auth/signup`, userData);
      if (response.status === 200) {
        const { user, accessToken } = response.data;
        const tokenExpiry = new Date().getTime() + 5 * 24 * 60 * 60 * 1000;
        const tokenData = {
          token: accessToken,
          expiry: tokenExpiry,
        };
        const userWithoutSensitiveData = {
          ...user,
          password: undefined,
        };
        setAuth({
          user: userWithoutSensitiveData,
          accessToken: tokenData,
        });
        localStorage.setItem(
          "auth",
          JSON.stringify({
            user: userWithoutSensitiveData,
            accessToken: tokenData,
          })
        );
        axios.post(
          `${API}/scores/create-test-scores/${user._id}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        navigate("/");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An error occurred during sign up");
      }
    } finally {
      setLoading(false);
    }
  };


  const handleGoogleLogin = async (credentialResponse) => {
    setLoading(true);
    try {
      const idToken = credentialResponse.credential;
      const response = await axios.post(`${API}/auth/google-signin`, {
        idToken,
      });

      if (response.status === 200) {
        const res = response.data;
        const tokenExpiry = new Date().getTime() + 5 * 24 * 60 * 60 * 1000;
        const tokenData = {
          token: res.accessToken,
          expiry: tokenExpiry,
        };
        const { password, ...userWithoutSensitiveFields } = res.user;
        setAuth({ user: userWithoutSensitiveFields, accessToken: tokenData });
        localStorage.setItem(
          "auth",
          JSON.stringify({
            user: userWithoutSensitiveFields,
            accessToken: tokenData,
          })
        );
        navigate("/");
        setLoading(false);
      } else {
        toast.error("Google authentication failed");
        setLoading(false);
      }
    } catch (error) {
      toast.error("Google authentication failed");
      setLoading(false);
    }
  };
  const handleGoogleLoginError = (error) => {
    toast.error("Google login failed");
    setLoading(false);
  };
  const validateEmail = (email) => {
    let emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return emailRegex.test(email);
  };

  const validateContact = (contact) => {
    const contactRegex = /^\d{10}$/;
    return contactRegex.test(contact);
  };

  const validatePassword = (event) => {
    setPassword(event.target.value);
    if (event.target.value.length < 8) {
      setPasswordMessage("Weak Password");
    } else {
      setPasswordMessage("");
    }
  };

  const validContact = (event) => {
    setContact(event.target.value);
    if (!validateContact(event.target.value)) {
      setMessage("Enter a valid Contact Number");
    } else {
      setMessage("");
    }
  }

  const handleChange = (event) => {
    setEmail(event.target.value);
    if (!validateEmail(event.target.value)) {
      setEmailMessage("Enter a valid email address");
    } else {
      setEmailMessage("");
    }
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="pb-4">
      <h2 className="hidden mb-[-8px] md:block text-[#ff820f] md:text-[2vw]">
        Sign-Up
      </h2>
      <form
        className="flex flex-col items-center justify-center mt-[.3vw] lg:items-start"
        onSubmit={(e) => e.preventDefault()}
      >
        {/* Name Input */}
        <div className="flex flex-col max-lg:mb-1">
          <label
            htmlFor="name"
            className=" max-lg:mt-[1vw] mt-[1vw] max-md:mb-[0.2vw] text-start font-medium  text-black text-[15px] lg:text-[1.2vw]"
          >
            Name
          </label>
          <input
            required
            id="name"
            name="name"
            type="text"
            autoComplete="name"
            placeholder="Your Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="max-lg:mt-1 bg-[#c5c5c5] font-medium lg:p-[0.7vw] p-2 max-lg:py-3 w-[60vw] lg:w-[30vw] border rounded-[20px] md:rounded-[15px] text-[15px] border-none lg:text-[1.2vw]  text-black shadow-md"
          />
        </div>
        {/* Email Input */}
        <div className="flex flex-col max-lg:mb-1 ">
          <label
            htmlFor="email"
            className=" max-lg:mt-[1vw] mt-[1vw] max-md:mb-[0.2vw] text-start font-medium  text-black text-[15px] lg:text-[1.2vw]"
          >
            Email
          </label>
          <input
            required
            id="email"
            name="email"
            type="email"
            autoComplete="email"
            placeholder="Your Email"
            value={email}
            onChange={handleChange}
            className="max-lg:mt-1 bg-[#c5c5c5] font-medium lg:p-[0.7vw] p-2 max-lg:py-3 w-[60vw] border rounded-[20px] md:rounded-[15px] text-[15px] border-none lg:text-[1.2vw] lg:w-[30vw] text-black shadow-md"
          />
          {email && <p className="mt-1 mb-[-4px] text-red-500">{emailmessage}</p>}
        </div>
        <div className="flex flex-col max-lg:mb-1">
          <label
            htmlFor="name"
            className=" max-lg:mt-[1vw] mt-[1vw] max-md:mb-[0.2vw] text-start font-medium  text-black text-[15px] lg:text-[1.2vw]"
          >
            Institute
          </label>
          <input
            required
            id="institute"
            name="institute"
            type="text"
            autoComplete="institute"
            placeholder="Your Institute"
            value={institute}
            onChange={(e) => setInstitute(e.target.value)}
            className="max-lg:mt-1 bg-[#c5c5c5] font-medium lg:p-[0.7vw] p-2 max-lg:py-3 w-[60vw] lg:w-[30vw] border rounded-[20px] md:rounded-[15px] text-[15px] border-none lg:text-[1.2vw]  text-black shadow-md"
          />
        </div>
        {/* Contact Input */}
        <div className="flex flex-col max-lg:mb-1">
          <label
            htmlFor="contact"
            className=" max-lg:mt-[1vw] mt-[1vw] max-md:mb-[0.2vw] text-start font-medium  text-black text-[15px] lg:text-[1.2vw]"
          >
            Contact
          </label>
          <input
            required
            id="contact"
            name="contact"
            type="text"
            autoComplete="contact"
            placeholder="Your Contact Number"
            value={contact}
            onChange={validContact}
            className="max-lg:mt-1 bg-[#c5c5c5] font-medium lg:p-[0.7vw] p-2 max-lg:py-3 w-[60vw] lg:w-[30vw] border rounded-[20px] md:rounded-[15px] text-[15px] border-none lg:text-[1.2vw]  text-black shadow-md"
          />
          {contact && <p className="mt-1 mb-[-4px] text-red-500">{message}</p>}
        </div>
        {/* Password Input */}
        <div className="flex flex-col">
          <label
            htmlFor="password"
            className="max-lg:mt-[1vw] mt-[1vw] max-md:mb-[0.2vw] text-start font-medium text-black text-[15px] lg:text-[1.2vw]"
          >
            Password
          </label>
          <div className="relative">
            <input
              required
              id="password"
              name="password"
              type={showPassword ? "text" : "password"}
              autoComplete="current-password"
              value={password}
              placeholder="8+ Password"
              onChange={validatePassword}
              className="max-lg:mt-1 bg-[#c5c5c5] font-medium lg:p-[0.7vw] p-2 max-lg:py-3 w-[60vw] border rounded-[20px] md:rounded-[15px] text-[15px] border-none lg:text-[1.2vw] lg:w-[30vw] text-black shadow-md"
            />
            <div
              onClick={togglePasswordVisibility}
              className="absolute max-md:top-2 inset-y-0 right-0 flex items-center  pr-3"
            >
              <IconButton className="max-md:w-[20px]">
                {showPassword ? <EyeOutline /> : <EyeOffOutline />}
              </IconButton>
            </div>
          </div>
          {password && password.length < 9 ? (
            <p className="mt-1 text-red-500">{passwordMessage}</p>
          ) : (
            ""
          )}
        </div>
        <div className="mt-[0.8vw] max-lg:mt-1 lg:flex justify-start">
          <button
            type="button"
            onClick={handleSendOTP}
            className="max-lg:w-[129px] w-[10vw] md:my-[0.8vw] max-lg:px-6 px-[1.3vw] max-lg:py-3 py-[1vw] bg-black text-white text-center rounded-full cursor-pointer border-none text-[1.2vw] max-lg:text-[18px]"
          >
            {loading && <CgSpinner size={20} className="mt-1 animate-spin" />}
            {!loading && <span>Sign Up</span>}{" "}
          </button>
        </div>
        <div className="flex  max-lg:top-[10px] relative items-center">
          <GoogleLogin
            onSuccess={handleGoogleLogin}
            onError={handleGoogleLoginError}
            disabled={loading}
            text="signup_with"
            style={{ fontSize: "15px" }}
          >
            {loading ? (
              <CgSpinner size={20} className="mt-1 animate-spin mr-2" />
            ) : (
              <span>Login with Google</span>
            )}
          </GoogleLogin>
        </div>
      </form>
      <ToastContainer />

      {showVerifyEmailPopup && (
        <div className="fixed z-50 inset-0 flex items-center justify-center bg-black/40 backdrop-blur-sm">
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.2 }}
            className="bg-white dark:bg-gray-800 w-full max-w-md mx-4 md:mx-auto rounded-2xl shadow-xl overflow-hidden"
          >
            <div className="relative bg-gradient-to-r from-[#ffb46f] to-[#b6702e] px-6 py-5 text-white">
              <button
                onClick={() => setShowVerifyEmailPopup(false)}
                className="absolute cursor-pointer bg-transparent border-none right-4 top-4 text-white/80 hover:text-white transition-colors"
                aria-label="Close"
              >
                <RxCross1 size={20} />
              </button>
              <h3 className="text-xl font-bold">Verify Your Email</h3>
              <p className="mt-1 text-white/90 text-sm">
                A verification code has been sent to
              </p>
              <p className="font-medium truncate">{email}</p>
            </div>

            <div className="px-6 py-8">
              <div className="flex flex-col items-center">
                <div className="w-full mb-6">
                  <OtpInput
                    value={otp}
                    onChange={(value) => setOtp(value)}
                    numInputs={6}
                    containerStyle="flex justify-between w-full gap-2"
                    inputStyle={{
                      width: '100%',
                      height: '50px',
                      fontSize: '1.3rem',
                      borderRadius: '8px',
                      border: '1px solid #d1d5db',
                      backgroundColor: '#f9fafb',
                      color: '#1f2937',
                      textAlign: 'center',
                      outline: 'none',
                      transition: 'all 0.2s ease-in-out',
                    }}
                    focusStyle={{
                      border: '2px solid #3b82f6',
                      boxShadow: '0 0 0 2px rgba(59, 130, 246, 0.3)'
                    }}
                    renderInput={(props) => <input {...props} />}
                  />
                </div>

                <button
                  onClick={handleVerifyOTP}
                  disabled={otp.length !== 6 || loading}
                  className="w-full py-3 cursor-pointer  px-4 bg-gradient-to-r from-blue-500 to-purple-600 text-white font-medium rounded-xl transition-all flex items-center justify-center disabled:opacity-70 hover:shadow-lg hover:from-blue-600 hover:to-purple-700"
                >
                  {loading ? (
                    <CgSpinner size={24} className="animate-spin" />
                  ) : (
                    "Verify & Continue"
                  )}
                </button>
              </div>

              <div className="mt-6 text-center">
                {resendTimer > 0 ? (
                  <p className="text-gray-500 dark:text-gray-400">
                    Resend code in <span className="font-medium">{resendTimer}s</span>
                  </p>
                ) : (
                  <button
                    onClick={handleResendOtp}
                    disabled={loading}
                    className="text-blue-600 cursor-pointer  bg-transparent border-none hover:text-blue-800 font-medium transition-colors"
                  >
                    Resend verification code
                  </button>
                )}
              </div>
            </div>
          </motion.div>
        </div>
      )}
    </div>
  );
};

export default SignupForm;