import axios from "axios";
import React, { useEffect, useState } from "react";
// import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { IoBookmark, IoBookmarkOutline } from "react-icons/io5";
import { RxCross1 } from "react-icons/rx";
import { TfiTimer } from "react-icons/tfi";
// import { Link } from "react-router-dom/dist";
import { API } from "../../utils/constants";
import { useAuth } from "../../utils/context";
import CuetLoader from "../Loader/Loader";
import { MathText } from "../mathJax/MathText";
import ScoreReport from "./ScoreReport ";
import "./testplatform.css";

const PracticeTestQues = ({ data, topic, subtopic }) => {
  console.log("this is ", topic);
  const alphabets = "ABCDEFGHIJ".split("");
  const [selectedOptions, setSelectedOptions] = useState(
    Array(data?.length).fill([])
  );
  const [auth] = useAuth();

  const [currentPage, setCurrentPage] = useState(0);
  const [explanationsVisible, setExplanationsVisible] = useState(
    Array(data?.length).fill(false)
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [savedQuestions, setSavedQuestions] = useState([]);
  let intervalId;
  const [showWarningPopup, setShowWarningPopup] = useState(false);
  const [correctOptions, setCorrectOptions] = useState([]);
  const [incorrectOptions, setIncorrectOptions] = useState([]);

  // Initial timer setup
  const [timer, setTimer] = useState(() => {
    return 20 * 60;
  });

  const [showResultPopup, setShowResultPopup] = useState(false);
  const [testResults, setTestResults] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [userAttempts, setUserAttempts] = useState(new Map());

  const handlePopupOpen = () => {
    setShowPopup(true);
  };

  const handlePopupClose = () => {
    setShowPopup(false);
  };

  const handleWarningExam = () => {
    setShowWarningPopup(true);
  };

  const handleContinueExam = () => {
    setShowWarningPopup(false);
  };

  const handleResultPopupClose = () => {
    setShowResultPopup(false);
  };

  const handleRetakeTest = () => {
    window.location.reload();
  };

  const handleReviewTest = () => {
    setShowResultPopup(false);
  };

  const handleSubmitWarning = () => {
    setShowWarningPopup(false);
    handleSubmit();
  };

  useEffect(() => {
    let timerReachedZero = false;
    const intervalId = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer <= 0 && !timerReachedZero) {
          clearInterval(intervalId);
          timerReachedZero = true;
          return 0;
        } else {
          return prevTimer - 1;
        }
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const handleOptionClick = (
    question,
    questionIndex,
    optionIndex,
    questionNo
  ) => {
    if (!isSubmitted) {
      const updatedSelectedOptions = [...selectedOptions];
      updatedSelectedOptions[currentPage] =
        updatedSelectedOptions[currentPage] || [];
      updatedSelectedOptions[currentPage][questionIndex] = optionIndex;
      setSelectedOptions(updatedSelectedOptions);

      const isCorrect =
        question.subQuestions[0].correctOptionIndex === optionIndex;

      if (userAttempts.has(questionNo)) {
        const updatedAttempts = new Map(userAttempts);
        updatedAttempts.set(questionNo, isCorrect);
        setUserAttempts(updatedAttempts);
      } else {
        setUserAttempts(new Map(userAttempts).set(questionNo, isCorrect));
      }

      if (isCorrect) {
        setCorrectOptions([...correctOptions, { questionIndex, optionIndex }]);
      } else {
        setIncorrectOptions([
          ...incorrectOptions,
          { questionIndex, optionIndex },
        ]);
      }
    }
  };

  const handleSubmit = () => {
    setTimer(0);
    setIsSubmitted(true);
    setIsLoading(true);
    clearInterval(intervalId);

    // Calculate the correct, incorrect and unattempted counts
    const correctCount = [...userAttempts.values()].filter(
      (isCorrect) => isCorrect
    ).length;
    const incorrectCount = userAttempts.size - correctCount;
    const unattemptedCount = data?.data?.length - userAttempts.size;

    // Calculate time taken (initial time - remaining time)
    const timeTaken = 20 * 60 - timer;

    // Create the payload with the required format
    const payload = {
      topicName: topic, // Using the topic prop or defaulting to Chemistry
      testType: "practice",
      testNumber:
        typeof subtopic === "number" ? subtopic : parseInt(subtopic, 10),
      correctAnswers: correctCount,
      incorrectAnswers: incorrectCount,
      unattemptedQuestions: unattemptedCount,
      timeTaken: timeTaken,
    };

    axios
      .post(
        `${API}/scores/submit-score/${auth?.user?._id}`,
        payload, // Send the formatted payload
        {
          headers: {
            Authorization: `Bearer ${auth.accessToken.token}`,
          },
        }
      )
      .then((response) => {
        console.log("Score submitted successfully:", response.data);
        setTestResults(response.data); // Store the response data
        setShowResultPopup(true);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error updating score:", error);
        setIsLoading(false);
      });
  };

  const handleSaveQuestion = (questionIndex) => {
    const questionAbsoluteIndex = questionIndex + currentPage * 1;
    const updatedSavedQuestions = [...savedQuestions];
    if (!updatedSavedQuestions.includes(questionAbsoluteIndex)) {
      updatedSavedQuestions.push(questionAbsoluteIndex);
    } else {
      updatedSavedQuestions.splice(
        updatedSavedQuestions.indexOf(questionAbsoluteIndex),
        1
      );
    }
    setSavedQuestions(updatedSavedQuestions);
  };

  const toggleExplanationVisibility = (questionIndex) => {
    if (isSubmitted) {
      const updatedExplanationsVisible = [...explanationsVisible];
      updatedExplanationsVisible[questionIndex] =
        !updatedExplanationsVisible[questionIndex];
      setExplanationsVisible(updatedExplanationsVisible);
    }
  };

  const renderQuestion = (question, questionIndex) => (
    <div
      key={questionIndex}
      className="options-grid max-h-[70vh] min-h-[70vh] px-[5px] overflow-y-scroll"
    >
      {question?.questionTextAndImages[0]?.text[0] ? (
        <div className="question-box">
          <div className="flex flex-col">
            <div className="flex justify-between">
              <div className="px-4 py-4">
                {/* <span className={`question-number id-${question._id}`}>
                    {`P${currentPage + 1}`}
                  </span> */}
              </div>
            </div>
            <div className="text-[20px]  pl-8 flex items-center pb-2  px-4">
              <div>
                {question?.questionTextAndImages?.map((textData, textIndex) => (
                  <div key={textIndex}>
                    {textData?.text && (
                      <MathText
                        className="question-text mb-2"
                        text={textData?.text}
                        textTag="h6"
                      />
                    )}
                    {textData?.image && (
                      <div>
                        <img
                          src={textData?.image}
                          alt={`Img ${textIndex}+1`}
                          className="question-image"
                        />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="question-box ">
        <div className="flex  flex-col">
          <div className="flex justify-between">
            <div className="px-4 py-4">
              <span className={`question-number id-${question?._id}`}>
                {`${questionIndex + 1 + currentPage * 1} `}
              </span>
            </div>
            {!isSubmitted && (
              <div
                className={` question-number2 cursor-pointer  m-2  ${
                  savedQuestions?.includes(questionIndex + currentPage * 1)
                    ? "saved"
                    : ""
                }`}
                onClick={() => handleSaveQuestion(questionIndex)}
              >
                {savedQuestions?.includes(questionIndex + currentPage * 1) ? (
                  <>
                    <IoBookmark className="text-[25px]" />
                  </>
                ) : (
                  <>
                    <IoBookmarkOutline className="text-[25px]" />
                  </>
                )}
              </div>
            )}
          </div>
          <div className="text-[20px] pb-2  pl-8 flex items-center px-4   ">
            <div>
              {question?.subQuestions[0]?.questionTextAndImages?.map(
                (textData, textIndex) => (
                  <div key={textIndex}>
                    <MathText
                      className="question-text mb-2"
                      text={textData?.text}
                      textTag="h6"
                    />

                    {textData?.image && (
                      <img
                        src={textData?.image}
                        alt={`Img ${textIndex}+1`}
                        className="question-image"
                      />
                    )}
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
      {question?.subQuestions[0]?.options?.map((option, optionIndex) => (
        <div
          key={optionIndex}
          className={`option-box ${
            !isSubmitted &&
            selectedOptions[currentPage] &&
            selectedOptions[currentPage][questionIndex] === optionIndex
              ? "bg-gradient-to-br from-[#868fe4] to-[#677be9] text-white"
              : isSubmitted &&
                selectedOptions[currentPage] &&
                selectedOptions[currentPage][questionIndex] === optionIndex &&
                question?.subQuestions[0]?.correctOptionIndex === optionIndex
              ? "correct"
              : isSubmitted &&
                selectedOptions[currentPage] &&
                selectedOptions[currentPage][questionIndex] === optionIndex &&
                question?.subQuestions[0]?.correctOptionIndex !== optionIndex
              ? "incorrect"
              : ""
          }`}
          onClick={() =>
            handleOptionClick(question, questionIndex, optionIndex, currentPage)
          }
        >
          <div className="flex justify-start items-center">
            <span className="option-alphabet ">{alphabets[optionIndex]}</span>
            <div className="flex mx-2 text-[20px]  relative  justify-start gap-3 w-100 items-center ">
              <MathText text={option?.text} textTag="h6" />
              <div className="single-image-container">
                {option?.image && (
                  <div>
                    <img
                      className="question-image"
                      src={option?.image}
                      alt={`Img ${optionIndex + 1}`}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="">
            {question?.subQuestions[0]?.correctOptionIndex === optionIndex &&
              isSubmitted &&
              selectedOptions[currentPage] &&
              selectedOptions[currentPage][questionIndex] === optionIndex && (
                <span className="relative mx-2 ">
                  <i className="fa-solid fa-check "></i>
                </span>
              )}
            {isSubmitted &&
              selectedOptions[currentPage] &&
              selectedOptions[currentPage][questionIndex] === optionIndex &&
              question?.subQuestions[0]?.correctOptionIndex !== optionIndex && (
                <span className=" relative mx-2  ">
                  <i className="fa-solid fa-xmark"></i>
                </span>
              )}
          </div>
        </div>
      ))}

      <div className="w-100 flex justify-center items-center">
        {isSubmitted && (
          <button
            className=" btn  btn-outline btn-tertiary text-capitalize  flex justify-center"
            onClick={() => toggleExplanationVisibility(questionIndex)}
          >
            {explanationsVisible[questionIndex]
              ? "Hide Explanation"
              : "Show Explanation"}
          </button>
        )}
      </div>
      <div className="explanation-wrapper ">
        {explanationsVisible[questionIndex] && (
          <div className="explanation">
            <div className="flex flex-row gap-2 justify-start items-center">
              <h6 className="mb-0  text-blueviolet-100 fw-bold">
                <strong>Answer: </strong>
              </h6>
              <h6 className="mb-0   text-salmon-200">
                <strong>
                  {" "}
                  Option{" "}
                  {question?.subQuestions[0]?.correctOptionIndex !== undefined
                    ? alphabets[question?.subQuestions[0]?.correctOptionIndex]
                    : ""}
                </strong>
              </h6>
            </div>

            <h6 className="text-blueviolet-100">
              <strong> Solution:</strong>
            </h6>
            {question?.subQuestions[0]?.explanation?.map(
              (explanationData, index) => (
                <p className="m-0" key={index}>
                  {explanationData?.text?.map((text, textIndex) => (
                    <MathText
                      className="explanation-text mb-2"
                      key={textIndex}
                      text={text}
                      textTag="h6"
                    />
                  ))}
                  {explanationData?.image && (
                    <div>
                      <img
                        className="question-image"
                        src={explanationData?.image}
                        alt={`Img ${index + 1}`}
                      />
                    </div>
                  )}
                </p>
              )
            )}
          </div>
        )}
      </div>
    </div>
  );
  const generatePageNumbers = () => {
    const totalPages = Math.ceil(data?.data?.length / 1);
    const pages = [];

    for (let i = 1; i <= totalPages; i++) {
      let isSelected = false;
      if (!isSubmitted) {
        isSelected = selectedOptions[i - 1]?.length > 0;
      }
      const pageStatus = isSubmitted
        ? checkPageStatusAfterSubmission(i - 1)
        : null;
      pages.push({ number: i, isSelected, pageStatus });
    }

    return pages;
  };

  const checkPageStatusAfterSubmission = (pageIndex) => {
    const questionsOnPage = data?.data?.slice(
      pageIndex * 1,
      (pageIndex + 1) * 1
    );
    let correctCount = 0;
    let incorrectCount = 0;
    let notAttemptedCount = 0;

    questionsOnPage?.forEach((question, index) => {
      const selectedOptionIndex = selectedOptions[pageIndex]
        ? selectedOptions[pageIndex][index]
        : null;
      if (selectedOptionIndex !== null) {
        const isCorrect =
          question?.subQuestions[0]?.correctOptionIndex === selectedOptionIndex;
        if (isCorrect) {
          correctCount++;
        } else {
          incorrectCount++;
        }
      } else {
        notAttemptedCount++;
      }
    });

    if (correctCount > 0 && incorrectCount === 0 && notAttemptedCount === 0) {
      return "correct";
    } else if (incorrectCount > 0) {
      return "incorrect";
    } else if (
      notAttemptedCount > 0 &&
      correctCount === 0 &&
      incorrectCount === 0
    ) {
      return "notAttempted";
    }

    return null;
  };

  const totalQuestions = data?.data?.length;
  const totalAttempts = userAttempts.size;
  const correctQuestionCount = [...userAttempts.values()].filter(
    (isCorrect) => isCorrect
  ).length;
  const correctPercentage = (correctQuestionCount / totalQuestions) * 100;
  const incorrectPercentage = (15 / totalQuestions) * 100;
  return (
    <section className="mx-auto mb-8 max-w-[1280px] ">
      <div
        className={`flex px-5 max-md:top-[-12px] top-[-25px] lg:hidden rounded  text-black  relative items-center`}
      >
        <div
          className={`text-[20px] gap-1 rounded-xl px-10 border-[1px] border-black border-solid py-3 bg-gradient-to-br overflow-hidden  flex justify-center items-center `}
        >
          <div className="text-[20px] ">
            <TfiTimer />
          </div>
          {Math.floor(timer / 60)
            .toString()
            .padStart(2, "0")}
          :{(timer % 60).toString().padStart(2, "0")}
        </div>
        {/* <div className="w-full ml-6 flex justify-center items-center">
          {isLoading ? (
            <CuetLoader />
          ) : (
            <>
              {!isSubmitted ? (
                <button
                  className="border-none cursor-pointer shadow-2xl relative flex justify-center items-center rounded bg-gradient-to-br overflow-hidden from-[#5648FC] to-[#5648FC] text-white px-7 p-3"
                  onClick={handleWarningExam}
                >
                  Submit
                </button>
              ) : (
                <button
                  className="border-none cursor-pointer shadow-2xl relative flex justify-center items-center rounded bg-gradient-to-br overflow-hidden from-[#5648FC] to-[#5648FC] text-white px-4 md:px-7 p-3"
                  onClick={() => window.location.reload()}
                >
                  Retake Test
                </button>
                
              )}
            </>
          )}
        </div> */}
        <div className="w-full ml-6 flex flex-wrap justify-center items-center gap-3">
          {isLoading ? (
            <CuetLoader />
          ) : (
            <>
              {!isSubmitted ? (
                <button
                  className="border-none cursor-pointer shadow-2xl flex justify-center items-center rounded bg-gradient-to-br overflow-hidden from-[#5648FC] to-[#5648FC] text-white px-7 p-3 w-full md:w-auto"
                  onClick={handleWarningExam}
                >
                  Submit
                </button>
              ) : (
                <>
                  {/* Retake Test Button */}
                  <button
                    className="border-none cursor-pointer shadow-2xl flex justify-center items-center rounded bg-gradient-to-br overflow-hidden from-[#5648FC] to-[#5648FC] text-white px-7 p-3 w-full md:w-auto"
                    onClick={() => window.location.reload()}
                  >
                    Retake Test
                  </button>

                  <button
                    className="border-none cursor-pointer shadow-2xl flex justify-center items-center rounded bg-gradient-to-br overflow-hidden from-[#FF820F] to-[#FF4500] text-white px-7 p-3 w-full md:w-auto"
                    onClick={() => setShowResultPopup(true)}
                  >
                    View Report
                  </button>
                </>
              )}
            </>
          )}
        </div>
      </div>
      <div className="flex md:mx-10 gap-5 justify-between">
        <div className="w-full ">
          {data?.data
            .slice(currentPage, currentPage + 1)
            .map((question, questionIndex) =>
              renderQuestion(question, questionIndex)
            )}
          <div className="flex items-center mt-8 justify-between">
            <button
              className=" border-none cursor-pointer shadow-2xl relative flex justify-center items-center rounded bg-gradient-to-br overflow-hidden from-[#5648FC] to-[#5648FC] text-white px-7 p-3"
              onClick={() => setCurrentPage((prev) => prev - 1)}
              disabled={currentPage === 0}
            >
              Prev
            </button>
            <button
              className=" border-none text-center md:hidden shadow-2xl relative flex justify-center items-center rounded bg-gradient-to-br overflow-hidden from-[#5648FC] to-[#5648FC] text-white px-4 p-3"
              onClick={handlePopupOpen}
            >
              All Questions
            </button>

            <button
              className=" border-none cursor-pointer shadow-2xl relative flex justify-center items-center rounded bg-gradient-to-br overflow-hidden from-[#5648FC] to-[#5648FC] text-white px-7 p-3"
              onClick={() => setCurrentPage((prev) => prev + 1)}
              disabled={currentPage === data?.data?.length - 1}
            >
              Next
            </button>
          </div>
        </div>
        <div className="max-w-[400px] scroll-kit max-h-[630px] h-fit overflow-y-scroll relative left-8 border-solid border-[1px] rounded-2xl   border-black mx-8 max-lg:hidden  flex flex-col items-center justify-center ">
          <div
            className={`flex px-5 max-lg:hidden rounded-xl mt-2 border border-white border-solid py-3 bg-gradient-to-br overflow-hidden  text-black justify-center relative items-center`}
          >
            <div className="text-[20px] flex justify-center items-center px-2">
              <TfiTimer />
            </div>
            <div>
              {Math.floor(timer / 60)
                .toString()
                .padStart(2, "0")}
              :{(timer % 60).toString().padStart(2, "0")}
            </div>
          </div>
          <div className="my-4 max-w-[400px]   justify-center items-center mx-auto flex flex-wrap overflow-auto ">
            {generatePageNumbers().map(({ number, isSelected, pageStatus }) => (
              <button
                key={number}
                className={`rounded border-none cursor-pointer shadow-xl w-[50px] h-[50px] m-1 ${
                  currentPage === number - 1
                    ? "bg-gradient-to-br overflow-hidden from-[#5648FC] to-[#5648FC] text-white"
                    : isSelected
                    ? "bg-slate-400 text-white"
                    : pageStatus === "correct"
                    ? "bg-green-500 text-white"
                    : pageStatus === "incorrect"
                    ? "bg-red-400 text-white"
                    : savedQuestions.includes(number - 1) && !isSubmitted
                    ? "bg-[#FF7468] text-white"
                    : "bg-white"
                }`}
                onClick={() => setCurrentPage(number - 1)}
              >
                {number}
                {isSubmitted && (
                  <div className="bottom-[30px] left-[8px] relative">
                    {pageStatus === "correct" && (
                      <i className="fa-solid fa-check absolute ml-1"></i>
                    )}
                    {pageStatus === "incorrect" && (
                      <i className="fa-solid absolute fa-xmark  ml-1"></i>
                    )}
                    {pageStatus === "notAttempted" && (
                      <i className="fa-regular ml-1 absolute  fa-circle-question"></i>
                    )}
                  </div>
                )}
              </button>
            ))}
          </div>
          <div className="w-[80%] ml-6 flex flex-wrap justify-center items-center gap-2">
            {isLoading ? (
              <CuetLoader />
            ) : (
              <>
                {!isSubmitted ? (
                  <button
                    className="border-none cursor-pointer shadow-md flex justify-center items-center rounded bg-gradient-to-br overflow-hidden from-[#5648FC] to-[#5648FC] text-white text-sm px-3 p-2 w-auto"
                    onClick={handleWarningExam}
                  >
                    Submit
                  </button>
                ) : (
                  <>
                    {/* Retake Test Button */}
                    <button
                      className="border-none cursor-pointer shadow-md flex justify-center items-center rounded bg-gradient-to-br overflow-hidden from-[#5648FC] to-[#5648FC] text-white text-sm px-3 p-2 w-auto mb-2"
                      onClick={() => window.location.reload()}
                    >
                      Retake
                    </button>

                    {/* View Score Report Button */}
                    <button
                      className="border-none cursor-pointer shadow-md ml-2 flex justify-center items-center rounded bg-gradient-to-br overflow-hidden from-[#FF820F] to-[#FF4500] text-white text-sm px-3 p-2 w-auto mb-2"
                      onClick={() => setShowResultPopup(true)}
                    >
                      View Report
                    </button>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      {showPopup && (
        <div className="fixed popupquestion inset-0 z-[1000]  flex flex-col items-center justify-center overflow-hidden">
          <div className="backdrop-blur backdrop-filter bg-[#0000004f] absolute inset-0"></div>
          <div className="relative justify-center flex flex-wrap  mx-10">
            {generatePageNumbers().map(({ number, isSelected, pageStatus }) => (
              <button
                key={number}
                className={`rounded border-none cursor-pointer shadow-xl w-[50px] h-[50px] m-1 ${
                  currentPage === number - 1
                    ? "bg-gradient-to-br overflow-hidden from-[#5648FC] to-[#5648FC] text-white"
                    : isSelected
                    ? "bg-slate-400 text-white"
                    : pageStatus === "correct"
                    ? "bg-green-500 text-white"
                    : pageStatus === "incorrect"
                    ? "bg-red-400 text-white"
                    : savedQuestions.includes(number - 1) && !isSubmitted
                    ? "bg-[#FF7468] text-white"
                    : "bg-white"
                }`}
                onClick={() => {
                  setCurrentPage(number - 1);
                  handlePopupClose();
                }}
              >
                {number}
                {isSubmitted && (
                  <div className="bottom-[30px] left-[8px] relative">
                    {pageStatus === "correct" && (
                      <i className="fa-solid fa-check absolute ml-1"></i>
                    )}
                    {pageStatus === "incorrect" && (
                      <i className="fa-solid absolute fa-xmark  ml-1"></i>
                    )}
                    {pageStatus === "notAttempted" && (
                      <i className="fa-regular ml-1 absolute  fa-circle-question"></i>
                    )}
                  </div>
                )}
              </button>
            ))}
          </div>
        </div>
      )}

      {showResultPopup && (
        <div className="popup-overlay">
          {testResults ? (
            <ScoreReport
              data={testResults}
              onClose={handleResultPopupClose}
              onRetakeTest={handleRetakeTest}
              onReviewTest={handleReviewTest}
            />
          ) : (
            <div className="result-popup flex items-center justify-center">
              <CuetLoader />
            </div>
          )}
        </div>
      )}
      {showWarningPopup && (
        <div className="popup-overlay ">
          <div className="warning-popup bg-white rounded-xl">
            <div className="flex justify-between">
              <h2 className="font-bold mx-4 text-[20px]">Finish Test?</h2>
              <h2 className="mx-4 cursor-pointer" onClick={handleContinueExam}>
                <RxCross1 />
              </h2>
            </div>
            <hr className="my-1" />
            <div className="px-8 py-4">
              <p>Are you sure you want to finish this test?</p>
              <p className=" text-[20px]">
                Time is running out!{" "}
                <span className="text-red-500">
                  Only {Math.floor(timer / 60)}:
                  {(timer % 60).toString().padStart(2, "0")} left.
                </span>
              </p>
              <p>You will be unable to resume after you finish this test.</p>
            </div>
            <hr className="my-1" />

            <div className="flex mx-4 my-3  justify-end">
              <button
                className="border-none mr-2 cursor-pointer shadow-2xl mb-2 relative flex justify-center items-center rounded bg-gradient-to-br overflow-hidden from-[#FF7468] to-[#FF7468] text-white px-7 p-3"
                onClick={handleContinueExam}
              >
                Continue Exam
              </button>
              <button
                className="border-none cursor-pointer shadow-2xl mb-2 relative flex justify-center items-center rounded bg-gradient-to-br overflow-hidden from-[#5648FC] to-[#5648FC] text-white px-7 p-3"
                onClick={handleSubmitWarning}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default PracticeTestQues;
