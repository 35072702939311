import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API } from '../../../utils/constants';
import { useAuth } from '../../../utils/context';
import { useSearch } from '../../../utils/SearchProvider';

const StudentsList = () => {
  const [rankings, setRankings] = useState([]);
  const [filteredRankings, setFilteredRankings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [institute, setInstitute] = useState('');
  const { searchTerm } = useSearch();
  const [auth] = useAuth();

  useEffect(() => {
    const fetchRankings = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${API}/scores/ranking/institute/${auth.user?._id}`,
          {
            headers: {
              Authorization: `Bearer ${auth.accessToken.token}`,
            },
          }
        );
        
        if (response.data.success) {
          setRankings(response.data.rankings);
          setFilteredRankings(response.data.rankings);
          setInstitute(response.data.institute);
        }
      } catch (error) {
        console.error("Error fetching rankings:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (auth?.accessToken?.token) {
      fetchRankings();
    }
  }, [auth.user?._id, auth.accessToken?.token]);

  // Filter students when search term changes
  useEffect(() => {
    if (searchTerm.trim() === '') {
      setFilteredRankings(rankings);
    } else {
      const filtered = rankings.filter(ranking => 
        ranking.userName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        ranking.email.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredRankings(filtered);
    }
  }, [searchTerm, rankings]);

  // Format score to display with fixed decimal places
  const formatScore = (score) => {
    return score ? score.toFixed(1) + '%' : 'N/A';
  };

  return (
    <div className="w-full bg-white min-h-full mx-auto p-4">
      <div className="flex justify-between items-center mb-6">
        <div>
          <h1 className="text-xl font-bold">Student Rankings</h1>
          {institute && <p className="text-gray-600">Institute: {institute}</p>}
        </div>
        {searchTerm && (
          <div className="text-blue-600">
            {filteredRankings.length} result{filteredRankings.length !== 1 ? 's' : ''} for "{searchTerm}"
          </div>
        )}
      </div>
      
      {isLoading ? (
        <div className="flex justify-center items-center h-40">
          <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-solid border-blue-500"></div>
        </div>
      ) : filteredRankings.length === 0 ? (
        <div className="text-center py-10">
          <p className="text-gray-500">No students found matching "{searchTerm}"</p>
        </div>
      ) : (
        <div className="overflow-x-auto">
          <table className="w-full border-collapse">
            <thead>
              <tr className="bg-blue-50">
                <th className="p-3 text-left border border-gray-200">Rank</th>
                <th className="p-3 text-left border border-gray-200">Student Name</th>
                <th className="p-3 text-left border border-gray-200">Email</th>
                <th className="p-3 text-left border border-gray-200">Overall Score</th>
                <th className="p-3 text-left border border-gray-200">Topics Attempted</th>
              </tr>
            </thead>
            <tbody>
              {filteredRankings.map((ranking) => (
                <tr key={ranking.userId} className="hover:bg-gray-50">
                  <td className="p-3 border-b border-gray-200">#{ranking.rank}</td>
                  <td className="p-3 border-b border-gray-200">
                    <div className="flex items-center gap-2">
                      {ranking.profilePic && (
                        <img 
                          src={ranking.profilePic} 
                          alt={`${ranking.userName}'s avatar`} 
                          className="w-8 h-8 rounded-full"
                        />
                      )}
                      {ranking.userName}
                    </div>
                  </td>
                  <td className="p-3 border-b border-gray-200">{ranking.email}</td>
                  <td className="p-3 border-b border-gray-200 font-medium">
                    {formatScore(ranking.overallScore)}
                  </td>
                  <td className="p-3 border-b border-gray-200">{ranking.totalTopicsAttempted}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default StudentsList;