import React from 'react'

const UserReports = () => {
    return (
        <div className="w-full min-h-full mx-auto p-4 bg-white">
            <div className="mb-6">
                <h1 className="text-xl font-bold mb-4">Reports</h1>
            </div>
        </div>

    )
}

export default UserReports 