export const API = "https://api.testknock.com/api";
// export const API = "http://localhost:8800/api";
export const API2 = "https://api.testknock.com/api";
export const MOCKAPI = "https://platform.testknock.com";
export const subjects = [
  {
    subjectName: "Accounting",
    image: "/syllabusImages/amico.png",
    link: "https://cdnbbsr.s3waas.gov.in/s3d1a21da7bca4abff8b0b61b87597de73/uploads/2025/03/2025030181.pdf",
  },
  {
    subjectName: "Agriculture",
    image: "/syllabusImages/rice-field/rafiki.png",
    link: "https://cdnbbsr.s3waas.gov.in/s3d1a21da7bca4abff8b0b61b87597de73/uploads/2025/03/2025030135.pdf",
  },
  {
    subjectName: "Anthropology",
    image: "/syllabusImages/Sandy_Edu-04_Single-03 4.png",
    link: "https://cdnbbsr.s3waas.gov.in/s3d1a21da7bca4abff8b0b61b87597de73/uploads/2025/03/2025030155.pdf",
  },
  {
    subjectName: "Art Education Sculpture",
    image: "/syllabusImages/art/cuate.png",
    link: "https://cdnasb.samarth.ac.in/site/Syllabus%20of%20CUET%20%28UG%29%20-%202022/312_ArtEducation_Painting&Sculpture.pdf",
  },
  {
    subjectName: "Biology",
    image: "/syllabusImages/biologist/rafiki.png",
    link: "https://cdnbbsr.s3waas.gov.in/s3d1a21da7bca4abff8b0b61b87597de73/uploads/2025/03/2025030122.pdf",
  },
  {
    subjectName: "Business Studies",
    image: "/syllabusImages/Business startup statistics and insights.png",
    link: "https://cdnbbsr.s3waas.gov.in/s3d1a21da7bca4abff8b0b61b87597de73/uploads/2025/03/2025030154-1.pdf",
  },
  {
    subjectName: "Chemistry",
    image: "/syllabusImages/science/pana.png",
    link: "https://cdnbbsr.s3waas.gov.in/s3d1a21da7bca4abff8b0b61b87597de73/uploads/2025/03/2025030186.pdf",
  },
  {
    subjectName: "Computer Science",
    image: "/syllabusImages/researching/amico.png",
    link: "https://cdnbbsr.s3waas.gov.in/s3d1a21da7bca4abff8b0b61b87597de73/uploads/2025/03/2025030172.pdf",
  },
  {
    subjectName: "Economics/Business Economics",
    image: "/syllabusImages/Financial growth.png",
    link: "https://cdnbbsr.s3waas.gov.in/s3d1a21da7bca4abff8b0b61b87597de73/uploads/2025/03/2025030110.pdf",
  },
  {
    subjectName: "Languages (IA & IB)",
    image: "/syllabusImages/learning-languages/pana.png",
    link: "https://cdnasb.samarth.ac.in/site/Syllabus%20of%20CUET%20%28UG%29%20-%202022/LanguageSyllabus(IAandIB).pdf",
  },
  {
    subjectName: "Engineering Graphics",
    image: "/syllabusImages/Sandy_Edu-04_Single-03 4.png",
    link: "https://cdnasb.samarth.ac.in/site/Syllabus%20of%20CUET%20%28UG%29%20-%202022/310_Eng.Graphics.pdf",
  },
  {
    subjectName: "Entrepreneurship",
    image: "/syllabusImages/Sandy_Edu-04_Single-03 4.png",
    link: "https://cdnasb.samarth.ac.in/site/Syllabus%20of%20CUET%20%28UG%29%20-%202022/311_Entrepreneurship_.pdf",
  },
  {
    subjectName: "Environmental Studies",
    image: "/syllabusImages/nature/cuate.png",
    link: "https://cdnbbsr.s3waas.gov.in/s3d1a21da7bca4abff8b0b61b87597de73/uploads/2025/03/2025030166.pdf",
  },
  {
    subjectName: "General Test",
    image: "/syllabusImages/online-test/rafiki.png",
    link: "https://cdnasb.samarth.ac.in/site-admin23/syllabus/501_General_Test.pdf",
  },
  {
    subjectName: "Legal Studies",
    image: "/syllabusImages/law-firm/amico.png",
    link: "https://cdnasb.samarth.ac.in/site/Syllabus%20of%20CUET%20%28UG%29%20-%202022/317_LegalStudies.pdf",
  },
  {
    subjectName: "Geography",
    image: "/syllabusImages/Sandy_Edu-04_Single-03 4.png",
    link: "https://cdnbbsr.s3waas.gov.in/s3d1a21da7bca4abff8b0b61b87597de73/uploads/2025/03/2025030191.pdf",
  },
  {
    subjectName: "History",
    image: "/syllabusImages/time-machine/rafiki.png",
    link: "https://cdnbbsr.s3waas.gov.in/s3d1a21da7bca4abff8b0b61b87597de73/uploads/2025/03/2025030112.pdf",
  },
  {
    subjectName: "Home Science",
    image: "/syllabusImages/Sandy_Edu-04_Single-03 4.png",
    link: "https://cdnbbsr.s3waas.gov.in/s3d1a21da7bca4abff8b0b61b87597de73/uploads/2025/03/2025030116.pdf",
  },
  {
    subjectName: "Knowledge Tradition -Practices India",
    image: "/syllabusImages/Sandy_Edu-04_Single-03 4.png",
    link: "https://cdnbbsr.s3waas.gov.in/s3d1a21da7bca4abff8b0b61b87597de73/uploads/2025/03/2025030146-1.pdf",
  },
  {
    subjectName: "Mass Media/ Mass Communication",
    image: "/syllabusImages/marketing/rafiki.png",
    link: "https://cdnbbsr.s3waas.gov.in/s3d1a21da7bca4abff8b0b61b87597de73/uploads/2025/03/20250301100.pdf",
  },
  {
    subjectName: "Mathematics",
    image: "/syllabusImages/mathematics/amico.png",
    link: "https://cdnbbsr.s3waas.gov.in/s3d1a21da7bca4abff8b0b61b87597de73/uploads/2025/03/2025030159.pdf",
  },
  {
    subjectName: "Performing Arts",
    image: "/syllabusImages/acting/amico.png",
    link: "https://cdnbbsr.s3waas.gov.in/s3d1a21da7bca4abff8b0b61b87597de73/uploads/2025/03/2025030188.pdf",
  },
  {
    subjectName: "Physical Education",
    image: "/syllabusImages/physical-education/pana.png",
    link: "https://cdnbbsr.s3waas.gov.in/s3d1a21da7bca4abff8b0b61b87597de73/uploads/2025/03/2025030186-1.pdf",
  },
  {
    subjectName: "Physics",
    image: "/syllabusImages/researching/amico.png",
    link: "https://cdnbbsr.s3waas.gov.in/s3d1a21da7bca4abff8b0b61b87597de73/uploads/2025/03/2025030177.pdf",
  },
  {
    subjectName: "Political Science",
    image: "/syllabusImages/Political debates.png",
    link: "https://cdnbbsr.s3waas.gov.in/s3d1a21da7bca4abff8b0b61b87597de73/uploads/2025/03/2025030114.pdf",
  },
  {
    subjectName: "Psychology",
    image: "/syllabusImages/group-therapy/rafiki.png",
    link: "https://cdnbbsr.s3waas.gov.in/s3d1a21da7bca4abff8b0b61b87597de73/uploads/2025/03/2025030193.pdf",
  },
  {
    subjectName: "Sanskrit",
    image: "/syllabusImages/Sandy_Edu-04_Single-03 4.png",
    link: "https://cdnbbsr.s3waas.gov.in/s3d1a21da7bca4abff8b0b61b87597de73/uploads/2025/03/2025030199.pdf",
  },
  {
    subjectName: "Sociology",
    image: "/syllabusImages/Business statistics.png",
    link: "https://cdnbbsr.s3waas.gov.in/s3d1a21da7bca4abff8b0b61b87597de73/uploads/2025/03/2025030195.pdf",
  },
  {
    subjectName: "Teaching Aptitude",
    image: "/syllabusImages/online-test/rafiki.png",
    link: "https://cdnasb.samarth.ac.in/site-admin23/syllabus/327_TeachingAptitude.pdf",
  },
];
export const topics = {
  "General English": [
    "Spot the Error",
    "Sentence Correction",
    "Fill in the Blanks Single",
    "Fill in the Blanks Double",
    "Cloze Test",
    "Synonyms",
    "Antonyms",
    "One Word Substitution",
    "Idioms & Phrases",
    "Miscellaneous",
    "Jumbled Sentences & Parajumble",
    "Reading Comprehension",
  ],
  Mathematics: [
    "Relation and Function",
    "Inverse Trigonometry Function",
    "Matrices",
    "Determinant",
    "Continuity and Differentiability",
    "Application of Derivatives",
    "Integrals",
    "Application of Integral",
    "Differential Equation",
    "Vector Algebra",
    "Three Dimensional Geometry",
    "Linear Programming",
    "Probability",
  ],
  Physics: [
    "Electric Charges and Fields",
    "Electrostatic Potential and Capacitance",
    "Current Electricity",
    "Moving Charges and Magnetism",
    "Magnetism and Matter",
    "Electromagnetic Induction",
    "Alternating Current",
    "Electromagnetic Waves",
    "Ray Optics and Optical Instruments",
    "Wave Optics",
    "Dual Nature of Radiation and Matter",
    "Atoms",
    "Nuclei",
    "Electronic Devices",
    "Communication Systems",
  ],
  Chemistry: [
    "Solution",
    "Electrochemistry",
    "Chemical Kinetics",
    "The d & f-Block Elements",
    "Haloalkanes & Haloarenes",
    "Alcohols, Phenols and Ethers",
    "Aldehydes, Ketones and Carboxylic Acids",
    // "Amines",
    "Biomolecules",
  ],
  Biology: [
    "Reproduction in Organisms",
    "Sexual Reproduction in Flowering Plants",
    "Human Reproduction",
    "Reproductive Health",
    "Heredity and variation",
    "Molecular Basis of Inheritance",
    "Evolution",
    "Human Health and Disease",
    "Microbes in Human Welfare",
    "Biotechnology: Principle and Processes",
    "Biotechnology and its Applications",
    "Organisms and Environment",
    "Ecosystem",
    "Biodiversity and Conservation",
  ],
  Accountancy: [
    "Accounting for Partnership",
    "Reconstitution of Partnership",
    "Dissolution of Partnership Firm",
    // "Accounting for Share and Debenture Capital",
    "Analysis of Financial Statements",
    // "Statement of Changes in Financial Position",
    "Computerized Accounting System",
  ],
  Economics: {
    "Macro Economics": [
      "National Income and Real Aggregates",
      "Money and Banking",
      "Determination of Income and Employment",
      "Government Budget and the Economy",
      "Balance of Payment",
    ],
    "Micro Economics": [
      "Introduction to Microeconomics",
      "Consumer Behavior and Demand",
      // "Producer behaviour and supply",
      // "Forms of Market and Price Determination",
      // "Simple Applications of Tools of Demand and Supply Curves",
    ],
    // "Indian Economics": [
    //   "Human Capital formation in india",
    //   "Rural Development",
    //   "Sustainable Economic Development",
    //   "Infrastructure",
    //   "Development experience of india",
    //   "Poverty",
    //   "Employment",
    //   "Development experience 1947-90 and economic reforms since 1991",
    // ],
  },
  "General Test": {
    Mathematics: [
      "Number System",
      "Algebra",
      "Simplification & Calculation",
      "Average",
      "Percentage",
      "Fraction, Decimals & Square root",
      "Ratio & Proportion",
      "Profit & Loss",
      "S.I & C.I",
      "Partnership",
      "Time, Speed & Distance",
      "Time & Work",
      "Mensuration",
      "Geometry",
      "Permutation & Combination",
      "Probability",
    ],
    "Logical Reasoning": [
      "Alphabet series",
      "Blood Relation",
      "Classification",
      "Clock and Calendar",
      "Critical Reasoning",
      "Arrangement Circular and Linear",
      "Coding Decoding",
      "Decision Making, Order and Ranking",
      "Direction Problem",
      "Number Series",
      "Puzzle",
      "Venn Diagram",
      "Visual Reasoning",
    ],
    "General Awareness": [
      "Art Culture and Literature",
      "Award and Honor",
      "Indian Geography",
      "Indian Economics",
      "Indian History",
      "Indian Polity",
      "General Science",
      "International Cooperation Organization",
      "Current Affairs Feb 2024",
      "Current Affairs Jan 2024",
      "Current Affairs Dec 2023",
      "Current Affairs Nov 2023",
      "Current Affairs 2025",
    ],
  },
  "Business Studies": [
    "Nature and significance of management",
    "Principles of management",
    "Business environment",
    "Planning",
    "Organising",
    "Staffing",
    "Directing",
    "Controlling",
    // "Financial management",
    // "Financial markets",
    "Marketing Management",
    "Consumer Protection",
    "Entrepreneurship Development",
    // "Business Finance",
  ],
  History: [
    "The Story of the First Cities Harappan Archaeology",
    "Political and Economic History: How Inscriptions tell a story",
    "Social Histories using the Mahabharata",
    "A History of Buddhism: Sanchi Stupa",
    "Medieval society through Travellers Accounts",
    "Religious Histories: The Bhakti-Sufi Tradition",
    "New Architecture: Hampi",
    "Agrarian Relations: The Ain-i-Akbari",
    // "The Mughal Court: Reconstructing Histories through Chronicles",
    "Colonialism and Rural Society: Evidence from Official Reports",
    "Representations of 1857",
    // "Colonialism and Indian Towns: Town Plans and Municipal Reports",
    "Mahatma Gandhi through Contemporary Eyes",
    // "Partition through Oral Sources",
    "The Making of the Constitution",
  ],
  "Political Science": [
    // "Cold War Era in World Politics",
    "The Collapse of Bipolarity",
    // "US Hegemony in World Politics",
    "Alternatives Centre of Power",
    "Contemporary South Asia",
    "International Organisations",
    "Security in the Contemporary World",
    "Environment & Natural Resources",
    "Globalisation",
    "Nation Building and Its Problems",
    "Era of One Party Dominance",
    "Politics of Planned Development",
    "India's External Relations",
    "Challenges to and Restoration of the Congress System",
    // "The Crisis of Democractic Order",
    "Crises of the Constitutional Order",
    // "Rise of Popular Movement",
    "Regional Aspirations",
    // "Democratic Upsurge and Coalition Politic",
    // "Democratic Upsurge and Coalition Politics",
  ],
  Geography: {
    "Fundamentals of Human Geography": [
      // "Population Distribution, Density, Growth and Composition",
      // "Migration Types, Cause and Consequences",
      // "Human Development",
      // "Human Settlements",
      // "Land Resources and Agriculture",
      // "Water Resources",
      // "Minerals and Energy Resources",
      // "Manufacturing Industries",
      // "Planning and Sustainable Development",
      // "Transport and Communication",
      // "International Trade",
      // "Geographical Perspective on Selected Issues and Problems",
      // "Human Geography, Nature and Scope",
      // "The World Population, Distribution, Density and Growth",
      // "Population Composition",
      // "Primary Activity",
      // "Secondary Activity",
      // "Tertiary Activity and Quaternary Activity",
      // "Cartography",
      // "Indian People and Economy",
      "Human Geography: Nature and Scope",
      "People",
      "Human Activities",
      "Transport, Communication and Trade",
    ],
    "India: People and Economy": [
      "People",
      "Human Settlements",
      "Resources and Development",
      "Transport, Communication and International Trade",
      "Geographical Perspective on Selected Issues and Problems",
    ],
  },
  Psychology: [
    "Variations in Psychological Attributes",
    "Self and Personality",
    "Meeting Life Challenges",
    "Psychological Disorders",
    "Therapeutic Approaches",
    "Attitude and Social Cognition",
    "Social Influence and Group Processes",
    // "Psychology and Life",
    // "Developing Psychological Skills",
  ],
  Sociology: [
    "Structure of Indian Society",
    "Social institutions: Continuity and Change",
    "Social Inequality and Exclusion",
    "The challenges of Unity in Diversity",
    "Process of Social Change in India",
    "Social Change and the Polity",
    "Social Change and the Economy",
    // "Arenas of Social Change",
    "New Arenas of Social Change",
    "Social Movements",
  ],
  "Physical Education": [
    "Sociological Aspects of Physical Education",
    "Training Methods",
    "Career Aspects in Physical Education: Career points",
    "Career Aspects in Physical Education: Tournaments",
    "Health Education and Health Problems",
    "Sports Injuries and First Aid",
    "Test and Measurement in Sports",
    "Biomechanics and Sports",
    "Psychology and Sports",
    // "Policy, Programmes and Career Options",
    // "Nutrition and Athlete’s Care Food and Nutrition",
    // " Yoga Basics of Yogic Practices",
    // " Theoretical Aspect of Games, Sports and Yogic Practice Basics of Games and Sports",
  ],

  Domain: {
    Science: {
      Maths: [
        "Relation and Function",
        "Inverse Trigonometry Function",
        "Matrices",
        "Determinant",
        "Continuity and Differentiability",
        "Application of Derivatives",
        "Integrals",
        "Application of Integral",
        "Differential Equation",
        "Vector Algebra",
        "Three Dimensional Geometry",
        "Linear Programming",
        "Probability",
      ],
      Physics: [
        "Electric Charges and Fields",
        "Electrostatic Potential and Capacitance",
        "Current Electricity",
        "Moving Charges and Magnetism",
        "Magnetism and Matter",
        "Electromagnetic Induction",
        "Alternating Current",
        "Electromagnetic Waves",
        "Ray Optics and Optical Instruments",
        "Wave Optics",
        "Dual Nature of Radiation and Matter",
        "Atoms",
        "Nuclei",
        "Electronic Devices",
        "Communication Systems",
      ],
      Chemistry: [
        "Solution",
        "Electrochemistry",
        "Chemical Kinetics",
        "The d & f-Block Elements",
        "Haloalkanes & Haloarenes",
        "Alcohols, Phenols and Ethers",
        "Aldehydes, Ketones and Carboxylic Acids",
        // "Amines",
        "Biomolecules",
      ],
      Biology: [
        "Reproduction in Organisms",
        "Sexual Reproduction in Flowering Plants",
        "Human Reproduction",
        "Reproductive Health",
        "Heredity and variation",
        "Molecular Basis of Inheritance",
        "Evolution",
        "Human Health and Disease",
        "Microbes in Human Welfare",
        "Biotechnology: Principle and Processes",
        "Biotechnology and its Applications",
        "Organisms and Environment",
        "Ecosystem",
        "Biodiversity and Conservation",
      ],
    },
    Commerce: {
      Accountancy: [
        // "Accounting not-for-Profit Organisation",
        "Accounting for Partnership",
        "Reconstitution of Partnership",
        "Dissolution of Partnership Firm",
        // "Accounting for Share and Debenture Capital",
        "Analysis of Financial Statements",
        // "Statement of Changes in Financial Position",
        "Computerized Accounting System",
      ],
      Economics: {
        MacroEconomics: [
          "National Income and Real Aggregates",
          "Money and Banking",
          "Determination of Income and Employment",
          "Government Budget and the Economy",
          "Balance of Payment",
        ],
        MicroEconomics: [
          "Introduction to Microeconomics",
          "Consumer Behavior and Demand",
          // "Producer behaviour and supply",
          // "Forms of Market and Price Determination",
          // "Simple Applications of Tools of Demand and Supply Curves",
        ],
        // IndianEconomics: [
        //   "Human Capital formation in india",
        //   "Rural Development",
        //   "Sustainable Economic Development",
        //   "Infrastructure",
        //   "Development experience of india",
        //   "Poverty",
        //   "Employment",
        //   "Development experience 1947 -90 and economic reforms since 1991",
        // ],
      },
      "Business Studies": [
        "Nature and significance of management",
        "Principles of management",
        "Business environment",
        "Planning",
        "Organising",
        "Staffing",
        "Directing",
        "Controlling",
        // "Financial management",
        // "Financial markets",
        "Marketing Management",
        "Consumer Protection",
        "Entrepreneurship Development",
        // "Business Finance",
      ],
      Mathematics: [
        "Relation and Function",
        "Inverse Trigonometry Function",
        "Matrices",
        "Determinant",
        "Continuity and Differentiability",
        "Application of Derivatives",
        "Integrals",
        "Application of Integral",
        "Differential Equation",
        "Vector Algebra",
        "Three Dimensional Geometry",
        "Linear Programming",
        "Probability",
      ],
    },
    "Humanities & Social Sciences": {
      History: [
        "The Story of the First Cities Harappan Archaeology",
        "Political and Economic History: How Inscriptions tell a story",
        "Social Histories using the Mahabharata",
        "A History of Buddhism: Sanchi Stupa",
        "Medieval society through Travellers Accounts",
        "Religious Histories: The Bhakti-Sufi Tradition",
        "New Architecture: Hampi",
        "Agrarian Relations: The Ain-i-Akbari",
        // "The Mughal Court: Reconstructing Histories through Chronicles",
        "Colonialism and Rural Society: Evidence from Official Reports",
        "Representations of 1857",
        // "Colonialism and Indian Towns: Town Plans and Municipal Reports",
        "Mahatma Gandhi through Contemporary Eyes",
        // "Partition through Oral Sources",
        "The Making of the Constitution",
      ],
      "Political Science": [
        // "Cold War Era in World Politics",
        "The Collapse of Bipolarity",
        // "US Hegemony in World Politics",
        "Alternatives Centre of Power",
        "Contemporary South Asia",
        "International Organisations",
        "Security in the Contemporary World",
        "Environment & Natural Resources",
        "Globalisation",
        "Nation Building and Its Problems",
        "Era of One Party Dominance",
        "Politics of Planned Development",
        "India's External Relations",
        "Challenges to and Restoration of the Congress System",
        // "The Crisis of Democractic Order",
        "Crises of the Constitutional Order",
        // "Rise of Popular Movement",
        "Regional Aspirations",
        // "Democratic Upsurge and Coalition Politics",
        // "Democratic Upsurge and Coalition Politics",
      ],
      Geography: {
        "Fundamentals of Human Geography": [
          // "Population Distribution, Density, Growth and Composition",
          // "Migration Types, Cause and Consequences",
          // "Human Development",
          // "Human Settlements",
          // "Land Resources and Agriculture",
          // "Water Resources",
          // "Minerals and Energy Resources",
          // "Manufacturing Industries",
          // "Planning and Sustainable Development",
          // "Transport and Communication",
          // "International Trade",
          // "Geographical Perspective on Selected Issues and Problems",
          // "Human Geography, Nature and Scope",
          // "The World Population, Distribution, Density and Growth",
          // "Population Composition",
          // "Primary Activity",
          // "Secondary Activity",
          // "Tertiary Activity and Quaternary Activity",
          // "Cartography",
          // "Indian People and Economy",
          "Human Geography: Nature and Scope",
          "People",
          "Human Activities",
          "Transport, Communication and Trade",
        ],
        "India: People and Economy": [
          "People",
          "Human Settlements",
          "Resources and Development",
          "Transport, Communication and International Trade",
          "Geographical Perspective on Selected Issues and Problems",
        ],
      },
      Psychology: [
        "Variations in Psychological Attributes",
        "Self and Personality",
        "Meeting Life Challenges",
        "Psychological Disorders",
        "Therapeutic Approaches",
        "Attitude and Social Cognition",
        "Social Influence and Group Processes",
        // "Psychology and Life",
        // "Developing Psychological Skills",
      ],
      Sociology: [
        "Structure of Indian Society",
        "Social institutions: Continuity and Change",
        "Social Inequality and Exclusion",
        "The challenges of Unity in Diversity",
        "Process of Social Change in India",
        "Social Change and the Polity",
        "Social Change and the Economy",
        // "Arenas of Social Change",
        "New Arenas of Social Change",
        "Social Movements",
      ],
      "Physical Education": [
        "Sociological Aspects of Physical Education",
        "Training Methods",
        "Career Aspects in Physical Education: Career points",
        "Career Aspects in Physical Education: Tournaments",
        "Health Education and Health Problems",
        "Sports Injuries and First Aid",
        "Test and Measurement in Sports",
        "Biomechanics and Sports",
        "Psychology and Sports",
        // "Policy, Programmes and Career Options",
        // "Nutrition and Athlete’s Care Food and Nutrition",
        // " Yoga Basics of Yogic Practices",
        // " Theoretical Aspect of Games, Sports and Yogic Practice Basics of Games and Sports",
      ],
      Mathematics: [
        "Relation and Function",
        "Inverse Trigonometry Function",
        "Matrices",
        "Determinant",
        "Continuity and Differentiability",
        "Application of Derivatives",
        "Integrals",
        "Application of Integral",
        "Differential Equation",
        "Vector Algebra",
        "Three Dimensional Geometry",
        "Linear Programming",
        "Probability",
      ],
    },
  },
};
