import React, { useState, useRef, useEffect } from 'react';
import { FaBook } from "react-icons/fa";

const SubjectCard = ({ subject, animate, index }) => {
  const MAX_TESTS = 15;
  const [showMockDetails, setShowMockDetails] = useState(false);
  const [showPracticeDetails, setShowPracticeDetails] = useState(false);
  const [showMockScoreTooltip, setShowMockScoreTooltip] = useState(false);
  const [showPracticeScoreTooltip, setShowPracticeScoreTooltip] = useState(false);
  const [showOverallScoreTooltip, setShowOverallScoreTooltip] = useState(false);
  const cardRef = useRef(null);
  const graphData = [30, 40, 60, 45, 80];

  while (graphData.length < 5) {
    graphData.push(30 + Math.floor(Math.random() * 50));
  }

  const displayGraphData = graphData.slice(-5);

  const formatSubjectName = (name) => {
    return name
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  // Calculate unique attempts for display
  const uniqueMockAttempts = subject.mockTestFrequency ? 
    new Set(subject.mockTestFrequency.map(item => item.testNumber)).size : 
    subject.mockAttempts;
    
  const uniquePracticeAttempts = subject.practiceTestFrequency ? 
    new Set(subject.practiceTestFrequency.map(item => item.testNumber)).size : 
    subject.practiceAttempts;
    
  const totalUniqueTests = uniqueMockAttempts + uniquePracticeAttempts;

  useEffect(() => {
    if (cardRef.current) {
      const height = cardRef.current.offsetHeight;
      cardRef.current.style.height = `${height}px`;
      
      setTimeout(() => {
        cardRef.current.style.height = 'auto';
      }, 10);
    }
  }, [showMockDetails, showPracticeDetails]);

  return (
    <div 
      ref={cardRef}
      className={`bg-white rounded-2xl p-4 md:p-6 shadow-md w-full transition-all duration-700 ease-in-out transform ${animate ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-8'} flex flex-col`} 
      style={{ 
        transitionDelay: `${index * 150}ms`, 
        minHeight: "200px",
        transition: "all 0.7s ease-in-out"
      }}
    >
      <div>
        <div className="bg-blue-100 w-8 h-8 flex items-center justify-center rounded-lg mb-1">
          <FaBook className='text-blue-400' />
        </div>
        <div className="text-gray-800 font-medium truncate">
          {formatSubjectName(subject.name)}
        </div>
      </div>

      <div className="flex justify-between items-end mt-2">
        <div 
          className="text-2xl md:text-[40px] font-bold relative cursor-help"
          onMouseEnter={() => setShowOverallScoreTooltip(true)}
          onMouseLeave={() => setShowOverallScoreTooltip(false)}
        >
          {subject.score.toFixed(1)}
          {showOverallScoreTooltip && (
            <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-1 px-2 py-1 bg-gray-800 text-white text-xs rounded whitespace-nowrap z-10">
              Overall Average Score
            </div>
          )}
        </div>
        
        <div className="flex items-end h-16 md:h-20 gap-[2px]">
          {displayGraphData.map((value, i) => (
            <div 
              key={i} 
              className={`w-2 md:w-4 rounded-md transition-all duration-700 ease-in-out ${
                i === 0 || i === 2 ? 'bg-blue-200' : 
                i === 4 ? 'bg-blue-600' : 'bg-blue-400'
              }`}
              style={{ 
                height: animate ? `${value}%` : '0%',
                transitionDelay: `${(index * 150) + (i * 80)}ms`
              }}
            ></div>
          ))}
        </div>
      </div>

      <div className="mt-3 flex flex-col gap-1 flex-grow">
        <div className="flex justify-between text-xs items-center">
          <div className="text-gray-700 font-medium">Mock Tests:</div>
          <div className="flex items-center">
            <div className="text-gray-600">
              {uniqueMockAttempts} of {MAX_TESTS} 
              <span 
                className={subject.mockScore > 0 ? "text-green-500 ml-1 relative cursor-help" : "text-gray-400 ml-1 relative cursor-help"}
                onMouseEnter={() => setShowMockScoreTooltip(true)}
                onMouseLeave={() => setShowMockScoreTooltip(false)}
              >
                ({subject.mockScore.toFixed(1)}%)
                {showMockScoreTooltip && (
                  <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-1 px-2 py-1 bg-gray-800 text-white text-xs rounded whitespace-nowrap z-10">
                    Average Mock Test Score
                  </div>
                )}
              </span>
            </div>
            <div 
              onClick={() => setShowMockDetails(!showMockDetails)}
              className={`ml-1 cursor-pointer text-blue-500 hover:text-blue-700 focus:outline-none ${!(subject.mockTestFrequency && subject.mockTestFrequency.length > 0) ? 'invisible' : ''}`}
            >
              {showMockDetails ? "▲" : "▼"}
            </div>
          </div>
        </div>
        
        <div className="pl-2 border-l-2 border-blue-100">
          <div 
            className={`transition-all duration-700 ease-in-out ${showMockDetails ? "max-h-[120px] opacity-100" : "max-h-0 opacity-0"} overflow-hidden`}
          >
            {subject.mockTestFrequency && subject.mockTestFrequency.length > 0 ? (
              <>
                <div className="overflow-auto">
                  {subject.mockTestFrequency.map((item, idx) => (
                    <div key={idx} className="flex justify-between text-xs text-gray-600">
                      <span>Mock Test {item.testNumber}:</span>
                      <span>{item.count} time{item.count !== 1 ? 's' : ''}</span>
                    </div>
                  ))}
                </div>
                <div className="flex justify-between text-xs text-gray-600 font-medium mt-1">
                  <span>Total Attempts:</span>
                  <span>{subject.totalMockAttemptCount}</span>
                </div>
               
              </>
            ) : null}
          </div>
        </div>

        <div className="flex justify-between text-xs items-center">
          <div className="text-gray-700 font-medium">Practice Tests:</div>
          <div className="flex items-center">
            <div className="text-gray-600">
              {uniquePracticeAttempts} of {MAX_TESTS} 
              <span 
                className={subject.practiceScore > 0 ? "text-green-500 ml-1 relative cursor-help" : "text-gray-400 ml-1 relative cursor-help"}
                onMouseEnter={() => setShowPracticeScoreTooltip(true)}
                onMouseLeave={() => setShowPracticeScoreTooltip(false)}
              >
                ({subject.practiceScore.toFixed(1)}%)
                {showPracticeScoreTooltip && (
                  <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-1 px-2 py-1 bg-gray-800 text-white text-xs rounded whitespace-nowrap z-10">
                    Average Practice Test Score
                  </div>
                )}
              </span>
            </div>
            <div 
              onClick={() => setShowPracticeDetails(!showPracticeDetails)}
              className={`ml-1 cursor-pointer text-blue-500 hover:text-blue-700 focus:outline-none ${!(subject.practiceTestFrequency && subject.practiceTestFrequency.length > 0) ? 'invisible' : ''}`}
            >
              {showPracticeDetails ? "▲" : "▼"}
            </div>
          </div>
        </div>
        
        <div className="pl-2 border-l-2 border-blue-100">
          <div 
            className={`transition-all duration-700 ease-in-out ${showPracticeDetails ? "max-h-[120px] opacity-100" : "max-h-0 opacity-0"} overflow-hidden`}
          >
            {subject.practiceTestFrequency && subject.practiceTestFrequency.length > 0 ? (
              <>
                <div className="overflow-auto">
                  {subject.practiceTestFrequency.map((item, idx) => (
                    <div key={idx} className="flex justify-between text-xs text-gray-600">
                      <span>Practice Test {item.testNumber}:</span>
                      <span>{item.count} time{item.count !== 1 ? 's' : ''}</span>
                    </div>
                  ))}
                </div>
                <div className="flex justify-between text-xs text-gray-600 font-medium">
                  <span>Total Attempts:</span>
                  <span>{subject.totalPracticeAttemptCount}</span>
                </div>
                
              </>
            ) : null}
          </div>
        </div>
  
        <div className="flex justify-between text-xs">
          <div className="text-gray-700 font-medium">Total Unique Tests:</div>
          <div className="text-gray-600">{totalUniqueTests}</div>
        </div>
      </div>
    </div>
  );
};

export default SubjectCard;