import { FaUser } from "react-icons/fa";
const StudentCountCard = ({ studentCount, userRank, animate }) => {
    return (
      <div className={`bg-white rounded-2xl max-md:p-4 p-2 px-3 md:pt-3 shadow-md w-full transition-all duration-500 ease-in-out transform ${animate ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-8'}`} style={{ transitionDelay: '300ms' }}>
        <h2 className="text-gray-800 font-medium mt-0 mb-1">Student Count</h2>
        
        {/* Gauge Chart */}
        <div className="flex justify-center mb-3">
          <div className="relative w-full h-12 md:h-14">
            <svg className="w-full h-full" viewBox="0 0 100 50">
              {/* Gauge background */}
              <path 
                d="M10,50 A40,40 0 0,1 90,50" 
                fill="none" 
                stroke="#f3f4f6" 
                strokeWidth="1"
              />
              
              {/* Gauge Lines */}
              {Array.from({ length: 50 }).map((_, i) => (
                <line 
                  key={i}
                  x1="50"
                  y1="50"
                  x2={50 + 40 * Math.cos(Math.PI * (0.8 + i * 0.02))}
                  y2={50 + 40 * Math.sin(Math.PI * (0.8 + i * 0.02))}
                  stroke="#4361ee"
                  strokeWidth="1"
                  strokeOpacity={animate ? 1 : 0}
                  className="transition-opacity duration-50"
                  style={{ transitionDelay: `${600 + i * 30}ms` }}
                />
              ))}
            </svg>
          </div>
        </div>
        
        {/* User Icon */}
        <div className="flex justify-center pb-2 -mt-1">
          <div className={`bg-blue-200 rounded-full p-2 w-8 h-8 flex items-center justify-center transition-all duration-500 transform ${animate ? 'scale-100' : 'scale-0'}`} style={{ transitionDelay: '1100ms' }}>
            <FaUser className='text-blue-500' />
          </div>
        </div>
        
        {/* Count and Label */}
        <div className="flex flex-col items-center justify-center mt-1">
          <div className={`text-xl font-bold transition-all duration-700 ${animate ? 'opacity-100' : 'opacity-0'}`} style={{ transitionDelay: '1300ms' }}>
            {studentCount}
          </div>
          <div className="text-xs text-gray-500">Students</div>
          
          {/* Rank information */}
          <div className={`mt-1 text-sm font-semibold ${userRank === 1 ? 'text-yellow-500' : userRank === 2 ? 'text-gray-500' : userRank === 3 ? 'text-amber-700' : 'text-blue-500'} transition-all duration-700 ${animate ? 'opacity-100' : 'opacity-0'}`} style={{ transitionDelay: '1400ms' }}>
            Rank: #{userRank}
          </div>
        </div>
        
        {/* Details Button */}
        {/* <div className="flex justify-center w-1/2 mx-auto mt-4">
          <Link to={"/profile/students-list"}
            className="bg-blue-100 text-blue-500 text-center font-bold px-6 py-2 rounded-md text-sm hover:bg-blue-200 transition-colors w-full"
          >
            Details
          </Link>
        </div> */}
      </div>
    );
  };
  
export default StudentCountCard;