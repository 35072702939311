import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { API } from '../utils/constants';

import { LoadingButton } from '@mui/lab';
import {
    Alert,
    Box,
    Button,
    Checkbox,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Snackbar,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
} from '@mui/material';

// MUI Icons
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import EditIcon from '@mui/icons-material/Edit';
import LockIcon from '@mui/icons-material/Lock';
import RefreshIcon from '@mui/icons-material/Refresh';


const CouponManagement = () => {
    // State variables
    const [coupons, setCoupons] = useState([]);
    const [loading, setLoading] = useState(false);
    const [adminPassword, setAdminPassword] = useState('');
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [openAuthDialog, setOpenAuthDialog] = useState(true);
    const [openCouponDialog, setOpenCouponDialog] = useState(false);
    const [dialogMode, setDialogMode] = useState('create'); // 'create' or 'edit'
    const [currentCoupon, setCurrentCoupon] = useState(null);
    const [alertInfo, setAlertInfo] = useState({ open: false, message: '', severity: 'success' });
    const [refreshing, setRefreshing] = useState(false);
    const [multiSelectMode, setMultiSelectMode] = useState(false);
    const [selectedCoupons, setSelectedCoupons] = useState([]);

    // Form state
    const [formData, setFormData] = useState({
        code: '',
        type: 'single-use',
        discountPercentage: 10,
        maxUsageLimit: 1,
        expiryDate: '',
        isActive: true
    });

    // Fetch coupons when authenticated
    useEffect(() => {
        if (isAuthenticated) {
            fetchCoupons();
        }
    }, [isAuthenticated]);

    // Reset form data when dialog opens
    useEffect(() => {
        if (openCouponDialog && dialogMode === 'create') {
            setFormData({
                code: '',
                type: 'single-use',
                discountPercentage: 10,
                maxUsageLimit: 1,
                expiryDate: '',
                isActive: true
            });
        } else if (openCouponDialog && dialogMode === 'edit' && currentCoupon) {
            setFormData({
                code: currentCoupon.code,
                type: currentCoupon.type,
                discountPercentage: currentCoupon.discountPercentage,
                maxUsageLimit: currentCoupon.maxUsageLimit,
                expiryDate: currentCoupon.expiryDate
                    ? new Date(currentCoupon.expiryDate).toISOString().split('T')[0]
                    : '',
                isActive: currentCoupon.isActive
            });
        }
    }, [openCouponDialog, dialogMode, currentCoupon]);

    // Fetch all coupons
    const fetchCoupons = async () => {
        try {
            setLoading(true);
            const response = await axios.post(`${API}/coupon/list`, { adminPassword });
            setCoupons(response.data.coupons);
            // Reset multi-select mode when fetching new data
            setMultiSelectMode(false);
            setSelectedCoupons([]);
        } catch (error) {
            console.error('Error fetching coupons:', error);
            showAlert('Failed to fetch coupons', 'error');
            if (error.response && error.response.status === 401) {
                setIsAuthenticated(false);
                setOpenAuthDialog(true);
            }
        } finally {
            setLoading(false);
            setRefreshing(false);
        }
    };

    // Handle admin authentication
    const handleAuthenticate = () => {
        if (adminPassword) {
            setIsAuthenticated(true);
            setOpenAuthDialog(false);
            fetchCoupons();
        } else {
            showAlert('Please enter the admin password', 'error');
        }
    };

    // Handle form input changes
    const handleInputChange = (e) => {
        const { name, value, checked } = e.target;
        setFormData({
            ...formData,
            [name]: name === 'isActive' ? checked : value
        });
    };

    // Open create coupon dialog
    const handleOpenCreateDialog = () => {
        setDialogMode('create');
        setOpenCouponDialog(true);
    };

    // Open edit coupon dialog
    const handleOpenEditDialog = (coupon) => {
        setCurrentCoupon(coupon);
        setDialogMode('edit');
        setOpenCouponDialog(true);
    };

    // Close coupon dialog
    const handleCloseDialog = () => {
        setOpenCouponDialog(false);
        setCurrentCoupon(null);
    };

    // Create or update coupon
    const handleSubmitCoupon = async () => {
        try {
            setLoading(true);

            if (dialogMode === 'create') {
                // Create new coupon
                await axios.post(`${API}/coupon/create`, {
                    ...formData,
                    adminPassword
                });
                showAlert('Coupon created successfully', 'success');
            } else {
                // Update existing coupon
                await axios.put(`${API}/coupon/update/${currentCoupon._id}`, {
                    ...formData,
                    adminPassword
                });
                showAlert('Coupon updated successfully', 'success');
            }

            handleCloseDialog();
            fetchCoupons();
        } catch (error) {
            console.error('Error saving coupon:', error);
            showAlert(
                error.response?.data?.message || 'Failed to save coupon',
                'error'
            );
        } finally {
            setLoading(false);
        }
    };

    // Delete coupon
    const handleDeleteCoupon = async (id) => {
        if (window.confirm('Are you sure you want to delete this coupon?')) {
            try {
                setLoading(true);
                await axios.delete(`${API}/coupon/delete/${id}`, {
                    data: { adminPassword }
                });
                showAlert('Coupon deleted successfully', 'success');
                fetchCoupons();
            } catch (error) {
                console.error('Error deleting coupon:', error);
                showAlert('Failed to delete coupon', 'error');
            } finally {
                setLoading(false);
            }
        }
    };

    // Delete multiple coupons
    const handleDeleteMultipleCoupons = async () => {
        if (selectedCoupons.length === 0) {
            showAlert('No coupons selected for deletion', 'warning');
            return;
        }

        if (window.confirm(`Are you sure you want to delete ${selectedCoupons.length} selected coupons?`)) {
            try {
                setLoading(true);
                const promises = selectedCoupons.map(id =>
                    axios.delete(`${API}/coupon/delete/${id}`, {
                        data: { adminPassword }
                    })
                );

                await Promise.all(promises);
                showAlert(`${selectedCoupons.length} coupons deleted successfully`, 'success');
                setSelectedCoupons([]);
                setMultiSelectMode(false);
                fetchCoupons();
            } catch (error) {
                console.error('Error deleting multiple coupons:', error);
                showAlert('Failed to delete some or all coupons', 'error');
            } finally {
                setLoading(false);
            }
        }
    };

    // Toggle coupon active status
    const handleToggleStatus = async (id, currentStatus) => {
        try {
            setLoading(true);
            await axios.put(`${API}/coupon/toggle-status/${id}`, {
                adminPassword
            });
            showAlert(`Coupon ${currentStatus ? 'deactivated' : 'activated'} successfully`, 'success');
            fetchCoupons();
        } catch (error) {
            console.error('Error toggling coupon status:', error);
            showAlert('Failed to update coupon status', 'error');
        } finally {
            setLoading(false);
        }
    };

    // Handle refresh
    const handleRefresh = () => {
        setRefreshing(true);
        fetchCoupons();
    };

    // Show alert
    const showAlert = (message, severity) => {
        setAlertInfo({
            open: true,
            message,
            severity
        });
    };

    // Close alert
    const handleCloseAlert = () => {
        setAlertInfo({ ...alertInfo, open: false });
    };

    // Format date
    const formatDate = (dateString) => {
        if (!dateString) return 'No expiry';
        return new Date(dateString).toLocaleDateString();
    };

    // Toggle multi-select mode
    const toggleMultiSelectMode = () => {
        setMultiSelectMode(!multiSelectMode);
        setSelectedCoupons([]);
    };

    // Handle coupon selection
    const handleSelectCoupon = (id) => {
        if (selectedCoupons.includes(id)) {
            setSelectedCoupons(selectedCoupons.filter(couponId => couponId !== id));
        } else {
            setSelectedCoupons([...selectedCoupons, id]);
        }
    };

    // Handle select all coupons
    const handleSelectAllCoupons = (event) => {
        if (event.target.checked) {
            setSelectedCoupons(coupons.map(coupon => coupon._id));
        } else {
            setSelectedCoupons([]);
        }
    };

    return (
        <Box sx={{ p: 3, maxWidth: 1200, mx: 'auto', overflow: "hidden" }}>
            {/* Authentication Dialog */}
            <Dialog open={openAuthDialog} onClose={() => { }}>
                <DialogTitle>Admin Authentication</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Admin Password"
                        type="password"
                        fullWidth
                        variant="outlined"
                        value={adminPassword}
                        onChange={(e) => setAdminPassword(e.target.value)}
                        onKeyPress={(e) => e.key === 'Enter' && handleAuthenticate()}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <LockIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleAuthenticate} variant="contained" color="primary">
                        Authenticate
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Coupon Management Header */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                <Typography variant="h4" component="h1">
                    Coupon Management
                </Typography>
                <Box>
                    <Button
                        variant="outlined"
                        startIcon={<RefreshIcon />}
                        onClick={handleRefresh}
                        disabled={refreshing}
                        sx={{ mr: 2 }}
                    >
                        Refresh
                    </Button>
                    {coupons.length >= 5 && (
                        <Button
                            variant="outlined"
                            color={multiSelectMode ? "error" : "primary"}
                            onClick={toggleMultiSelectMode}
                            sx={{ mr: 2 }}
                        >
                            {multiSelectMode ? "Cancel Multiple Select" : "Multiple Select"}
                        </Button>
                    )}
                    {multiSelectMode && selectedCoupons.length > 0 && (
                        <Button
                            variant="contained"
                            color="error"
                            startIcon={<DeleteSweepIcon />}
                            onClick={handleDeleteMultipleCoupons}
                            sx={{ mr: 2 }}
                        >
                            Delete Selected ({selectedCoupons.length})
                        </Button>
                    )}
                    <Button
                        variant="contained"
                        startIcon={<AddIcon />}
                        onClick={handleOpenCreateDialog}
                    >
                        Create Coupon
                    </Button>
                </Box>
            </Box>

            {/* Coupons Table */}
            <TableContainer component={Paper} elevation={3}>
                <Table>
                    <TableHead sx={{ backgroundColor: '#f5f5f5' }}>
                        <TableRow>
                            {multiSelectMode && (
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        indeterminate={selectedCoupons.length > 0 && selectedCoupons.length < coupons.length}
                                        checked={coupons.length > 0 && selectedCoupons.length === coupons.length}
                                        onChange={handleSelectAllCoupons}
                                    />
                                </TableCell>
                            )}
                            <TableCell>Code</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Discount</TableCell>
                            <TableCell>Usage</TableCell>
                            <TableCell>Expiry</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading && !refreshing ? (
                            <TableRow>
                                <TableCell colSpan={multiSelectMode ? 8 : 7} align="center">
                                    <Typography variant="body1" sx={{ py: 3 }}>Loading coupons...</Typography>
                                </TableCell>
                            </TableRow>
                        ) : coupons.length === 0 ? (
                            <TableRow>
                                <TableCell colSpan={multiSelectMode ? 8 : 7} align="center">
                                    <Typography variant="body1" sx={{ py: 3 }}>No coupons found</Typography>
                                </TableCell>
                            </TableRow>
                        ) : (
                            coupons.map((coupon) => (
                                <TableRow key={coupon._id} sx={{
                                    '&:hover': { backgroundColor: '#f9f9f9' },
                                    backgroundColor: !coupon.isActive ? '#f5f5f5' :
                                        selectedCoupons.includes(coupon._id) ? '#e3f2fd' : 'inherit'
                                }}>
                                    {multiSelectMode && (
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={selectedCoupons.includes(coupon._id)}
                                                onChange={() => handleSelectCoupon(coupon._id)}
                                            />
                                        </TableCell>
                                    )}
                                    <TableCell>
                                        <Typography variant="body1" fontWeight="bold">
                                            {coupon.code}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Chip
                                            label={coupon.type === 'single-use' ? 'Single Use' : 'Multi Use'}
                                            color={coupon.type === 'single-use' ? 'primary' : 'secondary'}
                                            size="small"
                                        />
                                    </TableCell>
                                    <TableCell>{coupon.discountPercentage}%</TableCell>
                                    <TableCell>
                                        {coupon.currentUsageCount} / {coupon.maxUsageLimit || '∞'}
                                    </TableCell>
                                    <TableCell>{formatDate(coupon.expiryDate)}</TableCell>
                                    <TableCell>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={coupon.isActive}
                                                    onChange={() => handleToggleStatus(coupon._id, coupon.isActive)}
                                                    color="primary"
                                                />
                                            }
                                            label={coupon.isActive ? 'Active' : 'Inactive'}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <IconButton
                                            color="primary"
                                            onClick={() => handleOpenEditDialog(coupon)}
                                            size="small"
                                            sx={{ mr: 1 }}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton
                                            color="error"
                                            onClick={() => handleDeleteCoupon(coupon._id)}
                                            size="small"
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Create/Edit Coupon Dialog */}
            <Dialog open={openCouponDialog} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
                <DialogTitle>
                    {dialogMode === 'create' ? 'Create New Coupon' : 'Edit Coupon'}
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ mt: 2 }}>
                        <TextField
                            name="code"
                            label="Coupon Code"
                            fullWidth
                            variant="outlined"
                            value={formData.code}
                            onChange={handleInputChange}
                            margin="normal"
                            required
                            helperText="Coupon code should be unique"
                        />

                        <FormControl fullWidth margin="normal">
                            <InputLabel>Coupon Type</InputLabel>
                            <Select
                                name="type"
                                value={formData.type}
                                onChange={handleInputChange}
                                label="Coupon Type"
                            >
                                <MenuItem value="single-use">Single Use</MenuItem>
                                <MenuItem value="multi-use">Multi Use</MenuItem>
                            </Select>
                        </FormControl>

                        <TextField
                            name="discountPercentage"
                            label="Discount Percentage"
                            type="number"
                            fullWidth
                            variant="outlined"
                            value={formData.discountPercentage}
                            onChange={handleInputChange}
                            margin="normal"
                            required
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                            inputProps={{
                                min: 1,
                                max: 100
                            }}
                        />

                        <TextField
                            name="maxUsageLimit"
                            label="Maximum Usage Limit"
                            type="number"
                            fullWidth
                            variant="outlined"
                            value={formData.maxUsageLimit}
                            onChange={handleInputChange}
                            margin="normal"
                            required={formData.type === 'multi-use'}
                            disabled={formData.type === 'single-use'}
                            inputProps={{
                                min: 1
                            }}
                            helperText={formData.type === 'single-use' ? 'Single use coupons have a usage limit of 1' : ''}
                        />

                        <TextField
                            name="expiryDate"
                            label="Expiry Date (Optional)"
                            type="date"
                            fullWidth
                            variant="outlined"
                            value={formData.expiryDate}
                            onChange={handleInputChange}
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />

                        <FormControlLabel
                            control={
                                <Switch
                                    name="isActive"
                                    checked={formData.isActive}
                                    onChange={handleInputChange}
                                    color="primary"
                                />
                            }
                            label="Active"
                            sx={{ mt: 2 }}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="inherit">
                        Cancel
                    </Button>
                    <LoadingButton
                        onClick={handleSubmitCoupon}
                        loading={loading}
                        variant="contained"
                        color="primary"
                    >
                        {dialogMode === 'create' ? 'Create Coupon' : 'Update Coupon'}
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            {/* Alert Snackbar */}
            <Snackbar
                open={alertInfo.open}
                autoHideDuration={6000}
                onClose={handleCloseAlert}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert
                    onClose={handleCloseAlert}
                    severity={alertInfo.severity}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {alertInfo.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default CouponManagement;