import { Link } from 'react-router-dom';
import { FaShoppingCart,FaClipboardList} from "react-icons/fa";
export const NoPlanCard = ({ animate }) => {
  return (
    <div 
      className={`bg-white rounded-2xl p-4 md:p-6 shadow-md w-full transition-all duration-700 ease-in-out transform ${animate ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-8'}`}
    >
      <div className="flex flex-col items-center justify-center gap-4 py-2">
        <div className="bg-blue-100 w-16 h-16 flex items-center justify-center rounded-full">
          <FaShoppingCart className="text-blue-500 text-3xl" />
        </div>
        <h3 className="text-xl font-medium my-0 text-gray-800 text-center">No Plans Yet</h3>
        <p className="text-gray-600 text-center mb-2">Purchase a plan to start your learning journey</p>
        <Link 
          to="/purchase" 
          className="bg-blue-500 text-white font-medium px-6 py-2 rounded-md text-center hover:bg-blue-600 transition-colors w-full md:w-2/3"
        >
          Buy Plans
        </Link>
      </div>
    </div>
  );
};

export const NoAttemptsCard = ({ animate }) => {
    return (
      <div 
        className={`bg-white rounded-2xl p-4 md:p-6 shadow-md w-full transition-all duration-700 ease-in-out transform ${animate ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-8'}`}
      >
        <div className="flex flex-col items-center justify-center gap-4 py-2">
          <div className="bg-blue-100 w-16 h-16 flex items-center justify-center rounded-full">
            <FaClipboardList className="text-blue-500 text-3xl" />
          </div>
          <h3 className="text-xl my-0 font-medium text-gray-800 text-center">Start Practice</h3>
          <p className="text-gray-600 text-center mb-2">Take tests to see your performance here</p>
          <Link 
            to="/" 
            className="bg-blue-500 text-white font-medium px-6 py-2 rounded-md text-center hover:bg-blue-600 transition-colors w-full md:w-2/3"
          >
            Attempt Tests
          </Link>
        </div>
      </div>
    );
  };