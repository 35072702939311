import React, { useState } from 'react';
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from '../Footer';
import Navbar from '../Navbar';
import { Packages } from './PackagesData';
import PackFaq from "./PackFaq";
import PriceTables from './PriceCard';
import { PriceCardsContainer } from './PriceCardsContainer';

const Tabs = ({ packages, setActiveTab, activeTab, setBgColor, bgColor }) => {
  const changeBgColor = (color) => {
    setBgColor(color);
  }

  return (
    <div className=" border max-sm:fixed max-sm:w-[95%]  bottom-2  z-50 flex justify-between  bg-white rounded-lg shadow-2xl ">
      {packages.map((packageItem) => (
        <div
          key={packageItem._id}
          onClick={() => { setActiveTab(packageItem._id); changeBgColor(packageItem.bgColor); }}
          className={`bg-white font-medium max-sm:text-[3vw] shadow-2xl cursor-pointer rounded p-2 md:p-5 m-1 ${activeTab === packageItem._id ? 'active' : ''}`}
          style={{ backgroundColor: activeTab === packageItem._id ? bgColor : '' }}
        >
          {packageItem.nameOfPlan}
        </div>
      ))}
    </div>
  );
};

const PacksPage = () => {
  const location = useLocation();
  const pack = location.state?.pack;
  const initialActiveTabId = pack
    ? Packages.find((packageItem) => packageItem.nameOfPlan === pack)._id
    : Packages.find((packageItem) => packageItem.nameOfPlan === "SOLO PACK")
      ._id;
  const [activeTab, setActiveTab] = useState(initialActiveTabId);
  const [bgColor, setBgColor] = useState(Packages.find(packageItem => packageItem._id === initialActiveTabId).bgColor);
  const handleGetStarted = (packageName) => {
    const packageItem = Packages.find(item => item.nameOfPlan === packageName);
    setActiveTab(packageItem._id);
    setBgColor(packageItem.bgColor);
    window.scrollTo({
      top: 100,
      behavior: 'smooth'
    });
  };

  return (
    <div className="overflow-hidden max-w-full mx-auto  bg-[#c4e9f0]" style={{ background: `linear-gradient(to bottom, ${bgColor}, white 30%,  white)`, transition: "background-color 0.3s ease" }} >
      <Navbar />
      <div className="flex mt-10 flex-col justify-center items-center ">
        <Tabs packages={Packages} setActiveTab={setActiveTab} activeTab={activeTab} setBgColor={setBgColor} bgColor={bgColor} />
        <PriceCardsContainer packages={Packages.filter((packageItem) => packageItem._id === activeTab)} />
        <div style={{ background: `linear-gradient(to bottom, ${bgColor},  white)`, transition: "background-color 0.3s ease", width: "100%" }}>
          <PriceTables handleGetStarted={handleGetStarted} />
        </div>
        <div style={{ background: `linear-gradient(to bottom, #ededed,  white)`, transition: "background-color 0.3s ease", width: "100%" }}>
          <PackFaq />
        </div>
        <Footer />
      </div>
      <ToastContainer />
    </div>

  );
};


export default PacksPage