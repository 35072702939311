import { Button } from "primereact/button";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/fluent-light/theme.css";
import { TieredMenu } from "primereact/tieredmenu";
import React, { useRef } from "react";
import "./notifications.css";
import SubMenu from "./SubMenu";

export default function Notification({ isMobile }) {
  const menu = useRef(null);

  const menuItems = [
    {
      label: "CUET",
      items: [
        {
          template: () => (
            <SubMenu
              examName="CUET"
              logoSrc={require("../../assets/notifications-logo/nta.webp")}
              logoWidth="w-12"
              logoHeight="h-12"
              applyStatus="over"
              applyLink="https://cuet.nta.nic.in/"
              officialLink="https://cuet.nta.nic.in/"
              calendarDate="Closed"
            />
          ),
        },
      ],
    },
    {
      label: "ENGINEERING",
      items: [
        {
          template: () => (
            <SubMenu
              examName="JEE Mains"
              logoSrc={require("../../assets/notifications-logo/nta.webp")}
              logoWidth="w-12"
              logoHeight="h-12"
              applyStatus="over"
              applyLink="#"
              officialLink="https://jeemain.nta.nic.in/"
              calendarDate="Closed"
            />
          ),
        },
        {
          template: () => (
            <SubMenu
              examName="BITSAT"
              logoSrc={require("../../assets/notifications-logo/bits.png")}
              logoWidth="w-20"
              logoHeight="h-12"
              applyStatus="ongoing"
              applyLink="https://www.bitsadmission.com/"
              officialLink="https://www.bitsadmission.com/"
              calendarDate="18 APR "
            />
          ),
        },
        {
          template: () => (
            <SubMenu
              examName="VITEE"
              logoSrc={require("../../assets/notifications-logo/viteee.png")}
              logoWidth="w-12"
              logoHeight="h-12"
              applyStatus="ongoing"
              applyLink="https://viteee.vit.ac.in/"
              officialLink="https://viteee.vit.ac.in/"
              calendarDate="31 MAR"
            />
          ),
        },
        {
          template: () => (
            <SubMenu
              examName="SRMJEE"
              logoSrc={require("../../assets/notifications-logo/srm.png")}
              logoWidth="w-12"
              logoHeight="h-12"
              applyStatus="ongoing"
              applyLink="https://srmjeee.srmap.edu.in/"
              officialLink="https://srmjeee.srmap.edu.in/"
              calendarDate="16 APR"
            />
          ),
        },
        {
          template: () => (
            <SubMenu
              examName="COMEDK"
              logoSrc={require("../../assets/notifications-logo/comedk.jpg")}
              logoWidth="w-12"
              logoHeight="h-12"
              applyStatus="over"
              applyLink="https://www.comedk.org/"
              officialLink="https://www.comedk.org/"
              calendarDate="Closed"
            />
          ),
        },
        {
          template: () => (
            <SubMenu
              examName="NATA"
              logoSrc={require("../../assets/notifications-logo/nata.png")}
              logoWidth="w-12"
              logoHeight="h-12"
              applyStatus="ongoing"
              applyLink="https://www.nata.in/"
              officialLink="https://www.nata.in/"
              calendarDate="24 JUNE "
            />
          ),
        },
      ],
    },
    {
      label: "MEDICINE",
      items: [
        {
          template: () => (
            <SubMenu
              examName="NEET"
              logoSrc={require("../../assets/notifications-logo/nta.webp")}
              logoWidth="w-12"
              logoHeight="h-12"
              applyStatus="over"
              applyLink="https://neet.nta.nic.in/"
              officialLink="https://neet.nta.nic.in/"
              calendarDate="Closed"
            />
          ),
        },
      ],
    },
    {
      label: "LAW",
      items: [
        {
          template: () => (
            <SubMenu
              examName="CLAT"
              logoSrc={require("../../assets/notifications-logo/clat.jpg")}
              logoWidth="w-16"
              logoHeight="h-16"
              applyStatus="over"
              applyLink="#"
              officialLink="https://consortiumofnlus.ac.in/"
              calendarDate="Closed"
            />
          ),
        },
        {
          template: () => (
            <SubMenu
              examName="AILET"
              logoSrc={require("../../assets/notifications-logo/ailet.png")}
              logoWidth="w-16"
              logoHeight="h-16"
              applyStatus="over"
              applyLink="#"
              officialLink="https://nationallawuniversitydelhi.in/"
              calendarDate="Closed"
            />
          ),
        },
        {
          template: () => (
            <SubMenu
              examName="SLAT"
              logoSrc={require("../../assets/notifications-logo/slat.png")}
              logoWidth="w-16"
              logoHeight="h-16"
              applyStatus="over"
              applyLink="#"
              officialLink="https://www.slat-test.org/"
              calendarDate="Closed"
            />
          ),
        },
        {
          template: () => (
            <SubMenu
              examName="LNAT (UK)"
              logoSrc={require("../../assets/notifications-logo/lnat.jpeg")}
              logoWidth="w-20"
              logoHeight="h-16"
              applyStatus="ongoing"
              applyLink="https://lnat.ac.uk/"
              officialLink="https://lnat.ac.uk/"
              calendarDate="25 JULY"
            />
          ),
        },
        {
          template: () => (
            <SubMenu
              examName="NMIMS - LAT"
              logoSrc={require("../../assets/notifications-logo/nmims.png")}
              logoWidth="w-12"
              logoHeight="h-12"
              applyStatus="over"
              applyLink="https://nlat.nmims.edu/"
              officialLink="https://www.nmims.edu/"
              calendarDate="Closed"
            />
          ),
        },
        {
          template: () => (
            <SubMenu
              examName="IP University"
              logoSrc={require("../../assets/notifications-logo/ipu.jpeg")}
              logoWidth="w-16"
              logoHeight="h-16"
              applyStatus="ongoing"
              applyLink="http://www.ipu.ac.in/admission2024main.php"
              officialLink="http://ipu.ac.in/uslls/"
              calendarDate="31 MAR"
            />
          ),
        },
      ],
    },
    {
      label: "MANAGEMENT",
      items: [
        {
          template: () => (
            <SubMenu
              examName="CUET-Delhi University"
              logoSrc={require("../../assets/notifications-logo/nta.webp")}
              logoWidth="w-12"
              logoHeight="h-12"
              applyStatus="over"
              applyLink="https://exams.nta.ac.in/CUET-UG/"
              officialLink="https://exams.nta.ac.in/CUET-UG/"
              calendarDate=" Closed"
            />
          ),
        },
        {
          template: () => (
            <SubMenu
              examName="IPMAT-Indore"
              logoSrc={require("../../assets/notifications-logo/ipmat-indore.png")}
              logoWidth="w-16"
              logoHeight="h-16"
              applyStatus="over"
              applyLink="https://iimidr.ac.in/"
              officialLink="https://iimidr.ac.in/"
              calendarDate="Closed"
            />
          ),
        },
        {
          template: () => (
            <SubMenu
              examName="IIM Gaya"
              logoSrc={require("../../assets/notifications-logo/IIM gaya.png")}
              logoWidth="w-16"
              logoHeight="h-16"
              applyStatus="ongoing"
              applyLink="https://iimbg.ac.in/"
              officialLink="https://iimbg.ac.in/"
              calendarDate="31 MAR"
            />
          ),
        },
        {
          template: () => (
            <SubMenu
              examName="IIM Ranchi "
              logoSrc={require("../../assets/notifications-logo/ranchi.png")}
              logoWidth="w-16"
              logoHeight="h-16"
              applyStatus="over"
              applyLink="https://app.iimranchi.ac.in/admission/dp/register"
              officialLink="https://iimranchi.ac.in/"
              calendarDate="Closed"
            />
          ),
        },
        {
          template: () => (
            <SubMenu
              examName="IIM Jammu"
              logoSrc={require("../../assets/notifications-logo/jammu.png")}
              logoWidth="w-16"
              logoHeight="h-16"
              applyStatus="ongoing"
              applyLink="https://emba-application.iimj.ac.in/register"
              officialLink="https://www.iimj.ac.in/programs/post-graduate-program-pgp/admission-policy"
              calendarDate="31 MAR"
            />
          ),
        },
        {
          template: () => (
            <SubMenu
              examName="IPMAT-Rohtak"
              logoSrc={require("../../assets/notifications-logo/ipmat-rohtak.jpeg")}
              logoWidth="w-14"
              logoHeight="h-14"
              applyStatus="ongoing"
              applyLink="https://www.iimrohtak.ac.in/ipm-admission.php"
              officialLink="https://www.iimrohtak.ac.in/ipm-admission.php"
              calendarDate="11 APR"
            />
          ),
        },
        // {
        //   template: () => (
        //     <SubMenu
        //       examName="JAT"
        //       logoSrc={require("../../assets/notifications-logo/nta.webp")}
        //       logoWidth="w-12"
        //       logoHeight="h-12"
        //       applyStatus="ongoing"
        //       applyLink="https://exams.nta.ac.in/CUET-UG/"
        //       officialLink="https://exams.nta.ac.in/CUET-UG/"
        //       calendarDate="22 MAR "
        //     />
        //   ),
        // },
        {
          template: () => (
            <SubMenu
              examName="St. Xaviers, Mumbai"
              logoSrc={require("../../assets/notifications-logo/xaviers.png")}
              logoWidth="w-12"
              logoHeight="h-12"
              applyStatus="over"
              applyLink="https://xaviers.ac/admissions/"
              officialLink="https://xaviers.ac/"
              calendarDate="Closed"
            />
          ),
        },
        // {
        //   template: () => (
        //     <SubMenu
        //       examName="NMIMS - CET"
        //       logoSrc={require("../../assets/notifications-logo/nmims.png")}
        //       logoWidth="w-12"
        //       logoHeight="h-12"
        //       applyStatus="ongoing"
        //       applyLink="https://ncet.nmims.edu/"
        //       officialLink="https://www.nmims.edu/"
        //       calendarDate="26 MAR"
        //     />
        //   ),
        // },
        {
          template: () => (
            <SubMenu
              examName="NMIMS - NPAT"
              logoSrc={require("../../assets/notifications-logo/nmims.png")}
              logoWidth="w-12"
              logoHeight="h-12"
              applyStatus="ongoing"
              applyLink="https://npat.nmims.edu/"
              officialLink="https://www.nmims.edu/"
              calendarDate="26 MAY"
            />
          ),
        },
        {
          template: () => (
            <SubMenu
              examName="Symbiosis"
              logoSrc={require("../../assets/notifications-logo/symbiosis.png")}
              logoWidth="w-12"
              logoHeight="h-12"
              applyStatus="ongoing"
              applyLink="https://set2025.ishinfosys.com/SET20Y25/Register/Index.aspx"
              officialLink="https://www.set-test.org/"
              calendarDate="12 APR"
            />
          ),
        },
        {
          template: () => (
            <SubMenu
              examName="Christ University"
              logoSrc={require("../../assets/notifications-logo/christ1.jpg")}
              logoWidth="w-20"
              logoHeight="h-14"
              applyStatus="ongoing"
              applyLink="https://espro.christuniversity.in/Application/"
              officialLink="https://christuniversity.in/"
              calendarDate="29 APR"
            />
          ),
        },
        {
          template: () => (
            <SubMenu
              examName="IP University"
              logoSrc={require("../../assets/notifications-logo/ipu.jpeg")}
              logoWidth="w-14"
              logoHeight="h-14"
              applyStatus="ongoing"
              applyLink="http://www.ipu.ac.in/admission2024main.php"
              officialLink="http://www.ipu.ac.in/"
              calendarDate="31 MAR"
            />
          ),
        },
      ],
    },
    {
      label: "FASHION/DESIGN",
      items: [
        {
          template: () => (
            <SubMenu
              examName="NID"
              logoSrc={require("../../assets/notifications-logo/nid.png")}
              logoWidth="w-12"
              logoHeight="h-12"
              applyStatus="over"
              applyLink="#"
              officialLink="https://admissions.nid.edu/NIDA2025/Default.aspx"
              calendarDate="CLOSED"
            />
          ),
        },
        {
          template: () => (
            <SubMenu
              examName="NIFT"
              logoSrc={require("../../assets/notifications-logo/nift.png")}
              logoWidth="w-12"
              logoHeight="h-12"
              applyStatus="over"
              applyLink="https://www.nift.ac.in/admission"
              officialLink="https://www.nift.ac.in/"
              calendarDate="CLOSED"
            />
          ),
        },
        {
          template: () => (
            <SubMenu
              examName="UCEED"
              logoSrc={require("../../assets/notifications-logo/uceed1.png")}
              logoWidth="w-16"
              logoHeight="h-12"
              applyStatus="over"
              applyLink="#"
              officialLink="https://www.uceed.iitb.ac.in/2025/index.html"
              calendarDate="CLOSED"
            />
          ),
        },
        {
          template: () => (
            <SubMenu
              examName="Symbiosis"
              logoSrc={require("../../assets/notifications-logo/symbiosis.png")}
              logoWidth="w-12"
              logoHeight="h-12"
              applyStatus="ongoing"
              applyLink="https://siu.ishinfo.com/BDESY25/Register/Index.aspx"
              officialLink="https://www.sid.edu.in/"
              calendarDate="12 APR"
            />
          ),
        },
        {
          template: () => (
            <SubMenu
              examName="Pearl University"
              logoSrc={require("../../assets/notifications-logo/pearl.jpeg")}
              logoWidth="w-16"
              logoHeight="h-12"
              applyStatus="ongoing"
              applyLink="https://admissions.pearlacademy.com/studentportal/sturegistration.aspx"
              officialLink="https://www.pearlacademy.com/"
              calendarDate="15 APR"
            />
          ),
        },
      ],
    },
    {
      label: "MASS COMMUNICATION / JOURNALISM",
      items: [
        {
          template: () => (
            <SubMenu
              examName="CUET-Delhi University"
              logoSrc={require("../../assets/notifications-logo/nta.webp")}
              logoWidth="w-12"
              logoHeight="h-12"
              applyStatus="over"
              applyLink="https://exams.nta.ac.in/CUET-UG/"
              officialLink="https://exams.nta.ac.in/CUET-UG/"
              calendarDate="Closed"
            />
          ),
        },
        {
          template: () => (
            <SubMenu
              examName="IP University"
              logoSrc={require("../../assets/notifications-logo/ipu.jpeg")}
              logoWidth="w-12"
              logoHeight="h-12"
              applyStatus="ongoing"
              applyLink="https://ipu.admissions.nic.in/"
              officialLink="https://ipu.admissions.nic.in/"
              calendarDate="31 MAR"
            />
          ),
        },
        {
          template: () => (
            <SubMenu
              examName="Symbiosis"
              logoSrc={require("../../assets/notifications-logo/symbiosis.png")}
              logoWidth="w-12"
              logoHeight="h-12"
              applyStatus="ongoing"
              applyLink="https://set2025.ishinfosys.com/SET20Y25/Register/Index.aspx"
              officialLink="https://www.set-test.org/"
              calendarDate="12 APR"
            />
          ),
        },
        {
          template: () => (
            <SubMenu
              examName="St. Xaviers"
              logoSrc={require("../../assets/notifications-logo/xaviers.png")}
              logoWidth="w-12"
              logoHeight="h-12"
              applyStatus="ongoing"
              applyLink="https://www.xaviercomm.org/admissions/admissions.htm"
              officialLink="https://www.xaviercomm.org/"
              calendarDate="31 MAR"
            />
          ),
        },
        {
          template: () => (
            <SubMenu
              examName="Christ University"
              logoSrc={require("../../assets/notifications-logo/christ1.jpg")}
              logoWidth="w-16"
              logoHeight="h-12"
              applyStatus="ongoing"
              applyLink="https://christuniversity.in/admission-ug"
              officialLink="https://christuniversity.in/humanities-and-social-sciences/media-studies/ma-(media-and-communication-studies)"
              calendarDate="29 APR"
            />
          ),
        },
      ],
    },
    {
      label: "HOSPITALITY",
      items: [
        {
          template: () => (
            <SubMenu
              examName="JEE-NCHM"
              logoSrc={require("../../assets/notifications-logo/nta.webp")}
              logoWidth="w-12"
              logoHeight="h-12"
              applyStatus="over"
              applyLink="https://nchmjee.nta.nic.in/"
              officialLink="https://nchmjee.nta.nic.in/"
              calendarDate="Closed"
            />
          ),
        },
        {
          template: () => (
            <SubMenu
              examName="IIHM"
              logoSrc={require("../../assets/notifications-logo/iihm.jpeg")}
              logoWidth="w-12"
              logoHeight="h-12"
              applyStatus="over"
              applyLink="https://www.iihm.ac.in/"
              officialLink="https://www.iihm.ac.in/"
              calendarDate="Closed"
            />
          ),
        },
        {
          template: () => (
            <SubMenu
              examName="IP University"
              logoSrc={require("../../assets/notifications-logo/ipu.jpeg")}
              logoWidth="w-12"
              logoHeight="h-12"
              applyStatus="ongoing"
              applyLink="https://ipu.admissions.nic.in/"
              officialLink="https://ipu.admissions.nic.in/"
              calendarDate="31 MAR"
            />
          ),
        },
        {
          template: () => (
            <SubMenu
              examName="Oberoi STEP"
              logoSrc={require("../../assets/notifications-logo/oberoi.webp")}
              logoWidth="w-12"
              logoHeight="h-12"
              applyStatus="upcoming"
              applyLink="https://www.oberoigroup.com/learning-programmes/step"
              officialLink="https://www.oberoigroup.com/learning-programmes/step"
              calendarDate="APR,2025"
            />
          ),
        },
        {
          template: () => (
            <SubMenu
              examName="Welcom Group"
              logoSrc={require("../../assets/notifications-logo/welcom.jpeg")}
              logoWidth="w-12"
              logoHeight="h-12"
              applyStatus="over"
              applyLink="https://www.manipal.edu/wgsha.html"
              officialLink="https://www.manipal.edu/wgsha.html"
              calendarDate="Closed"
            />
          ),
        },
        {
          template: () => (
            <SubMenu
              examName="Christ University"
              logoSrc={require("../../assets/notifications-logo/christ1.jpg")}
              logoWidth="w-16"
              logoHeight="h-12"
              applyStatus="ongoing"
              applyLink="https://christuniversity.in/commerce-and-management/hotel-management/bachelor-of-hotel-management-(bhm)"
              officialLink="https://christuniversity.in/commerce-and-management/hotel-management/bachelor-of-hotel-management-(bhm)"
              calendarDate="29 APR"
            />
          ),
        },
      ],
    },
    {
      label: "OTHERS",
      items: [
        {
          template: () => (
            <SubMenu
              examName="ASHOKA- Liberal Arts"
              logoSrc={require("../../assets/notifications-logo/ashoka.png")}
              logoWidth="w-12"
              logoHeight="h-12"
              applyStatus="ongoing"
              applyLink="https://application.ashoka.edu.in/"
              officialLink="https://www.ashoka.edu.in/"
              calendarDate="25 APR"
            />
          ),
        },
        {
          template: () => (
            <SubMenu
              examName="BFA - DU (CUET)"
              logoSrc={require("../../assets/notifications-logo/nta.webp")}
              logoWidth="w-12"
              logoHeight="h-12"
              applyStatus="over"
              applyLink="https://exams.nta.ac.in/CUET-UG/"
              officialLink="https://exams.nta.ac.in/CUET-UG/"
              calendarDate="Closed"
            />
          ),
        },
        {
          template: () => (
            <SubMenu
              examName="BFA- MSU, Vadodara"
              logoSrc={require("../../assets/notifications-logo/bfa-ms.jpeg")}
              logoWidth="w-12"
              logoHeight="h-12"
              applyStatus="ongoing"
              applyLink="https://www.msubaroda.ac.in/"
              officialLink="https://www.msubaroda.ac.in/"
              calendarDate="30 APR"
            />
          ),
        },
        {
          template: () => (
            <SubMenu
              examName="FLAME - Liberal Arts"
              logoSrc={require("../../assets/notifications-logo/flame.png")}
              logoWidth="w-16"
              logoHeight="h-16"
              applyStatus="over"
              applyLink="https://application.flame.edu.in/application/LoginPage_1"
              officialLink="https://www.flame.edu.in/"
              calendarDate="Closed"
            />
          ),
        },
      ],
    },
  ];

  return (
    <div className="relative">
      <Button
        label={!isMobile ? "Exam Notifications" : null}
        icon="pi pi-bell"
        onClick={(e) => menu.current.toggle(e)}
        className={`animated-bell rounded-full ${
          isMobile ? "p-button-icon-only" : ""
        }`}
      />

      <TieredMenu
        model={menuItems}
        popup
        ref={menu}
        breakpoint="1024px"
        className="p-tieredmenu p-0 menu-root"
      />
    </div>
  );
}
