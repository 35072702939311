import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { CgSpinner } from "react-icons/cg";
import { FaCheck, FaQuestionCircle } from 'react-icons/fa';
import { MdDelete } from "react-icons/md";
import { RxCross1 } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import Select from 'react-select';
import { toast } from "react-toastify";
import { API } from '../../utils/constants';
import { useAuth } from "../../utils/context";

const PriceCard = ({
  _id,
  nameOfPlan,
  bgColor,
  amount,
  description,
  benefits
}) => {
  const [auth, setAuth] = useAuth();
  const navigate = useNavigate();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [showOptions, setShowOptions] = useState(false);
  const [loading, setLoading] = useState(false);
  const [paymentProcessing, setPaymentProcessing] = useState(false);

  // Coupon States
  const [couponCode, setCouponCode] = useState('');
  const [couponError, setCouponError] = useState('');
  const [couponDetails, setCouponDetails] = useState(null);
  const [originalAmount, setOriginalAmount] = useState(amount);

  const couponInputRef = useRef(null); // Ref for coupon input

  const closeModal = () => {
    setLoading(false);
    setShowOptions(false);
    setSelectedOptions([]);
    setCouponCode('');
    setCouponDetails(null);
    setCouponError('');
  }

  useEffect(() => {
    if (showOptions && couponInputRef.current) {
      couponInputRef.current.focus(); // Focus on coupon input when popup opens
    }
  }, [showOptions]);


  let options;
  if (nameOfPlan === 'SOLO PACK' || nameOfPlan === 'DUO PACK') {
    options = [
      { value: "General English", label: "General English" },
      { value: "General Test", label: "General Test" },
      { value: "Mathematics", label: "Mathematics" },
      { value: "Physics", label: "Physics" },
      { value: "Chemistry", label: "Chemistry" },
      { value: "Biology", label: "Biology" },
      { value: "Accountancy", label: "Accountancy" },
      { value: "Economics", label: "Economics" },
      { value: "Business Studies", label: "Business Studies" },
      { value: "History", label: "History" },
      { value: "Political Science", label: "Political Science" },
      { value: "Geography", label: "Geography" },
      { value: "Psychology", label: "Psychology" },
      { value: "Sociology", label: "Sociology" },
      { value: "Physical Education", label: "Physical Education" },
    ];
  } else {
    options = [
      { value: "Mathematics", label: "Mathematics" },
      { value: "Physics", label: "Physics" },
      { value: "Chemistry", label: "Chemistry" },
      { value: "Biology", label: "Biology" },
      { value: "Accountancy", label: "Accountancy" },
      { value: "Economics", label: "Economics" },
      { value: "Business Studies", label: "Business Studies" },
      { value: "History", label: "History" },
      { value: "Political Science", label: "Political Science" },
      { value: "Geography", label: "Geography" },
      { value: "Psychology", label: "Psychology" },
      { value: "Sociology", label: "Sociology" },
      { value: "Physical Education", label: "Physical Education" },
    ];
  }
  const handleSelectChange = (selected) => {
    if ((nameOfPlan === 'SOLO PACK') && selected.length > 1) {
      setErrorMessage("Select only one.");
    } else if ((nameOfPlan === 'DUO PACK') && selected.length > 2) {
      setErrorMessage("Select only two.");
    }
    else if ((nameOfPlan === 'MEGA PACK') && selected.length > 3) {
      setErrorMessage("Select only three subjects.");
    }
    else if ((nameOfPlan === 'JUMBO PACK') && selected.length > 4) {
      setErrorMessage("Select only four subjects.");
    } else {
      setSelectedOptions(selected);
      setErrorMessage('');
    }
  };

  const validateCoupon = async () => {
    if (!couponCode) {
      setCouponError('Please enter a coupon code');
      return;
    }

    try {
      const response = await axios.post(`${API}/coupon/validate-coupon`, {
        packageId: _id,
        couponCode,
        userId: auth.user?._id,
      });

      if (response.data.success) {
        setCouponDetails(response.data.coupon);
        setCouponError('');
        setOriginalAmount(amount - response.data.coupon.discountAmount);
      }
    } catch (error) {
      setCouponError(
        error.response?.data?.message || 'Invalid coupon code.'
      );
      setCouponDetails(null);
      setOriginalAmount(amount); // Reset to original amount if coupon fails
    }
  };

  const removeCoupon = () => {
    setCouponDetails(null);
    setCouponCode('');
    setCouponError('');
    setOriginalAmount(amount);
  };

  const renderIcon = (benefit) => {
    switch (benefit) {
      case 'Both General English & General Test':
      case 'Unlimited Attempts ':
      case 'Full Access to Prep Modules':
      case 'Over 2000 questions per subject':
      case '12 Practice Tests':
      case '12 Mock Tests':
      case '12 Practice Tests for each Subject':
      case '12 Mock Tests for each Subject':
      case 'Full Access to Practice Tests':
        return <FaCheck className='text-green-400' />;
      case '/ Any one Domain Subject':
      case '/ Any two Domain Subjects':
        return <span>  </span>;
      default:
        return <FaQuestionCircle className='text-blue-400' />;
    }
  };
  const initPayment = async () => {
    if ((nameOfPlan === 'SOLO PACK') && selectedOptions.length < 1) {
      toast.error("Select atleast 1 option.")
      return;
    } else if ((nameOfPlan === 'DUO PACK') && selectedOptions.length < 2) {
      toast.error("Select atleast 2 options.")
      return;
    }
    else if ((nameOfPlan === 'MEGA PACK') && selectedOptions.length < 3) {
      toast.error("Select atleast 3 options.")
      return;
    }
    else if ((nameOfPlan === 'JUMBO PACK') && selectedOptions.length < 4) {
      toast.error("Select atleast 4 options.")
      return;
    }

    setLoading(true);
    setPaymentProcessing(true);

    try {
      // If amount is 0 (100% discount), directly verify without Razorpay
      if (originalAmount === 0) {
        try {
          const verifyResponse = await axios.put(`${API}/payment/verify`, {
            userId: auth.user?._id,
            email: auth.user?.email,
            name: auth?.user?.name,
            amount: originalAmount,
            packname: nameOfPlan,
            contact: auth?.user?.contact,
            selectedOptions: selectedOptions.map(option => option.value),
            ...(couponDetails ? { couponCode } : {})
          });

          if (verifyResponse.data.success) {
            const userResponse = await axios.get(
              `${API}/users/find/${auth.user?._id}`
            );
            const updatedUser = userResponse.data;
            const updatedAuth = {
              ...auth,
              user: updatedUser,
              password: undefined
            };
            setAuth(updatedAuth);
            localStorage.setItem("auth", JSON.stringify(updatedAuth));

            setLoading(false);
            setPaymentProcessing(false);
            toast.success("Pack bought successfully")
            setTimeout(() => {
              navigate('/courses');
            }, 2000);
          }
        } catch (error) {
          setLoading(false);
          setPaymentProcessing(false);
          toast.error(
            "Cannot process your request at the moment. Please try again later"
          )
          console.error(error);
        }
        return;
      }

      const key = await axios.get(`${API}/payment/getKey`);

      const response = await axios.post(`${API}/payment/initiate`, {
        packageId: _id,
        userId: auth.user?._id,
        selectedOptions: (() => {
          if (nameOfPlan === 'JUMBO PACK' || nameOfPlan === 'MEGA PACK') {
            selectedOptions.push(
              { value: 'General English', label: 'General English' },
              { value: 'General Test', label: 'General Test' }
            );
          }
          return selectedOptions.map(option => option.value);
        })(),
        ...(couponDetails ? { couponCode } : {})
      });

      const { data } = response;

      const options = {
        key: key.data.key,
        amount: data.amount,
        currency: data.currency,
        name: nameOfPlan,
        description: "Test Transaction",
        image: "",
        order_id: data.order_id,
        handler: async (response) => {
          try {
            const verifyResponse = await axios.put(`${API}/payment/verify`, {
              userId: auth.user?._id,
              email: auth.user?.email,
              name: auth?.user?.name,
              amount: originalAmount,
              packname: nameOfPlan,
              contact: auth?.user?.contact,
              razorpay_order_id: response.razorpay_order_id,
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_signature: response.razorpay_signature,
              packageId: _id,
              selectedOptions: selectedOptions.map(option => option.value),
              ...(couponDetails ? {
                couponCode,
                discountAmount: couponDetails.discountAmount
              } : {})
            });

            if (verifyResponse.data.success) {
              const userResponse = await axios.get(
                `${API}/users/find/${auth.user?._id}`
              );
              const updatedUser = userResponse.data;
              const updatedAuth = {
                ...auth,
                user: updatedUser,
                password: undefined
              };
              setAuth(updatedAuth);
              localStorage.setItem("auth", JSON.stringify(updatedAuth));

              setLoading(false);
              setPaymentProcessing(false);
              toast.success("Pack bought successfully")
              setTimeout(() => {
                navigate('/courses');
              }, 2000);
            }
          } catch (error) {
            setLoading(false);
            setPaymentProcessing(false);
            toast.error(
              "Cannot process your request at the moment. Please try again later"
            )
            console.error(error);
          }
        },
        theme: {
          color: "#3399cc",
        },
        modal: {
          ondismiss: function () {
            setLoading(false);
            setPaymentProcessing(false);
          }
        }
      };

      const rzp1 = new window.Razorpay(options);
      setLoading(false);

      rzp1.on('payment.failed', function () {
        setPaymentProcessing(false);
      });

      rzp1.open();
      setSelectedOptions([]);
    } catch (error) {
      setLoading(false);
      setPaymentProcessing(false);
      console.error(error);
    }
  };


  return (
    <div id='select' className="price-card flex-col-reverse lg:flex-row lg:h-[550px] my-4 j items-center flex">
      <div className="max-lg:hidden rounded-3xl max-lg:justify-center max-lg:top-[-30px] flex flex-col justify-center px-10 hover:scale-105 shadow-2xl transition-all duration-100 p-5 relative lg:left-5 z-0 bg-white h-[400px]">
        <h4 className='font-bold mb-0 text-[25px]'>Plan Benefits:
          <hr className='my-1' />
        </h4>
        <h className='font-medium text-[13px] md:text-[18px]'>
          {benefits.map((benefit, index) => (
            <p key={index} className="my-4">
              {renderIcon(benefit)}
              <span className='ml-3'>{benefit}</span>
            </p>
          ))}
        </h>
      </div>

      <div className='p-4 md:max-w-[350px] max-lg:w-full relative z-10 md:max-h-[700px] max-h-[800px] bg-white transition-all duration-100 shadow-2xl my-8 rounded-3xl backdrop-blur[40px] hover:scale-105 text-center text-black mx-3 '>
        <div className='p-2 py-3 '>
          <h2 style={{ background: bgColor }} className='text-[25px] mb-6 border-dashed border-2 border-blue-950 p-2 mx-auto text-black whitespace-nowrap rounded-3xl '>{nameOfPlan}</h2>
          <hr className='my-2' />
          <h1 className='text-10xl text-slate-700'>Rs {amount}
            <span className="text-gray-400 font-medium text-xl">/ year</span>
            <p className='ml-0 text-[12px]'>*GST Excluded</p>
          </h1>
          <hr className='my-2 ' />
          <h className='md:hidden font-medium text-[13px] md:text-[18px]'>
            {benefits.map((benefit, index) => (
              <p key={index} className="mt-4">
                {renderIcon(benefit)}
                <span className='ml-3'>{benefit}</span>
              </p>
            ))}
          </h>
          <p className='max-md:hidden text-[15px] text-slate-600 px-1 md:h-[100px] '>{description}</p>
          <div className="md:pt-8 pt-5">
            <h onClick={() => {
              if (!auth.user) {
                navigate('/login');
                return;
              }
              setShowOptions(true);
            }}>
              <p className="w-full py-4 cursor-pointer border transition-colors duration-100 hover:border-blue-800 border-[#23bd68] bg-[#23bd68] border-solid hover:bg-blue-600 md:mt-8 rounded-xl text-white">
                <span className="font-medium">
                  Get Started
                </span>
              </p>
            </h>
          </div>
        </div>
      </div>
      {showOptions && (
        <>
          <div className="popup-overlay ">
            <div
              className="rounded-3xl bg-[#ffffffee] md:w-[700px] max-w-[95vw] md:max-h-[90vh] p-4 md:p-6 backdrop-blur-[100px] shadow-2xl flex flex-col overflow-y-auto overflow-x-hidden"
              style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
            >
              <div className="flex justify-between items-center">
                <h2 style={{ background: bgColor }} className="font-semibold my-1 p-2 m-0 rounded-2xl mx-2 text-lg md:text-2xl truncate max-w-[70%]">
                  {nameOfPlan}
                </h2>
                <h3 className="mx-2 cursor-pointer text-gray-600 hover:text-gray-800 transition-colors" onClick={closeModal}>
                  <RxCross1 size={24} />
                </h3>
              </div>

              <div className="flex-1 flex flex-col min-h-0">
                {/* Price Display in Popup */}
                <div className="text-center mb-1">
                  <div className='flex gap-2 items-center justify-center'>
                    <h3 className="text-xl my-1 text-gray-700">Price:</h3>
                    <h2 className="text-4xl my-1 font-bold text-blue-700">₹{amount} <span className="text-sm font-medium text-gray-500">/ year</span></h2>
                  </div>

                  <p className="text-sm my-1 text-gray-500">*GST will be applied at checkout</p>
                </div>


                <div className='mb-2'>
                  <p className='font-medium text-lg text-gray-800 my-1 text-center mb-1'>Select Subjects</p>
                  {(nameOfPlan === 'MEGA PACK' || nameOfPlan === 'JUMBO PACK') && (
                    <p className='text-sm text-gray-600 text-center mb-1'>General English & General Test is included for free in this pack.</p>
                  )}
                  <p className='text-sm text-gray-600 text-center'>Choose
                    {nameOfPlan === 'SOLO PACK' && (<span> One </span>)}
                    {nameOfPlan === 'DUO PACK' && (<span> Two </span>)}
                    {nameOfPlan === 'MEGA PACK' && (<span> Three </span>)}
                    {nameOfPlan === 'JUMBO PACK' && (<span> Four </span>)}
                    Domain Subjects
                  </p>
                  <div className="mt-3 w-full relative">
                    <Select
                      isMulti
                      options={options}
                      value={selectedOptions}
                      onChange={handleSelectChange}
                      maxMenuHeight={150}
                      minMenuHeight={10}
                      className="rounded text-sm"
                      placeholder="Select Subjects"
                    />
                    <div className="text-red-600 text-sm mt-1">{errorMessage}</div>
                  </div>
                </div>

                {/* Coupon Section */}
                <div className="mb-2">
                  <div className="flex items-center space-x-2 mb-1">
                    <label htmlFor="couponCode" className="block text-xs md:text-sm font-medium text-gray-700">
                      Apply Coupon Code <span className="text-gray-500 text-xs">(if you have one)</span>
                    </label>
                  </div>
                  <div className="flex">
                    <input
                      ref={couponInputRef}
                      type="text"
                      id="couponCode"
                      value={couponCode}
                      onChange={(e) => setCouponCode(e.target.value)}
                      placeholder="Enter coupon code"
                      className="shadow-sm border-[1px] focus:outline-none focus:ring-0 w-full text-xs md:text-sm border-gray-300 rounded-md px-2 md:px-4 py-1 md:py-2"
                    />
                    <button
                      onClick={validateCoupon}
                      className="ml-2 bg-blue-500 text-white border-none cursor-pointer px-2 md:px-4 py-1 md:py-2 rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 font-medium text-xs md:text-sm whitespace-nowrap"
                    >
                      Apply
                    </button>
                  </div>
                  {couponError && (
                    <p className="text-red-500 mt-1 text-xs md:text-sm">{couponError}</p>
                  )}
                </div>

                {couponDetails && (
                  <div className="px-3 md:px-4 py-1 md:py-2 bg-green-50 rounded border border-green-200 text-xs md:text-sm relative">
                    <button
                      onClick={removeCoupon}
                      className="absolute top-2 bg-transparent rounded-3xl cursor-pointer shadow-lg border-none right-2 text-red-500 hover:text-red-700 "
                      title="Remove Coupon"
                    >
                      <MdDelete size={16} />
                    </button>
                    <h4 className="font-semibold text-green-700 my-1">Coupon Applied!</h4>
                    <div className="flex justify-between mb-0.5">
                      <span className="text-gray-700">Original Price:</span>
                      <span className="line-through text-gray-500">₹{amount}</span>
                    </div>
                    <div className="flex justify-between mb-0.5">
                      <span className="text-gray-700">Discount ({couponDetails.discountPercentage}%):</span>
                      <span className="text-red-600">-₹{couponDetails.discountAmount}</span>
                    </div>
                    <div className="flex justify-between font-bold text-base md:text-lg">
                      <span className="text-gray-800">Final Price:</span>
                      <span className="text-green-800">₹{originalAmount}</span>
                    </div>
                    <p className="text-gray-500 text-xs mt-0.5 text-end">GST will be applied at checkout.</p>
                  </div>
                )}
                <div className=" pt-4 flex justify-center">
                  <button
                    onClick={initPayment}
                    className={`flex cursor-pointer border-none py-2 md:py-3 px-4 md:px-6 font-medium rounded-md bg-blue-600 hover:bg-blue-700 text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 text-sm ${loading ? 'opacity-50 cursor-wait' : ''}`}
                    disabled={loading || paymentProcessing}
                  >
                    {(loading || paymentProcessing) ? (
                      <span className="flex items-center"><CgSpinner size={18} className="animate-spin mr-1 md:mr-2" /> Processing...</span>
                    ) : (
                      <span>Confirm Selection & Pay</span>
                    )}
                  </button>
                </div>
              </div>


            </div>
          </div>
        </>
      )}
    </div>
  );
};

export const PriceCardsContainer = ({ packages }) => {
  return (
    <div className="price-cards-container md:pb-6">
      {packages.map((packageItem) => (
        <PriceCard key={packageItem._id} {...packageItem} />
      ))}
    </div>
  );
};