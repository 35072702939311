import { Link } from 'react-router-dom';

const PerformanceCard = ({ performance, animate }) => {
  // Calculate progress circle properties
  const radius = 40;
  const circumference = 2 * Math.PI * radius;
  const maxScore = 100; // Changed from 500 to 100 to match the API percentage scores
  const progressPercentage = performance.score / maxScore;
  const progressOffset = circumference * (1 - progressPercentage);
  
  return (
    <div className={`bg-white rounded-2xl max-md:p-4 p-2 px-3 md:pt-3 shadow-md w-full transition-all duration-500 ease-in-out transform ${animate ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-8'}`} style={{ transitionDelay: '300ms' }}>
      <h2 className="text-gray-800 font-medium mt-0 mb-1">Overall Performance</h2>
      
      <div className="flex justify-center">
        <div className="relative w-24 h-24 md:w-32 md:h-32">
          {/* Background Circle */}
          <svg className="w-full h-full -rotate-90">
            <circle 
              cx="50%" 
              cy="50%" 
              r={radius}
              fill="none" 
              stroke="#f3f4f6" 
              strokeWidth="10"
            />
            {/* Progress Circle */}
            <circle 
              cx="50%" 
              cy="50%" 
              r={radius}
              fill="none" 
              stroke="#4361ee" 
              strokeWidth="10"
              strokeDasharray={circumference}
              strokeDashoffset={animate ? progressOffset : circumference}
              className="transition-all duration-1500 ease-out"
              style={{ transitionDelay: '500ms' }}
            />
          </svg>
          
          {/* Center Content */}
          <div className="absolute inset-0 flex flex-col items-center justify-center">
            <div className={`text-xl md:text-2xl font-bold transition-all duration-1000 ${animate ? 'opacity-100' : 'opacity-0'}`} style={{ transitionDelay: '800ms' }}>
            {performance.score ? performance.score.toFixed(2)  : "N/A"}
            </div>
            <div className="text-xs text-gray-500">{performance.rating}</div>
          </div>
        </div>
      </div>
      
      <div className="flex justify-center w-1/2 mx-auto mt-1">
        <Link to={"/profile/performance"}
          className="bg-blue-100 text-blue-500 text-center font-bold px-6 py-2 rounded-md text-sm hover:bg-blue-200 transition-colors w-full"
        >
          Details
        </Link>
      </div>
    </div>
  );
};
export default PerformanceCard;