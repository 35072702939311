import React from 'react'
import CouponManagement from '../components/CouponManagement'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'

const Coupons = () => {
    return (
        <div className=' overflow-hidden'>
            <Navbar />
            <div className='min-h-[80vh] max-h-screen overflow-y-auto overflow-x-hidden'
                style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}>
                <CouponManagement />
            </div>

            <Footer />
        </div>
    )
}

export default Coupons