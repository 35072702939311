export const Packages = [
  {
    _id: "65d93ff1aaf8ebc47c522ced",
    nameOfPlan: "SOLO PACK",
    amount: 999,
    description:
      "Maximize your exam readiness with our Solo Pack. Choose from General English or General Test or any domain subject. Includes preparatory module, 12 practice tests, and 12 mock tests.",
    benefits: [
      "General English / General Test",
      "/ Any one Domain Subject",
      "Full Access to Prep Modules",
      "12 Practice Tests",
      "12 Mock Tests",
      "Over 2000 questions per subject",
      "Total one Subject",
      "Unlimited Attempts ",
    ],
    bgColor: "rgb(208, 239, 245, 0.8)",
  },
  {
    _id: "65d94008aaf8ebc47c522cef",
    nameOfPlan: "DUO PACK",
    amount: 1899,
    description:
      "Supercharge your preparation with our Duo Pack. Choose any from: General English and any one domain subject, General Test and one domain subject, or any two domain subjects. Includes preparatory modules, 12 practice tests, and 12 mock tests for each.",
    benefits: [
      "General English & General Test",
      "/ Any two Domain Subjects",
      "Full Access to Prep Modules",
      "12 Practice Tests for each Subject",
      "12 Mock Tests for each Subject",
      "Over 2000 questions per subject",
      "Total two Subjects",
      "Unlimited Attempts ",
    ],
    // bgColor: 'rgb(160, 232, 175, 0.6)'
    bgColor: "rgb(242, 224, 223, 0.6)",
  },
  {
    _id: "65d9428fd3267bf1efe0f364",
    nameOfPlan: "MEGA PACK",
    amount: 2999,
    description:
      "Introducing our MEGA pack! Choose any three domain subjects of your choice, along with general English and general Test. Includes preparatory modules, 12 practice tests, and 12 mock tests for each.",
    benefits: [
      "Both General English & General Test",
      "Any three Domain Subjects",
      "Full Access to Prep Modules",
      "12 Practice Tests for each Subject",
      "12 Mock Tests for each Subject",
      "Over 2000 questions per subject",
      "Total five Subjects",
      "Unlimited Attempts ",
    ],
    bgColor: "rgb(217, 196, 240, 0.6)",
  },
  {
    _id: "65e352e265a057561b4dcb67",
    nameOfPlan: "JUMBO PACK",
    amount: 3999,
    description:
      "Elevate your exam readiness with our Jumbo Pack. Choose any four domain subjects along with general Test and general English. Includes preparatory modules, 12 practice tests, and 12 mock tests for each.",
    benefits: [
      "Both General English & General Test",
      "Any four Domain Subjects",
      "Full Access to Prep Modules",
      "12 Practice Tests for each Subject",
      "12 Mock Tests for each Subject",
      "Over 2000 questions per subject",
      "Total six Subjects",
      "Unlimited Attempts ",
    ],
    // bgColor: 'rgb(242, 224, 223, 0.6)'
    bgColor: "rgb(203, 179, 84, 0.6)",
  },
];
