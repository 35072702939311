import { useState } from "react";
import { Link, useNavigate } from "react-router-dom/dist";
import ExamInfo from "../../components/home/ExamInfo";
import Notification from "../notification/Notification";

const Banner = () => {
  const navigate = useNavigate();
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handlescroll = () => {
    window.scrollTo({
      top: 600,
      behavior: 'smooth'
    });
  };

  const handleBellIconClick = () => {
    setIsPopupOpen(true);
  };

  const handleCloseExamInfo = () => {
    setIsPopupOpen(false);
  };

  return (
    <div className="max-w-[1280px] mx-auto md:mb-8">
      <div className=" md:mx-[20px] mx-3 mt-[40px] flex flex-col md:flex-row justify-between items-center ">
        <div className="flex justify-center items-center ">
          <div className="flex flex-col bg-black rounded-lg gap-2 md:p-5 pl-3 max-md:px-2  justify-center md:mb-[-30px] md:max-w-[45vw] ">
            <div className="my-4 font-bold text-5xl md:text-17xl">
              <p className="m-0 text-white">One Stop Solution for your</p>
              <p className="m-0">
                <span className="text-white">all </span>
                <span className="text-10xl md:text-[55px] text-[#FF820F]">CUET</span>
                <span className="text-7xl md:text-21xl text-white"> needs</span>
              </p>
            </div>
            <div className="mb-4 text-white">
              <b className="h-12 relative max-w-full font-semibold text-lg">
                <p className="m-0">Avail Complete Support from India’s best trainers</p>
              </b>
            </div>
            <div>
              <h3 className="m-0 h-[58px] relative font-bold font-inherit text-white inline-block text-lg md:text-3xl ">
                <p className="m-0">Trusted by maximum Students & Teachers across the INDIA</p>
              </h3>
            </div>
            <div className="my-6 lg:hidden flex justify-center md:justify-start">
              <button
                onClick={() => navigate("/purchase")}
                className={
                  "btn text-sm md:text-lg font-medium text-white bg-[#FF820F] hover:bg-[#FF820F]  no-underline rounded-full border-0 min-h-0 h-10 md:w-40 w-35 md:mr-8 mr-3 capitalize"
                }
              >
                Join Us
              </button>
              <button
                onClick={handlescroll}
                className={
                  "btn btn-outline text-sm md:text-lg font-medium border-white  text-white no-underline border-solid  hover:bg-black hover:text-white rounded-full min-h-0 h-10 w-[50%]  md:w-56 capitalize"
                }
              >
                Take a free test
              </button>
            </div>

          </div>
        </div>
        <div className=" lg:w-[550px] flex flex-col justify-center items-center  md:w-[400px] w-[300px] ">
          <img className="w-[98%]" alt=" " src={require("../../assets/images/home_banner.gif")} />

        </div>

      </div>
      <div className="flex mx-6 justify-between gap-8">
        <div className="my-6 max-lg:hidden flex justify-center md:justify-start items-center">
          <button
            onClick={() => navigate("/purchase")}
            className={
              "btn text-sm md:text-lg font-medium text-white bg-[#FF820F] hover:bg-[#FF820F]  no-underline rounded-full border-0 min-h-0 h-10 md:w-40 w-35 md:mr-8 mr-3 capitalize"
            }
          >
            Join Us
          </button>
          <button
            onClick={handlescroll}
            className={
              "btn btn-outline text-sm md:text-lg font-medium border-black text-black no-underline border-solid  hover:bg-black hover:text-white rounded-full min-h-0 h-10 w-40  md:w-56 capitalize"
            }
          >
            Take a free test
          </button>
        </div>
        <div className="gap-8 flex justify-center items-center ">

          <div className="flex  flex-col justify-center relative  max-lg:hidden  items-center  text-base cursor-pointer">
            <Notification isMobile={false}/>
          </div>
          {isPopupOpen && (
            <div className="absolute max-md:left-0 m-3 md:w-[25rem] rounded-lg">
              <ExamInfo onClose={handleCloseExamInfo} />
            </div>
          )}
          <div  >
            <table className=" table max-lg:hidden  lg:w-[400px]    rounded-2xl shadow-[0px_4px_6px_0px_rgba(0,0,0,0.1)]">
              <thead>
              </thead>
              <tbody className="font-semibold">
                <tr >
                  <Link className="no-underline hover:underline " target="_blank" to="https://cuet.nta.nic.in/">
                    <td className="w-9 gradient-text2" >
                      CUET       </td>
                    <td className="text-left font-medium text-gray-900"  >
                      Published on March 01, 2025 <br />
                      Closing on March 22, 2025, at 11:50 PM
                    </td>
                  </Link>
                </tr>
              </tbody>
            </table>
            {/* <div onClick={handleBellIconClick} className="max-lg:hidden"><p className="w-full font-normal my-5 underline text-right text-[#1877F2] cursor-pointer  " >All Notifications</p></div> */}
          </div>

        </div>
      </div>

    </div>

  );
};

export default Banner;