import { useEffect } from "react";

const DisableJs = () => {
  useEffect(() => {
    const preventDefaultAction = (e) => e.preventDefault();

    const preventKeyboardShortcuts = (e) => {
      if (
        e.ctrlKey ||
        e.metaKey ||
        e.key === "F12" ||
        (e.ctrlKey && e.shiftKey && e.key === "I") ||
        (e.ctrlKey && e.shiftKey && e.key === "J") ||
        (e.ctrlKey && e.key === "U") ||
        (e.ctrlKey && e.key === "F") ||
        (e.ctrlKey && e.key === "P") ||
        (e.ctrlKey && e.key === "S") ||
        (e.ctrlKey && e.key === "H") ||
        (e.ctrlKey && e.key === "J") ||
        (e.ctrlKey && e.key === "R") ||
        (e.ctrlKey && e.shiftKey && e.key === "R") ||
        (e.ctrlKey && e.shiftKey && e.key === "C")
      ) {
        e.preventDefault();
      }
    };

    document.addEventListener("copy", preventDefaultAction);
    document.addEventListener("cut", preventDefaultAction);
    document.addEventListener("paste", preventDefaultAction);
    document.addEventListener("contextmenu", preventDefaultAction);
    document.addEventListener("keydown", preventKeyboardShortcuts);

    return () => {
      document.removeEventListener("copy", preventDefaultAction);
      document.removeEventListener("cut", preventDefaultAction);
      document.removeEventListener("paste", preventDefaultAction);
      document.removeEventListener("contextmenu", preventDefaultAction);
      document.removeEventListener("keydown", preventKeyboardShortcuts);
    };
  }, []);

  return null;
};

export default DisableJs;
