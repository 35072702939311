import SearchIcon from '@mui/icons-material/Search';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, InputAdornment, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';

const Avatars = ({ open, onClose, onSelectAvatar }) => {
  const [searchInput, setSearchInput] = useState('');
  const [searchPreview, setSearchPreview] = useState(null);
  const [fixedAvatars, setFixedAvatars] = useState([]);
  // Add avatar style options from DiceBear
  const [avatarStyle, setAvatarStyle] = useState('avataaars');
  const avatarStyles = ['avataaars', 'bottts', 'pixel-art', 'lorelei', 'micah', 'adventurer'];

  useEffect(() => {
    fetchFixedAvatars();
  }, [avatarStyle]);

  const fetchFixedAvatars = async () => {
    try {
      // Generate 10 random avatars using DiceBear API
      const avatars = [...Array(10).keys()].map((index) => {
        return `https://api.dicebear.com/7.x/${avatarStyle}/svg?seed=${index}`;
      });
      setFixedAvatars(avatars);
    } catch (error) {
      console.error('Error generating fixed avatars:', error);
      setFixedAvatars([]);
    }
  };

  const handleSearchPreview = async () => {
    if (!searchInput.trim()) return;

    try {
      // Generate avatar with search input as seed
      const avatarUrl = `https://api.dicebear.com/7.x/${avatarStyle}/svg?seed=${searchInput}`;
      setSearchPreview(avatarUrl);
    } catch (error) {
      console.error('Error generating avatar preview:', error);
      setSearchPreview(null);
    }
  };

  const handleStyleChange = (style) => {
    setAvatarStyle(style);
    setSearchPreview(null);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <DialogTitle>Select an Avatar</DialogTitle>
      <DialogContent>
        <div style={{ marginBottom: '20px' }}>
          <p style={{ marginBottom: '10px' }}>Select Avatar Style:</p>
          <Grid container spacing={2}>
            {avatarStyles.map((style) => (
              <Grid item key={style}>
                <Button
                  variant={avatarStyle === style ? "contained" : "outlined"}
                  onClick={() => handleStyleChange(style)}
                  size="small"
                >
                  {style}
                </Button>
              </Grid>
            ))}
          </Grid>
        </div>

        <TextField
          fullWidth
          label="Enter random word for custom avatar"
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleSearchPreview();
            }
          }}
          margin="normal"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleSearchPreview}
                  edge="end"
                >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        {searchPreview && (
          <div style={{ textAlign: 'center', margin: '20px 0' }}>
            <p>Preview:</p>
            <img
              src={searchPreview}
              alt="Search Preview"
              style={{ width: '80px', height: '80px', cursor: 'pointer', margin: '10px auto' }}
              onClick={() => onSelectAvatar(searchPreview)}
            />
          </div>
        )}

        <div style={{ marginTop: '20px' }}>
          <p>Choose from samples:</p>
          <Grid container spacing={2} style={{ marginTop: '10px' }}>
            {fixedAvatars.map((avatar, index) => (
              <Grid item key={index}>
                <img
                  src={avatar}
                  alt={`Avatar ${index}`}
                  style={{
                    width: '60px',
                    height: '60px',
                    cursor: 'pointer',
                    border: '2px solid transparent',
                    borderRadius: '4px',
                    padding: '4px',
                    transition: 'all 0.2s',
                    '&:hover': {
                      transform: 'scale(1.1)',
                      border: '2px solid #3f51b5'
                    }
                  }}
                  onClick={() => onSelectAvatar(avatar)}
                />
              </Grid>
            ))}
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Avatars;