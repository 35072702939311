import React from "react";
import Score from "../../assets/images/practicetest/score.gif";
import Rank from "../../assets/images/practicetest/rank.gif";
import time from "../../assets/images/practicetest/time.gif";
import percentage from "../../assets/images/practicetest/percentage.gif";
import { IoMdClose } from "react-icons/io";

const ScoreReport = ({ data, onClose, onRetakeTest, onReviewTest }) => {
  // Extract values from data
  const { attemptDetails, ranking } = data;
  const { userRank, topPerformers, testInfo } = ranking;

  // Score and time data - using the provided attemptDetails
  const correctAnswers = attemptDetails.correctAnswers;
  const incorrectAnswers = attemptDetails.incorrectAnswers;
  const unattempted = attemptDetails.unattemptedQuestions;
  const totalQuestions = attemptDetails.totalQuestions;
  const totalTimeSpent = attemptDetails.timeTaken; // in seconds
  const totalTimeAllowed = 20 * 60; // 20 minutes in seconds
  const percentile = (correctAnswers / totalQuestions) * 100;

  // Time distribution calculations
  const averageTimePerQuestion =
    totalTimeSpent / (correctAnswers + incorrectAnswers);
  const correctAnswersTime = Math.floor(
    correctAnswers * averageTimePerQuestion
  );
  const incorrectAnswersTime = Math.floor(
    incorrectAnswers * averageTimePerQuestion
  );
  const unusedTime = totalTimeAllowed - totalTimeSpent;

  // Section data
  const sectionScore = `${correctAnswers}/${totalQuestions}`;
  const sectionTime = `${Math.floor(totalTimeSpent / 60)}:${(
    totalTimeSpent % 60
  )
    .toString()
    .padStart(2, "0")}`;
  const sectionPercentile = percentile;

  // Calculate percentages for time spent
  // const usedTimePercentage = (totalTimeSpent / totalTimeAllowed) * 100;
  const unusedTimePercentage = (unusedTime / totalTimeAllowed) * 100;
  const correctTimePercentage = (correctAnswersTime / totalTimeAllowed) * 100;
  const incorrectTimePercentage =
    (incorrectAnswersTime / totalTimeAllowed) * 100;

  // Calculate percentages for answer distribution
  const totalQuestionsCount = correctAnswers + incorrectAnswers + unattempted;
  const correctPercentage = (correctAnswers / totalQuestionsCount) * 100;
  const incorrectPercentage = (incorrectAnswers / totalQuestionsCount) * 100;
  const unattemptedQuestionsPercentage =
    (unattempted / totalQuestionsCount) * 100;

  // Get topper's data
  const topper = topPerformers[0];
  const topperScore = `${topper.score}/${totalQuestions}`;
  const topperTime = `${Math.floor(topper.timeTaken / 60)}:${(
    topper.timeTaken % 60
  )
    .toString()
    .padStart(2, "0")}`;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="relative max-w-[1100px] mx-auto w-full h-full md:h-auto md:max-h-[90vh] bg-orange-50 overflow-y-auto">
        <div className="sticky top-0 right-0 flex justify-end p-2 bg-orange-50 z-10">
          <button
            onClick={onClose}
            className="p-1 bg-orange-500 rounded-full text-white hover:bg-orange-600 transition-colors"
            aria-label="Close"
          >
            <IoMdClose className="text-base" />
          </button>
        </div>

        <div className="p-4 sm:p-6">
          {/* Report Button */}
          <div className="flex justify-center mb-4">
            <button className="bg-orange-500 text-white py-2 px-8 rounded-md border-none outline-none text-sm sm:text-base">
              Report
            </button>
          </div>

          {/* Score Metrics Row */}
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 mb-6">
            <div className="border rounded-md p-3 shadow-lg">
              <div className="flex items-center gap-2">
                <img
                  src={Score}
                  alt="Icon"
                  className="w-8 h-8 md:w-10 md:h-10 object-contain"
                />
                <span className="text-xs sm:text-sm font-medium text-gray-600">
                  Score
                </span>
                <span className="ml-1 text-sm text-orange-400 font-normal">
                  {sectionScore}
                </span>
              </div>
              <div className="w-full bg-gray-200 rounded-full h-2 mt-2">
                <div
                  className="bg-orange-400 h-2 rounded-full"
                  style={{
                    width: `${(correctAnswers / totalQuestions) * 100}%`,
                  }}
                ></div>
              </div>
            </div>

            <div className="border rounded-md p-3 shadow-lg">
              <div className="flex items-center gap-2">
                <img
                  src={Rank}
                  alt="Icon"
                  className="w-8 h-8 md:w-10 md:h-10 object-contain"
                />
                <span className="text-xs sm:text-sm font-medium text-gray-600">
                  Rank
                </span>
                <span className="ml-1 text-sm text-orange-400 font-normal">
                  {userRank.rank}/{userRank.totalParticipants}
                </span>
              </div>
              <div className="w-full bg-gray-200 rounded-full h-2 mt-2">
                <div
                  className="bg-orange-400 h-2 rounded-full"
                  style={{
                    width: `${
                      ((userRank.totalParticipants - userRank.rank + 1) /
                        userRank.totalParticipants) *
                      100
                    }%`,
                  }}
                ></div>
              </div>
            </div>

            <div className="border rounded-md p-3 shadow-lg">
              <div className="flex items-center gap-2">
                <img
                  src={time}
                  alt="Icon"
                  className="w-8 h-8 md:w-10 md:h-10 object-contain"
                />
                <span className="text-xs sm:text-sm font-medium text-gray-600">
                  Time
                </span>
                <span className="ml-1 text-sm text-orange-400 font-normal">
                  {Math.floor(totalTimeSpent / 60)}:
                  {(totalTimeSpent % 60).toString().padStart(2, "0")}
                </span>
              </div>
              <div className="w-full bg-gray-200 rounded-full h-2 mt-2">
                <div
                  className="bg-orange-400 h-2 rounded-full"
                  style={{
                    width: `${(totalTimeSpent / totalTimeAllowed) * 100}%`,
                  }}
                ></div>
              </div>
            </div>

            <div className="border rounded-md p-3 shadow-lg">
              <div className="flex items-center gap-2">
                <img
                  src={percentage}
                  alt="Icon"
                  className="w-8 h-8 md:w-10 md:h-10 object-contain"
                />
                <span className="text-xs sm:text-sm font-medium text-gray-600">
                  Percentage
                </span>
                <span className="ml-1 text-sm text-orange-400 font-normal">
                  {percentile.toFixed(2)}%
                </span>
              </div>
              <div className="w-full bg-gray-200 rounded-full h-2 mt-2">
                <div
                  className="bg-orange-400 h-2 rounded-full"
                  style={{ width: `${percentile}%` }}
                ></div>
              </div>
            </div>
          </div>

          {/* Topper's Comparison */}
          <div className="mb-6">
            <h3 className="text-sm font-medium mb-3">Topper's Comparison</h3>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div className="border rounded-md p-3 shadow-lg">
                <div className="flex items-center gap-2">
                  <img
                    src={Score}
                    alt="Icon"
                    className="w-8 h-8 md:w-10 md:h-10 object-contain"
                  />
                  <span className="text-xs sm:text-sm font-medium text-gray-600">
                    Topper's Score
                  </span>
                  <span className="ml-1 text-sm text-orange-400 font-normal">
                    {topperScore}
                  </span>
                </div>
                <div className="w-full bg-gray-200 rounded-full h-2 mt-2">
                  <div
                    className="bg-orange-400 h-2 rounded-full"
                    style={{
                      width: `${(topper.score / totalQuestions) * 100}%`,
                    }}
                  ></div>
                </div>
              </div>

              <div className="border rounded-md p-3 shadow-lg">
                <div className="flex items-center gap-2">
                  <img
                    src={time}
                    alt="Icon"
                    className="w-8 h-8 md:w-10 md:h-10 object-contain"
                  />
                  <span className="text-xs sm:text-sm font-medium text-gray-600">
                    Topper's Time
                  </span>
                  <span className="ml-1 text-sm text-orange-400 font-normal">
                    {topperTime}
                  </span>
                </div>
                <div className="w-full bg-gray-200 rounded-full h-2 mt-2">
                  <div
                    className="bg-orange-400 h-2 rounded-full"
                    style={{
                      width: `${(topper.timeTaken / totalTimeAllowed) * 100}%`,
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>

          {/* Overall Analysis */}
          <div className="bg-blue-50 p-4 rounded-md">
            <h3 className="text-sm font-medium mb-4 text-center">
              Overall Analysis
            </h3>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
              {/* Answer Distribution Donut Chart */}
              <div className="flex flex-col items-center">
                <div className="relative w-32 h-32 mb-4">
                  <svg viewBox="0 0 100 100" className="w-full h-full">
                    {/* Correct answers */}
                    <circle
                      cx="50"
                      cy="50"
                      r="40"
                      fill="transparent"
                      stroke="#3b82f6"
                      strokeWidth="20"
                      strokeDasharray="251.2"
                      strokeDashoffset={
                        251.2 - (251.2 * correctPercentage) / 100
                      }
                      transform="rotate(-90 50 50)"
                    />
                    {/* Incorrect answers */}
                    <circle
                      cx="50"
                      cy="50"
                      r="40"
                      fill="transparent"
                      stroke="#f97316"
                      strokeWidth="20"
                      strokeDasharray="251.2"
                      strokeDashoffset={
                        251.2 - (251.2 * incorrectPercentage) / 100
                      }
                      transform={`rotate(${
                        (correctPercentage / 100) * 360 - 90
                      } 50 50)`}
                    />
                    {/* Unattempted questions */}
                    <circle
                      cx="50"
                      cy="50"
                      r="40"
                      fill="transparent"
                      stroke="#ef4444"
                      strokeWidth="20"
                      strokeDasharray="251.2"
                      strokeDashoffset={
                        251.2 - (251.2 * unattemptedQuestionsPercentage) / 100
                      }
                      transform={`rotate(${
                        ((correctPercentage + incorrectPercentage) / 100) *
                          360 -
                        90
                      } 50 50)`}
                    />
                    <circle cx="50" cy="50" r="30" fill="white" />
                  </svg>
                  <div className="absolute inset-0 flex flex-col items-center justify-center text-xs text-center">
                    <span className="font-medium">Answer</span>
                    <span className="font-medium">Distribution</span>
                  </div>
                </div>
                <div className="mt-2 text-xs space-y-1">
                  <div className="flex items-center gap-1">
                    <div className="w-2 h-2 rounded-full bg-blue-500"></div>
                    <span>Correct</span>
                    <span className="ml-auto font-medium">
                      {correctPercentage.toFixed(2)}%
                    </span>
                  </div>
                  <div className="flex items-center gap-1">
                    <div className="w-2 h-2 rounded-full bg-orange-500"></div>
                    <span>Incorrect</span>
                    <span className="ml-auto font-medium">
                      {incorrectPercentage.toFixed(2)}%
                    </span>
                  </div>
                  <div className="flex items-center gap-1">
                    <div className="w-2 h-2 rounded-full bg-red-500"></div>
                    <span>Unattempted</span>
                    <span className="ml-auto font-medium">
                      {unattemptedQuestionsPercentage.toFixed(2)}%
                    </span>
                  </div>
                </div>
              </div>

              {/* Time Spent Donut Chart */}
              <div className="flex flex-col items-center">
                <div className="relative w-32 h-32 mb-4">
                  <svg viewBox="0 0 100 100" className="w-full h-full">
                    {/* Correct Time Segment (Green) */}
                    <circle
                      cx="50"
                      cy="50"
                      r="40"
                      fill="transparent"
                      stroke="#22c55e"
                      strokeWidth="20"
                      strokeDasharray="251.2"
                      strokeDashoffset={
                        251.2 - (251.2 * correctTimePercentage) / 100
                      }
                      transform="rotate(-90 50 50)"
                    />

                    {/* Incorrect Time Segment (Yellow) */}
                    <circle
                      cx="50"
                      cy="50"
                      r="40"
                      fill="transparent"
                      stroke="#facc15"
                      strokeWidth="20"
                      strokeDasharray="251.2"
                      strokeDashoffset={
                        251.2 - (251.2 * incorrectTimePercentage) / 100
                      }
                      transform={`rotate(${
                        (correctTimePercentage / 100) * 360 - 90
                      } 50 50)`}
                    />

                    {/* Unused Time Segment (Gray) */}
                    <circle
                      cx="50"
                      cy="50"
                      r="40"
                      fill="transparent"
                      stroke="#6b7280"
                      strokeWidth="20"
                      strokeDasharray="251.2"
                      strokeDashoffset={
                        251.2 - (251.2 * unusedTimePercentage) / 100
                      }
                      transform={`rotate(${
                        ((correctTimePercentage + incorrectTimePercentage) /
                          100) *
                          360 -
                        90
                      } 50 50)`}
                    />
                    <circle cx="50" cy="50" r="30" fill="white" />
                  </svg>
                  <div className="absolute inset-0 flex flex-col items-center justify-center text-xs text-center">
                    <span className="font-medium">Time Spent</span>
                  </div>
                </div>

                <div className="mt-2 text-xs space-y-1">
                  <div className="flex items-center gap-1">
                    <div className="w-2 h-2 rounded-full bg-green-500"></div>
                    <span>Correct Answers</span>
                    <span className="ml-auto font-medium">
                      {correctTimePercentage.toFixed(2)}%
                    </span>
                  </div>
                  <div className="flex items-center gap-1">
                    <div className="w-2 h-2 rounded-full bg-yellow-500"></div>
                    <span>Incorrect Answers</span>
                    <span className="ml-auto font-medium">
                      {incorrectTimePercentage.toFixed(2)}%
                    </span>
                  </div>
                  <div className="flex items-center gap-1">
                    <div className="w-2 h-2 rounded-full bg-gray-500"></div>
                    <span>Unused Time</span>
                    <span className="ml-auto font-medium">
                      {unusedTimePercentage.toFixed(2)}%
                    </span>
                  </div>
                </div>
              </div>

              {/* Performance Table */}
              <div className="rounded-lg overflow-hidden shadow-sm">
                <table className="w-full text-xs">
                  <thead>
                    <tr className="bg-orange-200">
                      <th className="py-2 px-2 text-left font-medium text-gray-700">
                        Sections
                      </th>
                      <th className="py-2 px-2 text-left font-medium text-gray-700">
                        Score
                      </th>
                      <th className="py-2 px-2 text-left font-medium text-gray-700">
                        Time Spent
                      </th>
                      <th className="py-2 px-2 text-left font-medium text-gray-700">
                        Percentile
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="py-2 px-2 text-gray-700">{`${
                        testInfo.topicName
                          .replace(/_/g, " ") // Replace underscores with spaces
                          .replace(/\b\w/g, (char) => char.toUpperCase()) // Capitalize first letter of each word
                      } ${
                        testInfo.testType
                          .replace(/_/g, " ") // Replace underscores with spaces
                          .replace(/\b\w/g, (char) => char.toUpperCase()) // Capitalize first letter of each word
                      } Test ${testInfo.testNumber}`}</td>

                      <td className="py-2 px-2 text-gray-700">
                        {sectionScore}
                      </td>
                      <td className="py-2 px-2 text-gray-700">{sectionTime}</td>
                      <td className="py-2 px-2 text-gray-700">
                        {sectionPercentile.toFixed(2)}%
                      </td>
                    </tr>
                    <tr className="bg-orange-200">
                      <td className="py-2 px-2 font-medium text-gray-700">
                        Total
                      </td>
                      <td className="py-2 px-2 font-medium text-gray-700">
                        {sectionScore}
                      </td>
                      <td className="py-2 px-2 font-medium text-gray-700">
                        {sectionTime}
                      </td>
                      <td className="py-2 px-2 font-medium text-gray-700">-</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {/* Action Buttons */}
          <div className="mt-6 flex justify-center space-x-4">
            {onRetakeTest && (
              <button
                onClick={onRetakeTest}
                className="bg-blue-500 text-white py-2 px-6 rounded-md hover:bg-blue-600 transition-colors"
              >
                Retake Test
              </button>
            )}
            {onReviewTest && (
              <button
                onClick={onReviewTest}
                className="bg-green-500 text-white py-2 px-6 rounded-md hover:bg-green-600 transition-colors"
              >
                Review Test
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScoreReport;
