import React, { useState, useEffect } from 'react';
import { useSearch } from '../../../utils/SearchProvider';
import axios from 'axios';
import { API } from '../../../utils/constants';
import { useAuth } from '../../../utils/context';

const UserPerformance = () => {
  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedTestType, setSelectedTestType] = useState('mock');
  const [selectedTestNumber, setSelectedTestNumber] = useState('');
  const [viewMode, setViewMode] = useState('topic');

  const [rankings, setRankings] = useState([]);
  const [filteredRankings, setFilteredRankings] = useState([]);
  const [testRanking, setTestRanking] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [institute, setInstitute] = useState('');
  const [error, setError] = useState(null);
  const [availableSubjects, setAvailableSubjects] = useState([]);
  const [availableTestNumbers, setAvailableTestNumbers] = useState([]);

  const { searchTerm } = useSearch();
  const [auth] = useAuth();

  const testTypes = ['mock', 'practice'];

  const formatSubjectForAPI = (subject) => {
    return subject.toLowerCase().replace(/\s+/g, '_');
  };


  useEffect(() => {
    try {
      const userData = auth?.user?.selectedSubjects || [];
      const subjects = userData.map(subject => subject.subjectName);

      setAvailableSubjects(subjects);

      if (subjects.length > 0 && !selectedSubject) {
        setSelectedSubject(subjects[0]);
      }
    } catch (error) {
      console.error("Error loading subjects from local storage:", error);
      setError("Failed to load subjects. Please refresh the page.");
    }
  }, [auth?.user]);

  useEffect(() => {
    if (viewMode === 'topic' && selectedSubject && selectedTestType && auth?.accessToken?.token) {
      fetchTopicLeaderboardData();
    }
  }, [viewMode, selectedSubject, selectedTestType, auth?.accessToken?.token, auth.user?._id]);

  useEffect(() => {
    if (viewMode === 'test' && selectedSubject && selectedTestType && selectedTestNumber && auth?.accessToken?.token) {
      fetchTestLeaderboardData();
    }
  }, [viewMode, selectedSubject, selectedTestType, selectedTestNumber, auth?.accessToken?.token, auth.user?._id]);

  useEffect(() => {
    if (selectedSubject && selectedTestType && auth?.accessToken?.token) {
      fetchAvailableTestNumbers();
    }
  }, [selectedSubject, selectedTestType, auth?.accessToken?.token]);

  const fetchAvailableTestNumbers = async () => {
    try {
      setAvailableTestNumbers([1, 2, 3, 4, 5,6,7,8,9,10,11,12,13,14,15]);

      if (!selectedTestNumber) {
        setSelectedTestNumber('1');
      }
    } catch (error) {
      console.error("Error fetching test numbers:", error);
    }
  };

  const fetchTopicLeaderboardData = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const formattedSubject = formatSubjectForAPI(selectedSubject);

      const response = await axios.get(
        `${API}/scores/ranking/topic/${auth.user?._id}?topicName=${formattedSubject}&testType=${selectedTestType}`,
        {
          headers: {
            Authorization: `Bearer ${auth.accessToken.token}`,
          },
        }
      );

      if (response.data.success) {
        setRankings(response.data.rankings);
        setFilteredRankings(response.data.rankings);
        setInstitute(response.data.institute);
      } else {
        if (response.data.message === "User does not have an associated institute.") {
          setError("You don't have an associated institute. Please contact Us.");
        } else {
          setError("Failed to load rankings. Please try again.");
        }
      }

      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching leaderboard data:", error);

      if (error.response?.data?.message === "User does not have an associated institute.") {
        setError("You don't have an associated institute. Please contact Us");
      } else {
        setError("Network error. Please check your connection and try again.");
      }

      setIsLoading(false);
    }
  };

  const fetchTestLeaderboardData = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const formattedSubject = formatSubjectForAPI(selectedSubject);

      const response = await axios.get(
        `${API}/scores/ranking/test/${auth.user?._id}?topicName=${formattedSubject}&testType=${selectedTestType}&testNumber=${selectedTestNumber}`,
        {
          headers: {
            Authorization: `Bearer ${auth.accessToken.token}`,
          },
        }
      );

      if (response.data.success) {
        setTestRanking(response.data.ranking);
        setInstitute(response.data.institute);
      } else {
        if (response.data.message === "User does not have an associated institute.") {
          setError("You don't have an associated institute. Please contact Us.");
        } else {
          setError("Failed to load test rankings. Please try again.");
        }
      }

      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching test leaderboard data:", error);

      if (error.response?.data?.message === "User does not have an associated institute.") {
        setError("You don't have an associated institute. Please contact Us");
      } else {
        setError("Network error. Please check your connection and try again.");
      }

      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (viewMode === 'topic') {
      if (searchTerm.trim() === '') {
        setFilteredRankings(rankings);
      } else {
        const filtered = rankings.filter(student =>
          student.userName.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredRankings(filtered);
      }
    }
  }, [searchTerm, rankings, viewMode]);

  const handleSubjectChange = (e) => {
    setSelectedSubject(e.target.value);
    setSelectedTestNumber('');
  };

  const handleTestTypeChange = (e) => {
    setSelectedTestType(e.target.value);
    setSelectedTestNumber('');
  };

  const handleTestNumberChange = (e) => {
    setSelectedTestNumber(e.target.value);
  };

  const handleViewModeChange = (mode) => {
    setViewMode(mode);
  };

  const formatTime = (timeInSeconds) => {
    if (timeInSeconds === undefined || timeInSeconds === null) return 'N/A';

    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;

    return `${minutes} min ${seconds} sec`;
  };

  const isCurrentUser = (userId) => {
    return auth.user?._id === userId;
  };

  const getReorganizedRankings = () => {
    if (!rankings.length) return [];

    const top3 = rankings.filter(student => student.rank <= 3);
    const currentUser = rankings.find(student => isCurrentUser(student.userId));

    if (!currentUser || top3.some(student => isCurrentUser(student.userId))) {
      return filteredRankings;
    }

    const otherUsers = filteredRankings.filter(
      student => student.rank > 3 && !isCurrentUser(student.userId)
    );

    const orgFilteredRankings = [...top3];

    if (currentUser.rank > 3) {
      orgFilteredRankings.push({
        isDivider: true,
        id: 'divider1'
      });
      orgFilteredRankings.push(currentUser);

      if (otherUsers.length > 0) {
        orgFilteredRankings.push({
          isDivider: true,
          id: 'divider2'
        });
      }
    }

    return [...orgFilteredRankings, ...otherUsers];
  };

  const getTestViewTopPerformers = () => {
    if (!testRanking || !testRanking.topPerformers) return [];

    const topPerformers = [...testRanking.topPerformers];
    const currentUser = topPerformers.find(student => isCurrentUser(student.userId));
    const currentUserRank = currentUser?.rank || 0;

    if (!currentUser || currentUserRank <= 3) {
      return topPerformers;
    }

    const top3 = topPerformers.filter(student => student.rank <= 3);
    const otherUsers = topPerformers.filter(
      student => student.rank > 3 && !isCurrentUser(student.userId)
    );

    const reorganized = [...top3];

    reorganized.push({
      isDivider: true,
      id: 'divider1'
    });
    reorganized.push(currentUser);

    if (otherUsers.length > 0) {
      reorganized.push({
        isDivider: true,
        id: 'divider2'
      });
      reorganized.push(...otherUsers);
    }

    return reorganized;
  };
  const formatSubjectName = (name) => {
    return name
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  return (
    <div className="w-full min-h-full rounded-3xl mx-auto p-4 bg-white">
      <div className="mb-6">
        <div className="flex justify-between items-center mb-4">
          <div>
            <h1 className="text-xl font-bold">Leaderboard</h1>
            {institute && <p className="text-gray-600">Institute: {institute}</p>}
          </div>
          {viewMode === 'topic' && searchTerm && (
            <div className="text-blue-600">
              {filteredRankings.length} result{filteredRankings.length !== 1 ? 's' : ''} for "{searchTerm}"
            </div>
          )}
        </div>

        <div className="flex max-md:mx-auto text-xs border-[1px] border-blue-600 p-1 rounded-xl w-fit border-solid mb-4">
          <div
            className={`py-2 cursor-pointer border-none font-medium px-4 rounded-l-xl  ${viewMode === 'topic' ? ' bg-blue-500 text-white ' : 'text-gray-500 bg-[#ececec] hover:text-gray-700'}`}
            onClick={() => handleViewModeChange('topic')}
          >
            Topic Rankings
          </div>
          <div
            className={`py-2 cursor-pointer  border-none  font-medium rounded-r-xl px-4 ${viewMode === 'test' ? ' bg-blue-500 text-white ' : ' text-gray-500 bg-[#ececec] hover:text-gray-700 '}`}
            onClick={() => handleViewModeChange('test')}
          >
            Test Rankings
          </div>
        </div>

        <div className="flex justify-end gap-2 mb-4">
          <div className="w-40">
            <select
              value={selectedSubject}
              onChange={handleSubjectChange}
              className="w-full p-2 border border-gray-300 rounded-md"
              disabled={availableSubjects.length === 0}
            >
              {availableSubjects.length === 0 ? (
                <option value="">No subjects available</option>
              ) : (
                availableSubjects.map((subject) => (
                  <option key={subject} value={subject}>{subject}</option>
                ))
              )}
            </select>
          </div>

          <div className="w-40">
            <select
              value={selectedTestType}
              onChange={handleTestTypeChange}
              className="w-full p-2 border border-gray-300 rounded-md"
            >
              {testTypes.map((type) => (
                <option key={type} value={type}>{type === 'mock' ? 'Mock Test' : 'Practice Test'}</option>
              ))}
            </select>
          </div>

          {viewMode === 'test' && (
            <div className="w-40">
              <select
                value={selectedTestNumber}
                onChange={handleTestNumberChange}
                className="w-full p-2 border border-gray-300 rounded-md"
                disabled={!availableTestNumbers.length}
              >
                <option value="">Select Test Number</option>
                {availableTestNumbers.map((number) => (
                  <option key={number} value={number}>Test {number}</option>
                ))}
              </select>
            </div>
          )}
        </div>
      </div>

      {error ? (
        <div className="text-center py-10">
          <p className="text-red-500">{error}</p>
        </div>
      ) : isLoading ? (
        <div className="flex justify-center items-center h-40">
          <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-solid border-blue-500"></div>
        </div>
      ) : viewMode === 'topic' ? (
        filteredRankings.length === 0 ? (
          <div className="text-center py-10">
            <p className="text-gray-500">
              {searchTerm
                ? `No students found matching "${searchTerm}"`
                : "Your ranking's on hold… Start practicing to unlock it!"}
            </p>
          </div>
        ) : (
          <div className="overflow-x-auto">
            <table className="w-full border-collapse">
              <thead>
                <tr className="bg-blue-50">
                  <th className="p-3 text-left border border-gray-200">Rank</th>
                  <th className="p-3 text-left border border-gray-200">Student Name</th>
                  <th className="p-3 text-left border border-gray-200">Average Score</th>
                  <th className="p-3 text-left border border-gray-200">Best Score</th>
                  <th className="p-3 text-left border border-gray-200">Last Score</th>
                  <th className="p-3 text-left border border-gray-200">Best Time</th>
                  <th className="p-3 text-left border border-gray-200">Last Time</th>
                  <th className="p-3 text-left border border-gray-200">Total Attempts</th>
                </tr>
              </thead>
              <tbody>
                {getReorganizedRankings().map((item) => {
                  if (item.isDivider) {
                    return (
                      <tr key={item.id} className="h-4">
                        <td colSpan="8" className="p-0">
                          <div className="border-t-2 border-dashed border-gray-300"></div>
                        </td>
                      </tr>
                    );
                  }

                  const isUserRow = isCurrentUser(item.userId);
                  const isTopThree = item.rank <= 3;
                  return (
                    <tr
                      key={item.userId}
                      className={`
                        hover:bg-gray-50
                        ${isUserRow ? 'bg-blue-50 font-medium' : ''}
                        ${isTopThree && !isUserRow ? 'bg-yellow-50' : ''}
                      `}
                    >
                      <td className="p-3 border-b border-gray-200">
                        {item.rank <= 3 ? (
                          <div className={`
                            rounded-full w-6 h-6 flex items-center justify-center
                            ${item.rank === 1 ? 'bg-yellow-400' : ''}
                            ${item.rank === 2 ? 'bg-gray-300' : ''}
                            ${item.rank === 3 ? 'bg-amber-700' : ''}
                            text-white font-bold
                          `}>
                            {item.rank}
                          </div>
                        ) : (
                          `#${item.rank}`
                        )}
                      </td>
                      <td className="p-3 border-b border-gray-200">
                        <div className="flex items-center gap-2">
                          {item.profilePic && (
                            <img
                              src={item.profilePic}
                              alt={`${item.userName}'s avatar`}
                              className="w-8 h-8 rounded-full"
                            />
                          )}
                          <span className={isUserRow ? 'text-blue-600' : ''}>
                            {item.userName} {isUserRow && '(You)'}
                          </span>
                        </div>
                      </td>
                      <td className="p-3 border-b border-gray-200">
                        {item.averageScore ? item.averageScore.toFixed(1) : 'N/A'}
                      </td>
                      <td className="p-3 border-b border-gray-200">{item.bestScore}</td>
                      <td className="p-3 border-b border-gray-200">{item.lastScore}</td>
                      <td className="p-3 border-b border-gray-200">{formatTime(item.bestTime)}</td>
                      <td className="p-3 border-b border-gray-200">{formatTime(item.lastTime)}</td>
                      <td className="p-3 border-b border-gray-200">{item.totalAttempts}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )
      ) : (
        !testRanking ? (
          <div className="text-center py-10">
            <p className="text-gray-500">
              {selectedTestNumber
                ? "No data available for this test number"
                : "Please select a test number to view rankings"}
            </p>
          </div>
        ) : !testRanking.userRank ? (
          testRanking.topPerformers && testRanking.topPerformers.length > 0 ? (
            <div>
              <div className="bg-gray-50 p-3 rounded-lg mb-4">
                <div className="flex flex-wrap gap-4">
                  <div>
                    <span className="text-gray-500">Topic:</span> {formatSubjectName(testRanking.testInfo.topicName)}
                  </div>
                  <div>
                    <span className="text-gray-500">Test Type:</span> {formatSubjectName(testRanking.testInfo.testType)}
                  </div>
                  <div>
                    <span className="text-gray-500">Test Number:</span> {testRanking.testInfo.testNumber}
                  </div>
                  <div>
                    <span className="text-gray-500">Total Participants:</span> {testRanking.testInfo.totalParticipants}
                  </div>
                </div>
              </div>

              <h2 className="text-lg font-semibold mb-2">Top Performers</h2>
              <div className="overflow-x-auto">
                <table className="w-full border-collapse">
                  <thead>
                    <tr className="bg-blue-50">
                      <th className="p-3 text-left border border-gray-200">Rank</th>
                      <th className="p-3 text-left border border-gray-200">Student Name</th>
                      <th className="p-3 text-left border border-gray-200">Score</th>
                      <th className="p-3 text-left border border-gray-200">Time Taken</th>
                      <th className="p-3 text-left border border-gray-200">Correct Answers</th>
                      <th className="p-3 text-left border border-gray-200">Incorrect Answers</th>
                      <th className="p-3 text-left border border-gray-200">Unattempted</th>
                      <th className="p-3 text-left border border-gray-200">Attempt Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {getTestViewTopPerformers().map((item) => {
                      if (item.isDivider) {
                        return (
                          <tr key={item.id} className="h-4">
                            <td colSpan="8" className="p-0">
                              <div className="border-t-2 border-dashed border-gray-300"></div>
                            </td>
                          </tr>
                        );
                      }

                      const isUserRow = isCurrentUser(item.userId);
                      const isTopThree = item.rank <= 3;

                      return (
                        <tr
                          key={item.userId}
                          className={`
                            hover:bg-gray-50
                            ${isUserRow ? 'bg-blue-50 font-medium' : ''}
                            ${isTopThree && !isUserRow ? 'bg-yellow-50' : ''}
                          `}
                        >
                          <td className="p-3 border-b border-gray-200">
                            {item.rank <= 3 ? (
                              <div className={`
                                rounded-full w-6 h-6 flex items-center justify-center
                                ${item.rank === 1 ? 'bg-yellow-400' : ''}
                                ${item.rank === 2 ? 'bg-gray-300' : ''}
                                ${item.rank === 3 ? 'bg-amber-700' : ''}
                                text-white font-bold
                              `}>
                                {item.rank}
                              </div>
                            ) : (
                              `#${item.rank}`
                            )}
                          </td>
                          <td className="p-3 border-b border-gray-200">
                            <div className="flex items-center gap-2">
                              {item.profilePic && (
                                <img
                                  src={item.profilePic}
                                  alt={`${item.userName}'s avatar`}
                                  className="w-8 h-8 rounded-full"
                                />
                              )}
                              <span className={isUserRow ? 'text-blue-600' : ''}>
                                {item.userName} {isUserRow && '(You)'}
                              </span>
                            </div>
                          </td>
                          <td className="p-3 border-b border-gray-200">{item.score}</td>
                          <td className="p-3 border-b border-gray-200">{formatTime(item.timeTaken)}</td>
                          <td className="p-3 border-b border-gray-200">{item.correctAnswers}</td>
                          <td className="p-3 border-b border-gray-200">{item.incorrectAnswers}</td>
                          <td className="p-3 border-b border-gray-200">{item.unattemptedQuestions}</td>
                          <td className="p-3 border-b border-gray-200">
                            {new Date(item.attemptDate).toLocaleDateString()} {new Date(item.attemptDate).toLocaleTimeString()}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div className="text-center py-10">
              <p className="text-gray-500">Your ranking's on hold… Start practicing to unlock it!</p>
            </div>
          )
        ) : (
          <div>
            <div className="bg-blue-50 p-4 rounded-lg mb-4">
              <h2 className="text-lg font-semibold mb-2">Your Performance</h2>
              <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                <div className="bg-white p-3 rounded shadow">
                  <div className="text-sm text-gray-500">Your Rank</div>
                  <div className="text-lg font-bold">
                    #{testRanking.userRank.rank} of {testRanking.userRank.totalParticipants}
                  </div>
                </div>
                <div className="bg-white p-3 rounded shadow">
                  <div className="text-sm text-gray-500">Your Score</div>
                  <div className="text-lg font-bold">{testRanking.userRank.userScore}</div>
                </div>
                <div className="bg-white p-3 rounded shadow">
                  <div className="text-sm text-gray-500">Time Taken</div>
                  <div className="text-lg font-bold">{formatTime(testRanking.userRank.userTime)}</div>
                </div>
                <div className="bg-white p-3 rounded shadow">
                  <div className="text-sm text-gray-500">Attempted</div>
                  <div className="text-lg font-bold">
                    {testRanking.userRank.attemptedQuestions}/{testRanking.userRank.totalQuestions}
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-gray-50 p-3 rounded-lg mb-4">
              <div className="flex flex-wrap gap-4">
                <div>
                  <span className="text-gray-500">Topic:</span> {formatSubjectName(testRanking.testInfo.topicName)}
                </div>
                <div>
                  <span className="text-gray-500">Test Type:</span> {formatSubjectName(testRanking.testInfo.testType)}
                </div>
                <div>
                  <span className="text-gray-500">Test Number:</span> {testRanking.testInfo.testNumber}
                </div>
                <div>
                  <span className="text-gray-500">Total Participants:</span> {testRanking.testInfo.totalParticipants}
                </div>
              </div>
            </div>

            <h2 className="text-lg font-semibold mb-2">Top Performers</h2>
            <div className="overflow-x-auto">
              <table className="w-full border-collapse">
                <thead>
                  <tr className="bg-blue-50">
                    <th className="p-3 text-left border border-gray-200">Rank</th>
                    <th className="p-3 text-left border border-gray-200">Student Name</th>
                    <th className="p-3 text-left border border-gray-200">Score</th>
                    <th className="p-3 text-left border border-gray-200">Time Taken</th>
                    <th className="p-3 text-left border border-gray-200">Correct Answers</th>
                    <th className="p-3 text-left border border-gray-200">Incorrect Answers</th>
                    <th className="p-3 text-left border border-gray-200">Unattempted</th>
                    <th className="p-3 text-left border border-gray-200">Attempt Date</th>
                  </tr>
                </thead>
                <tbody>
                  {getTestViewTopPerformers().map((item) => {
                    if (item.isDivider) {
                      return (
                        <tr key={item.id} className="h-4">
                          <td colSpan="8" className="p-0">
                            <div className="border-t-2 border-dashed border-gray-300"></div>
                          </td>
                        </tr>
                      );
                    }

                    const isUserRow = isCurrentUser(item.userId);
                    const isTopThree = item.rank <= 3;

                    return (
                      <tr
                        key={item.userId}
                        className={`
                          hover:bg-gray-50
                          ${isUserRow ? 'bg-blue-50 font-medium' : ''}
                          ${isTopThree && !isUserRow ? 'bg-yellow-50' : ''}
                        `}
                      >
                        <td className="p-3 border-b border-gray-200">
                          {item.rank <= 3 ? (
                            <div className={`
                              rounded-full w-6 h-6 flex items-center justify-center
                              ${item.rank === 1 ? 'bg-yellow-400' : ''}
                              ${item.rank === 2 ? 'bg-gray-300' : ''}
                              ${item.rank === 3 ? 'bg-amber-700' : ''}
                              text-white font-bold
                            `}>
                              {item.rank}
                            </div>
                          ) : (
                            `#${item.rank}`
                          )}
                        </td>
                        <td className="p-3 border-b border-gray-200">
                          <div className="flex items-center gap-2">
                            {item.profilePic && (
                              <img
                                src={item.profilePic}
                                alt={`${item.userName}'s avatar`}
                                className="w-8 h-8 rounded-full"
                              />
                            )}
                            <span className={isUserRow ? 'text-blue-600' : ''}>
                              {item.userName} {isUserRow && '(You)'}
                            </span>
                          </div>
                        </td>
                        <td className="p-3 border-b border-gray-200">{item.score}</td>
                        <td className="p-3 border-b border-gray-200">{formatTime(item.timeTaken)}</td>
                        <td className="p-3 border-b border-gray-200">{item.correctAnswers}</td>
                        <td className="p-3 border-b border-gray-200">{item.incorrectAnswers}</td>
                        <td className="p-3 border-b border-gray-200">{item.unattemptedQuestions}</td>
                        <td className="p-3 border-b border-gray-200">
                          {new Date(item.attemptDate).toLocaleDateString()} {new Date(item.attemptDate).toLocaleTimeString()}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default UserPerformance;