import { MathJax } from "better-react-mathjax";
import React from "react";

export const MathText = ({ text, textTag = "p" }) => {
  const TextTag = textTag || "p";

  if (typeof text !== "string") {
    text = text.toString();
  }
  text = text.trim();

  const parts = text.split(/\n/);

  const jsxElements = parts.flatMap((part, index) => {
    const hasMathExpression = /\$.*?\$|\\\(.*?\\\)/.test(part);

    const hasHTMLTags = /<.*?>/.test(part);

    if (hasMathExpression) {
      const subparts = part.split(/(\$.*?\$|\\\(.*?\\\))/);

      return subparts
        .map((subpart, subIndex) => {
          if (subIndex % 2 !== 0) {
            return (
              <MathJax
                key={`${index}_${subIndex}`}
                inline
                dynamic
                className="math-expression"
              >
                {subpart}
              </MathJax>
            );
          } else {
            return <span key={`${index}_${subIndex}`}>{subpart}</span>;
          }
        })
        .concat(<div key={`br_${index}`} className="minimal-space" />);
    }
    else if (hasHTMLTags) {
      return [
        <TextTag key={`html_${index}`} className="text-spacing">
          <span dangerouslySetInnerHTML={{ __html: part }} />
        </TextTag>,
      ];
    } else {
      return [
        <span className="normal text" key={index}>
          {part}
        </span>,
        <div key={`br_${index}`} className="minimal-space" />,
      ];
    }
  });

  return <TextTag className="text-spacing">{jsxElements}</TextTag>;
};
