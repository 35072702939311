import React, { useState, useEffect } from "react";
import { useNavigate, Outlet, useLocation, Link } from "react-router-dom";
import { MdOutlineInsertChart, MdOutlineSettings, MdOutlineLogout, MdClose } from "react-icons/md";
import { TbListDetails } from "react-icons/tb";
import { RiFileListLine } from "react-icons/ri";
import { IoDocumentTextOutline } from "react-icons/io5";
import { IoMdSearch } from "react-icons/io";
import { useAuth } from "../../utils/context";
import { useSearch } from "../../utils/SearchProvider";

const DashBoardLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [auth, setAuth] = useAuth();
  const { searchTerm, setSearchTerm, isSearchOpen, toggleSearch } = useSearch();
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  
  const currentPath = location.pathname.split('/profile/')[1] || "";
  const [activeMenuItem, setActiveMenuItem] = useState(currentPath);

  useEffect(() => {
    setActiveMenuItem(currentPath);
  }, [location, currentPath]);

  const handleLogoutClick = () => {
    setShowLogoutModal(true);
  };

  const handleCancelLogout = () => {
    setShowLogoutModal(false);
  };

  const handleConfirmLogout = () => {
    setAuth({
      ...auth,
      user: null,
      accessToken: "",
    });
    localStorage.removeItem("auth");
    setShowLogoutModal(false);
    navigate("/login"); // Redirect to login page after logout
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleMenuClick = (key) => {
    setActiveMenuItem(key);
    navigate(key);
  };

  const getCurrentDate = () => {
    const options = { weekday: 'short', day: '2-digit', month: 'short' };
    return new Date().toLocaleDateString('en-US', options);
  };

  const menuItems = [
    {
      key: "",
      label: "Student's Overview",
      icon: <IoDocumentTextOutline size={18} />
    },
    {
      key: "performance",
      label: "Performance",
      icon: <MdOutlineInsertChart size={18} />
    },
    // {
    //   key: "students-list",
    //   label: "Student's List",
    //   icon: <TbListDetails size={18} />
    // },
    // {
    //   key: "reports",
    //   label: "Reports",
    //   icon: <RiFileListLine size={18} />
    // },
  ];

  const bottomMenuItems = [
    {
      key: "settings",
      label: "Settings",
      icon: <MdOutlineSettings size={18} />
    },
    {
      key: "logout",
      label: "Log out",
      icon: <MdOutlineLogout size={18} />
    },
  ];

  const Logo = () => (
    <Link to="/" className="flex items-center">
      <div className="flex h-8 w-8">
        <img src="/logo.png" alt="" />
      </div>
      <span className="ml-2 font-bold text-black text-md">CUET-TestKnock</span>
    </Link>
  );

  // const handleSearchChange = (e) => {
  //   setSearchTerm(e.target.value);
  // };

  // const handleSearchSubmit = (e) => {
  //   e.preventDefault();
  //   if (activeMenuItem !== "students-list" && activeMenuItem !== "performance") {
  //     navigate("students-list");
  //     setActiveMenuItem("students-list");
  //   }
  // };

  return (
    <div className="h-screen flex flex-col md:flex-row overflow-hidden relative">
      {/* Logout Confirmation Modal */}
      {showLogoutModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg shadow-xl w-full max-w-md overflow-hidden transform transition-all">
            <div className="p-6">
              <div className="flex items-center justify-center mb-4">
                <div className="h-12 w-12 rounded-full bg-red-100 flex items-center justify-center">
                  <MdOutlineLogout className="h-6 w-6 text-red-600" />
                </div>
              </div>
              <h3 className="text-lg font-medium text-center text-gray-900 mb-2">Confirm Logout</h3>
              <p className="text-sm text-gray-500 text-center">
                Are you sure you want to log out of your account? You will need to log in again to access your dashboard.
              </p>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:px-6 flex justify-between gap-3 sm:flex-row-reverse">
              <button
                type="button"
                className="w-full inline-flex cursor-pointer justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 transition-colors duration-200 sm:w-auto sm:text-sm"
                onClick={handleConfirmLogout}
              >
                Yes, Log Out
              </button>
              <button
                type="button"
                className="w-full inline-flex cursor-pointer justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-200 sm:w-auto sm:text-sm"
                onClick={handleCancelLogout}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      <aside className="hidden md:flex md:w-[250px] bg-white h-full z-20 flex-col justify-between">
        <div>
          <div className="p-5">
            <Logo />
          </div>

          <nav className="mt-5 px-3">
            <div className="space-y-2">
              {menuItems.map((item) => (
                <div
                  key={item.key}
                  className={`
                    menu-item flex items-center px-4 py-3 rounded-lg
                    transition-all duration-200 ease-in-out
                    ${activeMenuItem === item.key ?
                      "bg-blue-600 text-white" :
                      "text-gray-600 hover:bg-gray-200 hover:text-gray-800"
                    }
                    cursor-pointer
                  `}
                  onClick={() => handleMenuClick(item.key)}
                >
                  <div className={`
                    flex items-center justify-center w-6 h-6 mr-3
                    ${activeMenuItem === item.key ? "text-white" : "text-gray-500"}
                  `}>
                    {item.icon}
                  </div>
                  <span className="font-medium text-sm">{item.label}</span>
                </div>
              ))}
            </div>
          </nav>
        </div>

        <div className="p-3 mb-5 space-y-2">
          {bottomMenuItems.map((item) => (
            (item.key === "logout") ? (
               <div
                 key={item.key}
                 className={`
                   menu-item flex items-center px-4 py-3 rounded-lg
                   transition-all duration-200 ease-in-out
                   ${activeMenuItem === item.key ?
                     "bg-blue-600 text-white" :
                     "text-gray-600 hover:bg-gray-200 hover:text-gray-800"
                   }
                   cursor-pointer
                 `}
                 onClick={handleLogoutClick} // Now calls handleLogoutClick instead of handleLogout
               >
                 <div className={`
                   flex items-center justify-center w-6 h-6 mr-3
                   ${activeMenuItem === item.key ? "text-white" : "text-gray-500"}
                 `}>
                   {item.icon}
                 </div>
                 <span className="font-medium text-sm">{item.label}</span>
               </div>
             ) : (
            <div
              key={item.key}
              className={`
                menu-item flex items-center px-4 py-3 rounded-lg
                transition-all duration-200 ease-in-out
                ${activeMenuItem === item.key ?
                  "bg-blue-600 text-white" :
                  "text-gray-600 hover:bg-gray-200 hover:text-gray-800"
                }
                cursor-pointer
              `}
                onClick={() => handleMenuClick(item.key)}
              >
                <div className={`
                flex items-center justify-center w-6 h-6 mr-3
                ${activeMenuItem === item.key ? "text-white" : "text-gray-500"}
              `}>
                  {item.icon}
                </div>
                <span className="font-medium text-sm">{item.label}</span>
              </div>
            )
          ))}
        </div>
      </aside>

      <div className="flex-1 bg-[#f5f5f5] flex flex-col overflow-hidden">
        <header className="bg-white shadow-md py-4 px-4 md:px-6 flex justify-between items-center sticky top-0 z-10">
          <div className="md:hidden">
            <Logo />
          </div>
          <div className="text-lg font-semibold hidden md:block">
            {menuItems.find(item => item.key === activeMenuItem)?.label || "Student's Overview"}
          </div>

          <div className="flex items-center space-x-3 md:space-x-4 ml-auto">
            <span className="text-gray-600 text-sm md:text-base">{getCurrentDate()}</span>

            {/* {!isSearchOpen ? (
              <>
                <IoMdSearch
                  size={24}
                  className="text-gray-600 cursor-pointer hover:text-blue-600 transition-colors duration-200"
                  onClick={toggleSearch}
                />
              </>
            ) : (
              <form onSubmit={handleSearchSubmit} className="flex-1 flex items-center">
                <input
                  type="text"
                  placeholder="Search students by name..."
                  value={searchTerm}
                  onChange={handleSearchChange}
                  className="w-full p-2 border border-gray-300 rounded-l-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  autoFocus
                />
                <button
                  type="button"
                  className="bg-white cursor-pointer rounded-r-md text-black "
                  onClick={toggleSearch}
                >
                  <MdClose size={24} />
                </button>
              </form>
            )} */}

            <div className="relative flex flex-col md:flex-row gap-1 justify-center items-center">
              {auth.user?.profilePic ? (
                <div>
                  <img className="w-[40px] h-[40px] flex justify-center items-center" src={auth.user.profilePic} alt="" />
                </div>
              ) : (
                <div className="rounded font-bold text-white relative text-[20px] w-10 h-10 flex items-center justify-center bg-[#FF820F] border">
                  {auth?.user?.name ? auth.user.name.charAt(0).toUpperCase() : ''}
                </div>
              )}
              {auth.user?.name && <span className="text-gray-800 max-md:text-[10px] font-semibold">{auth.user.name}</span>}
            </div>
          </div>
        </header>

        {/* Main Content Area */}
        <main className="flex-1 overflow-y-auto p-1 md:p-6 pb-20 md:pb-6 rounded-2xl">
          <Outlet context={{ setActiveMenuItem, navigate }} />
        </main>

        <nav className="md:hidden bg-white shadow-lg fixed bottom-0 left-0 right-0 z-30 px-2 py-3">
          <div className="flex justify-around items-center">
            {menuItems.map((item) => (
              <div
                key={item.key}
                className={`
                  flex flex-col items-center justify-center p-2
                  ${activeMenuItem === item.key ? "text-blue-600" : "text-gray-600"}
                  transition-colors duration-200
                `}
                onClick={() => handleMenuClick(item.key)}
              >
                {item.icon}
                <span className="text-xs mt-1">{item.label.split(' ')[0]}</span>
              </div>
            ))}
            <div
              className={`
                flex flex-col items-center justify-center p-2
                ${activeMenuItem === "settings" ? "text-blue-600" : "text-gray-600"}
                transition-colors duration-200
              `}
              onClick={() => handleMenuClick("settings")}
            >
              <MdOutlineSettings size={18} />
              <span className="text-xs mt-1">Settings</span>
            </div>
            <div
              className={`
                flex flex-col items-center justify-center p-2
                text-gray-600
                transition-colors duration-200
              `}
              onClick={handleLogoutClick} // Update mobile logout to use the confirm modal too
            >
              <MdOutlineLogout size={18} />
              <span className="text-xs mt-1">Logout</span>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default DashBoardLayout;