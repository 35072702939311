import React, { useState, useEffect, useRef } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const SubjectResultsGraph = ({ subjects, animate }) => {
  const containerRef = useRef(null);
  const chartContainerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [activeTab, setActiveTab] = useState('all'); 
  useEffect(() => {
    if (containerRef.current) {
      const updateDimensions = () => {
        setContainerWidth(containerRef.current.clientWidth);
        setIsMobile(window.innerWidth < 768);
      };
      
      updateDimensions();
      window.addEventListener('resize', updateDimensions);
      
      return () => window.removeEventListener('resize', updateDimensions);
    }
  }, []);
  
  const formatSubjectName = (name) => {
    return name
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };
  
  const getChartData = () => {
    return subjects.map(subject => {
      const baseData = {
        name: formatSubjectName(subject.name),
        uniqueMockTestNumbers: subject.uniqueMockTestNumbers || [],
        uniquePracticeTestNumbers: subject.uniquePracticeTestNumbers || [],
        mockAttempts: subject.mockAttempts,
        practiceAttempts: subject.practiceAttempts
      };
  
      // Add detailed recent attempts data
      if (subject.detailedRecentAttempts) {
        baseData.recentMockAttempts = subject.detailedRecentAttempts.mock || [];
        baseData.recentPracticeAttempts = subject.detailedRecentAttempts.practice || [];
      }
  
      // Add scores based on the active tab
      if (activeTab === 'all') {
        baseData.overallScore = subject.score;
        baseData.mockScore = subject.mockScore;
        baseData.practiceScore = subject.practiceScore;
      } else if (activeTab === 'mock') {
        // For mock tab, only show mock scores from recent attempts
        if (subject.detailedRecentAttempts?.mock && subject.detailedRecentAttempts.mock.length > 0) {
          // Get the scores from the last three mock attempts
          baseData.attempt1 = subject.detailedRecentAttempts.mock[0]?.score || 0;
          baseData.attempt2 = subject.detailedRecentAttempts.mock[1]?.score || 0;
          baseData.attempt3 = subject.detailedRecentAttempts.mock[2]?.score || 0;
          
          // Store test numbers for tooltip
          baseData.testNumber1 = subject.detailedRecentAttempts.mock[0]?.testNumber;
          baseData.testNumber2 = subject.detailedRecentAttempts.mock[1]?.testNumber;
          baseData.testNumber3 = subject.detailedRecentAttempts.mock[2]?.testNumber;
          
          // Store attempt dates for tooltip
          baseData.attemptDate1 = subject.detailedRecentAttempts.mock[0]?.attemptDate;
          baseData.attemptDate2 = subject.detailedRecentAttempts.mock[1]?.attemptDate;
          baseData.attemptDate3 = subject.detailedRecentAttempts.mock[2]?.attemptDate;
        }
      } else if (activeTab === 'practice') {
        // For practice tab, only show practice scores from recent attempts
        if (subject.detailedRecentAttempts?.practice && subject.detailedRecentAttempts.practice.length > 0) {
          // Get the scores from the last three practice attempts
          baseData.attempt1 = subject.detailedRecentAttempts.practice[0]?.score || 0;
          baseData.attempt2 = subject.detailedRecentAttempts.practice[1]?.score || 0;
          baseData.attempt3 = subject.detailedRecentAttempts.practice[2]?.score || 0;
          
          // Store test numbers for tooltip
          baseData.testNumber1 = subject.detailedRecentAttempts.practice[0]?.testNumber;
          baseData.testNumber2 = subject.detailedRecentAttempts.practice[1]?.testNumber;
          baseData.testNumber3 = subject.detailedRecentAttempts.practice[2]?.testNumber;
          
          // Store attempt dates for tooltip
          baseData.attemptDate1 = subject.detailedRecentAttempts.practice[0]?.attemptDate;
          baseData.attemptDate2 = subject.detailedRecentAttempts.practice[1]?.attemptDate;
          baseData.attemptDate3 = subject.detailedRecentAttempts.practice[2]?.attemptDate;
        }
      }
      
      return baseData;
    });
  };
  
  const chartData = getChartData();
  
  const getBarSize = () => {
    const count = subjects.length;
    if (count > 30) return 5;
    if (count > 20) return 7;
    if (count > 10) return 9;
    if (count > 5) return 12;
    return 18;
  };
  
  const getBarGap = () => {
    const count = subjects.length;
    if (count > 30) return 0;
    if (count > 20) return 1;
    return 2;
  };
  
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      
      // Format date function
      const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', { 
          year: 'numeric', 
          month: 'short', 
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit'
        });
      };
      
      if (activeTab === 'all') {
        // Show all data in 'all' tab
        return (
          <div className="bg-white p-3 rounded-lg shadow-lg border border-gray-200 text-sm">
            <div className="font-bold mb-1">{label}</div>
            <div className="text-blue-600">
              Mock Test Score: {data.mockScore?.toFixed(1) || 0} <span className="text-xs text-gray-500">(max 250)</span>
            </div>
            <div className="text-blue-200">
              Practice Score: {data.practiceScore?.toFixed(1) || 0} <span className="text-xs text-gray-500">(max 20)</span>
            </div>
            <div className="text-purple-300">
              Overall Score: {data.overallScore?.toFixed(1) || 0}
            </div>
            
            {/* Display attempts info */}
            {/* <div className="mt-2 text-gray-600 text-xs">
              Mock Tests: {data.mockAttempts || 0} attempts
            </div>
            <div className="text-gray-600 text-xs">
              Practice Tests: {data.practiceAttempts || 0} attempts
            </div> */}
            
            {/* Display unique test numbers */}
            {data.uniqueMockTestNumbers && data.uniqueMockTestNumbers.length > 0 && (
              <div className="mt-1 text-gray-600 text-xs">
               Attempted Mock test: {data.uniqueMockTestNumbers.join(', ')}
              </div>
            )}
            
            {data.uniquePracticeTestNumbers && data.uniquePracticeTestNumbers.length > 0 && (
              <div className="text-gray-600 text-xs">
               Attempted Practice test: {data.uniquePracticeTestNumbers.join(', ')}
              </div>
            )}
          </div>
        );
      } else if (activeTab === 'mock') {
        // Show only mock data in 'mock' tab with dates
        return (
          <div className="bg-white p-3 rounded-lg shadow-lg border border-gray-200 text-sm">
            <div className="font-bold mb-1">{label}</div>
            {data.recentMockAttempts && data.recentMockAttempts.length > 0 ? (
              data.recentMockAttempts.map((attempt, index) => (
                <div key={index} className={`text-blue-${index === 0 ? '600' : (index === 1 ? '400' : '200')}`}>
                  Test #{attempt.testNumber}: {attempt.score?.toFixed(1) || 0}
                  <div className="text-xs text-gray-500">
                    {formatDate(attempt.attemptDate)}
                  </div>
                </div>
              ))
            ) : data.attempt1 > 0 && (
              <>
                {data.attempt1 > 0 && (
                  <div className="text-blue-600">
                    Test #{data.testNumber1}: {data.attempt1?.toFixed(1) || 0}
                    {data.attemptDate1 && (
                      <div className="text-xs text-gray-500">
                        {formatDate(data.attemptDate1)}
                      </div>
                    )}
                  </div>
                )}
                {data.attempt2 > 0 && (
                  <div className="text-blue-400">
                    Test #{data.testNumber2}: {data.attempt2?.toFixed(1) || 0}
                    {data.attemptDate2 && (
                      <div className="text-xs text-gray-500">
                        {formatDate(data.attemptDate2)}
                      </div>
                    )}
                  </div>
                )}
                {data.attempt3 > 0 && (
                  <div className="text-blue-200">
                    Test #{data.testNumber3}: {data.attempt3?.toFixed(1) || 0}
                    {data.attemptDate3 && (
                      <div className="text-xs text-gray-500">
                        {formatDate(data.attemptDate3)}
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        );
      } else if (activeTab === 'practice') {
        // Show only practice data in 'practice' tab with dates
        return (
          <div className="bg-white p-3 rounded-lg shadow-lg border border-gray-200 text-sm">
            <div className="font-bold mb-1">{label}</div>
            {data.recentPracticeAttempts && data.recentPracticeAttempts.length > 0 ? (
              data.recentPracticeAttempts.map((attempt, index) => (
                <div key={index} className={`text-blue-${index === 0 ? '600' : (index === 1 ? '400' : '200')}`}>
                  Test #{attempt.testNumber}: {attempt.score?.toFixed(1) || 0}
                  <div className="text-xs text-gray-500">
                    {formatDate(attempt.attemptDate)}
                  </div>
                </div>
              ))
            ) : data.attempt1 > 0 && (
              <>
                {data.attempt1 > 0 && (
                  <div className="text-blue-600">
                    Test #{data.testNumber1}: {data.attempt1?.toFixed(1) || 0}
                    {data.attemptDate1 && (
                      <div className="text-xs text-gray-500">
                        {formatDate(data.attemptDate1)}
                      </div>
                    )}
                  </div>
                )}
                {data.attempt2 > 0 && (
                  <div className="text-blue-400">
                    Test #{data.testNumber2}: {data.attempt2?.toFixed(1) || 0}
                    {data.attemptDate2 && (
                      <div className="text-xs text-gray-500">
                        {formatDate(data.attemptDate2)}
                      </div>
                    )}
                  </div>
                )}
                {data.attempt3 > 0 && (
                  <div className="text-blue-200">
                    Test #{data.testNumber3}: {data.attempt3?.toFixed(1) || 0}
                    {data.attemptDate3 && (
                      <div className="text-xs text-gray-500">
                        {formatDate(data.attemptDate3)}
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        );
      }
    }
    return null;
  };
  
  const getScrollContainerWidth = () => {
    const barSize = getBarSize();
    const minSpacePerBar = barSize * 3.5;
    const minWidth = subjects.length * minSpacePerBar;
    
    return Math.max(minWidth, 600);
  };
  
  const needsScroll = isMobile || subjects.length > 5;
  
  const getYAxisMax = () => {
    const allScores = [];
    
    if (activeTab === 'all') {
      chartData.forEach(item => {
        if (item.mockScore !== undefined) allScores.push(item.mockScore);
        if (item.practiceScore !== undefined) allScores.push(item.practiceScore);
        if (item.overallScore !== undefined) allScores.push(item.overallScore);
      });
      
      const maxScore = Math.max(...allScores, 0); // Start with at least 0
      
      // Round up to the nearest nice number for Y-axis
      // Default to 100 if all scores are below 100
      if (maxScore <= 20) return 100;
      if (maxScore <= 50) return 100;
      if (maxScore <= 100) return 100;
      if (maxScore <= 150) return 150;
      if (maxScore <= 200) return 200;
      return 250;
    } else {
      // For mock and practice tabs with recent attempts (keep as is)
      chartData.forEach(item => {
        if (item.attempt1 !== undefined) allScores.push(item.attempt1);
        if (item.attempt2 !== undefined) allScores.push(item.attempt2);
        if (item.attempt3 !== undefined) allScores.push(item.attempt3);
      });
      
      const maxScore = Math.max(...allScores, 0);
      
      // Round up to the nearest nice number for Y-axis
      if (maxScore <= 20) return 20;
      if (maxScore <= 50) return 50;
      if (maxScore <= 100) return 100;
      if (maxScore <= 150) return 150;
      if (maxScore <= 200) return 200;
      return 250;
    }
  };
  
  const yAxisMax = getYAxisMax();
  const yAxisTicks = [];
  const tickCount = 5;
  
  for (let i = 0; i <= tickCount; i++) {
    yAxisTicks.push(Math.round((yAxisMax / tickCount) * i));
  }
  
  return (
    <div className="bg-white rounded-2xl shadow-md w-full h-full flex flex-col" ref={containerRef}>
      <div className="px-4 md:px-8 pt-4 pb-2">
        <div className='flex md:flex-row flex-col justify-between items-center'>
        <h2 className="text-gray-800 max-md:my-0 font-medium">Subject-Based Results</h2>
        
        {/* Tabs */}
        <div className="flex max-md:mx-auto max-md:my-2 text-xs border-[1px] border-blue-600 p-1 rounded-xl w-fit border-solid ">
          <button 
            className={`py-2 cursor-pointer border-none font-medium px-4 rounded-l-xl   ${activeTab === 'all' ? 'bg-blue-500 text-white' : 'text-gray-500 hover:text-gray-700'}`}
            onClick={() => setActiveTab('all')}
          >
            All
          </button>
          <button 
            className={`py-2 cursor-pointer border-none font-medium px-4    ${activeTab === 'mock' ? 'bg-blue-500 text-white' : 'text-gray-500 hover:text-gray-700'}`}
            onClick={() => setActiveTab('mock')}
          >
            Mock
          </button>
          <button 
            className={`py-2 cursor-pointer border-none font-medium px-4 rounded-r-xl  ${activeTab === 'practice' ? 'bg-blue-500 text-white' : 'text-gray-500 hover:text-gray-700'}`}
            onClick={() => setActiveTab('practice')}
          >
            Practice
          </button>
        </div>
        </div>
       
        
        {/* Legend for 'all' tab */}
        {activeTab === 'all' && (
          <div className="flex flex-wrap gap-3 md:gap-6 my-2">
            <div className="flex items-center gap-2">
              <div className="w-4 h-4 bg-blue-600 rounded-sm"></div>
              <span className="text-xs md:text-sm text-gray-700">Mock Test Score <span className="text-xs text-gray-500">(max 250)</span></span>
            </div>
            <div className="flex items-center gap-2">
              <div className="w-4 h-4 bg-blue-200 rounded-sm"></div>
              <span className="text-xs md:text-sm text-gray-700">Practice Score <span className="text-xs text-gray-500">(max 20)</span></span>
            </div>
            <div className="flex items-center gap-2">
              <div className="w-4 h-4 bg-purple-300 rounded-sm"></div>
              <span className="text-xs md:text-sm text-gray-700">Overall Score</span>
            </div>
          </div>
        )}
        
        {/* Legend for 'mock' and 'practice' tabs */}
        {(activeTab === 'mock' || activeTab === 'practice') && (
          <div className="flex flex-wrap gap-3 md:gap-6 my-2">
            <div className="flex items-center gap-2">
              <div className="w-4 h-4 bg-blue-600 rounded-sm"></div>
              <span className="text-xs md:text-sm text-gray-700">Latest Attempt</span>
            </div>
            <div className="flex items-center gap-2">
              <div className="w-4 h-4 bg-blue-400 rounded-sm"></div>
              <span className="text-xs md:text-sm text-gray-700">Second Latest Attempt</span>
            </div>
            <div className="flex items-center gap-2">
              <div className="w-4 h-4 bg-blue-200 rounded-sm"></div>
              <span className="text-xs md:text-sm text-gray-700">Third Latest Attempt</span>
            </div>
          </div>
        )}
      </div>
      
      <div className="flex-grow px-1 pb-4 relative" ref={chartContainerRef}>
        <div className="h-full w-full grid grid-cols-[auto_1fr]">
          <div className="h-full pt-2">
            <div className="flex flex-col justify-between h-full pb-8 pr-1">
              {yAxisTicks.reverse().map((tick, index) => (
                <div key={index} className="text-xs text-gray-500 text-right">{tick}</div>
              ))}
            </div>
          </div>
          
          <div className={`h-full max-md:min-h-[300px] ${needsScroll ? 'overflow-x-auto scrollbar-hide' : ''}`}>
            <div 
              className="h-full" 
              style={{ 
                minWidth: needsScroll ? `${getScrollContainerWidth()}px` : '100%',
                msOverflowStyle: 'none',
                scrollbarWidth: 'none'
              }}
            >
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  data={chartData}
                  margin={{ top: 10, right: 15, left: 0 }}
                  barGap={getBarGap()}
                  barSize={getBarSize()}
                >
                  <CartesianGrid strokeDasharray="3 3" vertical={false} />
                  <XAxis 
                    dataKey="name" 
                    axisLine={false}
                    tickLine={false}
                    tick={{ 
                      fontSize: subjects.length > 20 ? 8 : (isMobile ? 9 : 10),
                    }}
                    interval={subjects.length > 15 ? 1 : 0}
                  />
                  <YAxis 
                    axisLine={false}
                    tickLine={false}
                    domain={[0, yAxisMax]}
                    ticks={yAxisTicks}
                    hide={true}
                  />
                  <Tooltip content={<CustomTooltip />} />
                  
                  {activeTab === 'all' && (
                    <>
                      <Bar 
                        dataKey="overallScore" 
                        fill="#A78BFA"
                        radius={[4, 4, 0, 0]}
                        animationBegin={300}
                        animationDuration={1000}
                        isAnimationActive={animate}
                      />
                      <Bar 
                        dataKey="mockScore" 
                        fill="#4F46E5"
                        radius={[4, 4, 0, 0]}
                        animationBegin={500}
                        animationDuration={1000}
                        isAnimationActive={animate}
                      />
                      <Bar 
                        dataKey="practiceScore" 
                        fill="#BFDBFE"
                        radius={[4, 4, 0, 0]}
                        animationBegin={700}
                        animationDuration={1000}
                        isAnimationActive={animate}
                      />
                    </>
                  )}
                  
                  {(activeTab === 'mock' || activeTab === 'practice') && (
                    <>
                      <Bar 
                        dataKey="attempt1" 
                        fill="#4F46E5"
                        radius={[4, 4, 0, 0]}
                        animationBegin={300}
                        animationDuration={1000}
                        isAnimationActive={animate}
                      />
                      <Bar 
                        dataKey="attempt2" 
                        fill="#818CF8"
                        radius={[4, 4, 0, 0]}
                        animationBegin={500}
                        animationDuration={1000}
                        isAnimationActive={animate}
                      />
                      <Bar 
                        dataKey="attempt3" 
                        fill="#BFDBFE"
                        radius={[4, 4, 0, 0]}
                        animationBegin={700}
                        animationDuration={1000}
                        isAnimationActive={animate}
                      />
                    </>
                  )}
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubjectResultsGraph;