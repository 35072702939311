import React, { useEffect, useState, useRef } from 'react';
import { FaChevronLeft, FaChevronRight,  } from "react-icons/fa";
import axios from 'axios';
import { API } from '../../../utils/constants';
import { useAuth } from '../../../utils/context';
import SubjectResultsGraph from './Overview/SubjectResultsGraph';
import SubjectCard from './Overview/SubjectCard';
import { NoAttemptsCard, NoPlanCard } from './Overview/NoPlanCard';
import PerformanceCard from './Overview/PerformanceCard';
import StudentCountCard from './Overview/StudentCountCard';

const DashboardOverview = () => {
  const [auth] = useAuth();
  const [animate, setAnimate] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const [showLeftScroll, setShowLeftScroll] = useState(false);
  const [showRightScroll, setShowRightScroll] = useState(false);
  const subjectsContainerRef = useRef(null);
  const barsContainerRef = useRef(null);

  // Fetch user stats from API
  useEffect(() => {
    const fetchUserStats = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(
          `${API}/scores/user-stats/${auth.user?._id}`,
          {
            headers: {
              Authorization: `Bearer ${auth.accessToken.token}`,
            },
          }
        );
        
        if (response.data.success) {
          setUserData(response.data);
        }
      } catch (error) {
        console.error("Error fetching user stats:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (auth.user?._id) {
      fetchUserStats();
    }
  }, [auth.user?._id, auth.accessToken?.token]);

  // Check if horizontal scrolling is needed
  useEffect(() => {
    const checkScrollability = () => {
      if (subjectsContainerRef.current) {
        const container = subjectsContainerRef.current;
        setShowRightScroll(container.scrollWidth > container.clientWidth);
      }
      
      if (barsContainerRef.current) {
        const container = barsContainerRef.current;
        const isScrollable = container.scrollWidth > container.clientWidth;
        setShowRightScroll(isScrollable);
      }
    };

    checkScrollability();
    window.addEventListener('resize', checkScrollability);
    return () => window.removeEventListener('resize', checkScrollability);
  }, [userData]);

  // Handle scroll for subjects container
  const handleSubjectsScroll = (direction) => {
    if (subjectsContainerRef.current) {
      const container = subjectsContainerRef.current;
      const scrollAmount = direction === 'left' ? -300 : 300;
      container.scrollBy({ left: scrollAmount, behavior: 'smooth' });
      
      // Update scroll indicators after scroll
      setTimeout(() => {
        setShowLeftScroll(container.scrollLeft > 0);
        setShowRightScroll(container.scrollLeft < (container.scrollWidth - container.clientWidth));
      }, 300);
    }
  };


// Format data for the dashboard
const formatDashboardData = () => {
  if (!userData || !userData.userStats) {
    return {
      subjects: [],
      overallPerformance: { score: 0, rating: 'N/A' },
      studentCount: 0,
      userRank: 0
    };
  }

  const { userStats } = userData;
  
// Transform topics data for the dashboard
const subjects = userStats.topicsData.map(topic => {
  // Create maps to count test attempts by test number
  const mockTestCounts = {};
  const practiceTestCounts = {};
  
  // Count all attempts by test number and type
  topic.attempts?.forEach(attempt => {
    if (attempt.testType === 'mock') {
      mockTestCounts[attempt.testNumber] = (mockTestCounts[attempt.testNumber] || 0) + 1;
    } else if (attempt.testType === 'practice') {
      practiceTestCounts[attempt.testNumber] = (practiceTestCounts[attempt.testNumber] || 0) + 1;
    }
  });
  
  // Get all mock attempts
  const allMockAttempts = topic.attempts?.filter(attempt => 
    attempt.testType === 'mock'
  ) || [];
  
  // Get all practice attempts
  const allPracticeAttempts = topic.attempts?.filter(attempt => 
    attempt.testType === 'practice'
  ) || [];
  
  // Calculate scores for all attempts
  const mockScore = allMockAttempts.length > 0 
    ? allMockAttempts.reduce((sum, attempt) => sum + attempt.score, 0) / allMockAttempts.length 
    : 0;
    
  const practiceScore = allPracticeAttempts.length > 0 
    ? allPracticeAttempts.reduce((sum, attempt) => sum + attempt.score, 0) / allPracticeAttempts.length 
    : 0;
    
  // Get overall score
  const allAttempts = [...allMockAttempts, ...allPracticeAttempts];
  const overallScore = allAttempts.length > 0
    ? allAttempts.reduce((sum, attempt) => sum + attempt.score, 0) / allAttempts.length
    : 0;
  
  // Get detailed recent mock attempts (up to 3), sorted by date (most recent first)
  const recentMockAttempts = Array.from(allMockAttempts)
    .sort((a, b) => new Date(b.attemptDate) - new Date(a.attemptDate))
    .slice(0, 3);
  
  // Get detailed recent practice attempts (up to 3), sorted by date (most recent first)
  const recentPracticeAttempts = Array.from(allPracticeAttempts)
    .sort((a, b) => new Date(b.attemptDate) - new Date(a.attemptDate))
    .slice(0, 3);
  
  // Get recent attempts for backward compatibility
  const recentAttempts = [...recentMockAttempts, ...recentPracticeAttempts]
    .sort((a, b) => new Date(b.attemptDate) - new Date(a.attemptDate))
    .slice(0, 3)
    .map(attempt => attempt.score);

  // Format test counts into arrays for display
  const mockTestFrequency = Object.entries(mockTestCounts).map(([testNumber, count]) => ({
    testNumber: parseInt(testNumber),
    count
  })).sort((a, b) => a.testNumber - b.testNumber);
  
  const practiceTestFrequency = Object.entries(practiceTestCounts).map(([testNumber, count]) => ({
    testNumber: parseInt(testNumber),
    count
  })).sort((a, b) => a.testNumber - b.testNumber);

  // Get unique test numbers
  const uniqueMockTestNumbers = [...new Set(allMockAttempts.map(attempt => attempt.testNumber))];
  const uniquePracticeTestNumbers = [...new Set(allPracticeAttempts.map(attempt => attempt.testNumber))];

  return {
    name: topic.topicName,
    score: overallScore,
    results: {
      pass: mockScore,
      fail: 100 - mockScore - (allMockAttempts.length > 0 ? 0 : 100),
      notAttended: practiceScore
    },
    mockScore: mockScore,
    practiceScore: practiceScore,
    recentAttempts: recentAttempts,
    // Add detailed recent attempts
    detailedRecentAttempts: {
      mock: recentMockAttempts,
      practice: recentPracticeAttempts,
      all: [...recentMockAttempts, ...recentPracticeAttempts]
        .sort((a, b) => new Date(b.attemptDate) - new Date(a.attemptDate))
        .slice(0, 3)
    },
    totalAttempts: allMockAttempts.length + allPracticeAttempts.length,
    mockAttempts: allMockAttempts.length,
    practiceAttempts: allPracticeAttempts.length,
    // Store the arrays of unique test numbers
    uniqueMockTestNumbers,
    uniquePracticeTestNumbers,
    // Add the frequency data
    mockTestFrequency,
    practiceTestFrequency,
    // Store total attempt counts
    totalMockAttemptCount: allMockAttempts.length,
    totalPracticeAttemptCount: allPracticeAttempts.length
  };
});

  // Sort subjects by score (highest first)
  subjects.sort((a, b) => b.score - a.score);

  // Format overall performance
  const overallScore = userStats.overallStats?.averageScore || 0;
  let rating = 'Poor';
  if (overallScore >= 80) rating = 'Excellent';
  else if (overallScore >= 70) rating = 'Very Good';
  else if (overallScore >= 60) rating = 'Good';
  else if (overallScore >= 50) rating = 'Average';
  
  return {
    subjects,
    overallPerformance: {
      score: overallScore,
      rating
    },
    studentCount: userStats.instituteRanking?.totalStudents || 0,
    userRank: userStats.instituteRanking?.rank
  };
};
  const dashboardData = formatDashboardData();
  const allSubjects = dashboardData.subjects;
  
  useEffect(() => {
    if (!isLoading) {
      // Use a slight delay to ensure DOM is ready for animations
      const timer = setTimeout(() => {
        setAnimate(true);
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [isLoading]);

  if (isLoading) {
    return <div className="flex justify-center items-center h-screen">
      <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-solid border-blue-500"></div>
    </div>;
  }

  // Check if we have subjects
  const hasSubjects = auth?.user?.selectedSubjects && auth?.user?.selectedSubjects.length > 0;
  
  // Check if we have any attempts across all subjects
  const hasAttempts = dashboardData.subjects.some(subject => subject.totalAttempts > 0);

  return (
    <div className="font-sans mx-auto px-2 md:px-4">
      {/* Subjects Container with horizontal scroll */}
      <div className="relative my-3">
        {/* Left scroll button */}
        {showLeftScroll && (
          <button 
            className="absolute left-0 top-1/2 -translate-y-1/2 z-10 bg-white rounded-full shadow-md p-2 md:flex items-center justify-center hidden"
            onClick={() => handleSubjectsScroll('left')}
          >
            <FaChevronLeft className="text-blue-500" />
          </button>
        )}
        
        {/* Subjects container */}
        <div 
          ref={subjectsContainerRef}
          className="flex overflow-x-auto overflow-y-hidden scrollbar-hide gap-4 md:gap-6 pb-2 snap-x"
          onScroll={(e) => {
            const container = e.currentTarget;
            setShowLeftScroll(container.scrollLeft > 0);
            setShowRightScroll(container.scrollLeft < (container.scrollWidth - container.clientWidth - 5));
          }}
        >
          {!hasSubjects ? (
            // No subjects case
            <div className="min-w-[280px] md:min-w-[320px] flex-none snap-start">
              <NoPlanCard animate={animate} />
            </div>
          ) : allSubjects.length === 0 || !hasAttempts ? (
            // Has subjects but no attempts case
            <div className="min-w-[280px] md:min-w-[320px] flex-none snap-start">
              <NoAttemptsCard animate={animate} />
            </div>
          ) : (
            // Normal case: render all subjects
            allSubjects.map((subject, index) => (
              <div key={index} className="min-w-[280px] md:min-w-[320px] flex-none snap-start">
                <SubjectCard 
                  subject={subject}
                  animate={animate}
                  index={index}
                />
              </div>
            ))
          )}
        </div>
        
        {/* Right scroll button */}
        {showRightScroll && (
          <button 
            className="absolute right-0 top-1/2 -translate-y-1/2 z-10 bg-white rounded-full shadow-md p-2 md:flex items-center justify-center hidden"
            onClick={() => handleSubjectsScroll('right')}
          >
            <FaChevronRight className="text-blue-500" />
          </button>
        )}
      </div>
      
      <div className="flex lg:flex-row relative flex-col w-full gap-4 md:gap-6">
        <div className="lg:w-[70%] w-full">
          {allSubjects.length > 0 ? (
            <SubjectResultsGraph
              subjects={dashboardData.subjects}
              animate={animate}
            />
          ) : (
            <div className="bg-white rounded-2xl p-4 md:p-6 shadow-md w-full h-full flex items-center justify-center">
              <p className="text-gray-500 text-center">No subject data available.</p>
            </div>
          )}
        </div>
        
        <div className="flex flex-col gap-4 lg:w-[30%] w-full md:gap-6">
          <PerformanceCard 
            performance={dashboardData.overallPerformance}
            animate={animate}
          />
          <StudentCountCard 
            studentCount={dashboardData.studentCount}
            animate={animate}
            userRank={dashboardData.userRank}
          />
        </div>
      </div>
    </div>
  );
};



export default DashboardOverview;